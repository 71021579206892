import React, { useEffect, useState } from "react";
import AppLayout from "../../../Components/AppLayout";
import "./Packs.css";
import { addPack } from "../../../Common/Repository";
import { useFilePicker } from "use-file-picker";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { CloudUploadIcon, InfoIcon } from "../../../Components/Icons";
import { useDispatch, useSelector } from "react-redux";
import { PuffLoader } from "react-spinners";
import LightTooltip from "../../../Components/LightToolTip";
import { IconButton } from "@mui/material";

// var files = [];

const CreatePack = () => {
  const [files, setFiles] = useState([]);
  const [filesContent, setFilesContent] = useState([]);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { token } = useSelector((state) => state.auth);

  const { error, addPackLoading, addPackSuccess } = useSelector(
    (state) => state.packs
  );
  useEffect(() => {
    setErrors(error?.errors);
  }, [error]);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [perObject, setperObject] = useState("");
  const [deliveryTime, setdeliveryTime] = useState("");
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    const messageEle = document.getElementById("description");
    const counterEle = document.getElementById("descriptionCounter");

    messageEle.addEventListener("input", function (e) {
      const target = e.target;

      // Count the current number of characters
      const currentLength = target.value.length;

      counterEle.innerHTML = `${currentLength}`;
    });

    const tilteElement = document.getElementById("title");
    const titleCounterElement = document.getElementById("titleCounter");

    tilteElement.addEventListener("input", function (e) {
      const target = e.target;

      // Count the current number of characters
      const currentLength = target.value.length;

      titleCounterElement.innerHTML = `${currentLength}`;
    });
  });

  const [
    openFileSelector,
    { filesContent: newFilesContent, loading, fileErrors },
  ] = useFilePicker({
    readAs: "DataURL",
    accept: "image/jpeg, image/png",
    multiple: true,
    limitFilesConfig: { max: 4 },
    maxFileSize: 2, // in megabytes
    minFileSize: 0.02, // in megabytes
    onFilesRejected: ({ errors }) => {
      toast.error(
        errors[0].fileSizeTooSmall
          ? "file size too small"
          : errors[0].fileSizeToolarge
            ? "file size too large. Max file size 2MB"
            : ""
      );
    },
  });

  useEffect(() => {
    if (filesContent !== undefined) {
      if (files.length > 4) {
        toast.error("You can not select more than 4 images!");
      } else {
        for (let i = 0; i < filesContent.length; i++) {
          if (files.length < 4) {
            let foundFile = false;
            for (let j = 0; j < files.length; j++) {
              if (filesContent[i].name === files[j].name) {
                foundFile = true;
              }
            }
            if (!foundFile) {
              setFiles((prevFiles) => [...prevFiles, filesContent[i]]);
              // files.push(filesContent[i]);
            }
          }
        }
      }
    }
  }, [filesContent]);

  function deleteImage(index) {
    if (index >= 0 && index < files.length) {
      setFiles((state) => {
        return state.filter((f, i) => i != index);
      });
    } else {
      console.log("Invalid index:", index);
    }
  }

  useEffect(() => {
    if (newFilesContent && newFilesContent.length > 0) {
      setFilesContent(newFilesContent);
    }
  }, [newFilesContent]);

  const handleAddPack = async () => {
    setErrors([]);
    try {
      var formData = new FormData();
      formData.append("title", title);
      formData.append("description", description);
      formData.append("price", price);
      formData.append("object", perObject);
      formData.append("time_duration", deliveryTime);

      for (var i = 0; i < files.length; i++) {
        let asd = dataURItoBlob(files[i].content);
        formData.append(`images[${i}]`, asd);
      }
      dispatch(addPack({ token, formData, navigate, setFiles }));
    } catch (error) {
      toast.error("Failed to add package!");
      setErrors(error?.response?.data?.error);
    }
  };

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const objectData = ["Story", "Reel", "Short Video", "Graphic Post", "Custom"];

  const deliveryTimeData = ["1", "2", "3", "4", "5", "6"];

  const [selectedObject, setSelectedObject] = useState("");

  const handleObjectClick = (value) => {
    setSelectedObject(value);
  };

  return (
    <AppLayout>
      <div className="container container-animation">
        <div className="packHeading">Create a Pack</div>
        <div className="packContainerBackground">
          {/* Main Row */}
          <div className="row mobile-col">
            {/* First Column */}
            <div className="columnOne wm-100">
              <p className="inputTitle">Title</p>
              <div className="packsRow">
                <input
                  id="title"
                  type="text"
                  className="leftColumn"
                  value={title}
                  maxLength="120"
                  placeholder="Title..."
                  onChange={(event) => {
                    setTitle(event.target.value);
                  }}
                />
                <div className="rightColumn">
                  {/* <p>Title should be what you are providing.</p> */}

                  <LightTooltip
                    className="float-right"
                    placement="right"
                    title="Title should be what you are providing."
                  >
                    <IconButton>
                      <InfoIcon width="18" height="18" />
                    </IconButton>
                  </LightTooltip>
                </div>
              </div>
              {errors && errors?.title ? (
                <>
                  <div className="errorText">{errors?.title}</div>
                </>
              ) : null}

              <div className="counterText">
                <span id="titleCounter">0</span>/120 Characters
              </div>

              <div className="descriptionTextBox">
                <p className="inputTitle">Description</p>
                <div className="packsRow">
                  <textarea
                    id="description"
                    type="text"
                    className="leftColumn"
                    value={description}
                    maxLength={500}
                    placeholder="Description"
                    onChange={(event) => {
                      setDescription(event.target.value);
                    }}
                  />
                  <div className="rightColumn">
                    <LightTooltip
                      className="float-right"
                      placement="right"
                      title="Description should be a short glimpse of the service you
                      are providing. Make sure everything you mention is clear
                      and to the point."
                    >
                      <IconButton>
                        <InfoIcon width="18" height="18" />
                      </IconButton>
                    </LightTooltip>
                  </div>
                </div>
                {errors && errors?.description ? (
                  <>
                    <div className="errorText">{errors?.description}</div>
                  </>
                ) : null}
                <div className="counterText pe-3 pe-xl-1">
                  <span id="descriptionCounter">0</span>/500 Characters
                </div>
              </div>
              <div className="fieldsRow">
                <div className="leftColumn position-relative mobile-col phone-align-to-center">
                  <input
                    type="tel"
                    className="width-30 wm-100"
                    value={price}
                    placeholder="Price"
                    maxLength={10}
                    onChange={(event) => {
                      const inputValue = event.target.value;
                      const numericValue = inputValue.replace(/[^0-9]/g, "");
                      setPrice(numericValue);
                    }}
                  />

                  <img
                    className="create-pack-dollar-icon hide-on-phone"
                    src="/assets/images/priceDollar.svg"
                    alt="$"
                  />

                  <p className="perText wm-100">Per</p>
                  <select
                    className="width-30 dropDown wm-100 mb-4 mb-md-0"
                    value={perObject}
                    onChange={(event) => {
                      setperObject(event.target.value);
                    }}
                  >
                    <option value="">Select Object</option>
                    {objectData.map((object, index) => (
                      <option key={index} value={object}>
                        {object}
                      </option>
                    ))}
                  </select>
                  <select
                    className="width-30 dropDown wm-100"
                    value={deliveryTime}
                    onChange={(event) => {
                      setdeliveryTime(event.target.value);
                    }}
                  >
                    <option value="">Delivery Time</option>
                    {deliveryTimeData.map((object, index) => (
                      <option key={index} value={object}>
                        {object} {object === "1" ? "Day" : "Days"}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="rightColumn">
                  <LightTooltip
                    className="float-right"
                    placement="right"
                    title="Pricing &amp; Duration should be per Pack."
                  >
                    <IconButton>
                      <InfoIcon width="18" height="18" />
                    </IconButton>
                  </LightTooltip>
                  {/* <p>Pricing &amp; Duration should be per Pack.</p> */}
                </div>
              </div>
              <div className="row">
                <div className="col-4 errorText" style={{ width: "30%" }}>
                  {errors && errors?.price ? <>{errors?.price}</> : ""}
                </div>
                <div className="col-4 errorText" style={{ width: "30%" }}>
                  {errors && errors?.object ? <>{errors?.object}</> : ""}
                </div>
                <div className="col-4 ps-0  errorText">
                  {errors && errors?.time_duration ? (
                    <>{errors?.time_duration}</>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <span className="verticalDivider hide-on-phone"></span>
            <div className="columnTwo wm-100">
              <div className="shortRow">
                {files.map((file, index) => (
                  <div
                    key={`local-image-${index}`}
                    className="imageContainer"
                    style={{
                      background: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.85)), url(${file.content})`,
                    }}
                    onClick={() => deleteImage(index)}
                  >
                    <div className="deleteImage">Delete</div>
                  </div>
                ))}
                {files.length < 4 && (
                  <div
                    className="imageContainer"
                    onClick={() => openFileSelector()}
                  >
                    <img
                      className="insideImage"
                      alt="gallery"
                      src="/assets/images/packs/uim-image-v.svg"
                    />
                    <div className="insideContainer">
                      <CloudUploadIcon />
                      <span>Upload Photo</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="errorText text-center">
                {errors && errors?.images ? <> Atleast one image is required </> : ""}
              </div>
              <p className="imagesText">
                Upload up to 4 images for your pack.
                <br />
                1st image will be used as your packs default image.
              </p>
            </div>
          </div>
          <div className="buttonsRow wm-100">
            <div
              className="cancelButton wm-100"
              onClick={() => {
                navigate("/packs/view");
              }}
            >
              Cancel
            </div>
            <div
              className="saveButton d-flex justify-content-center wm-100"
              onClick={() => {
                handleAddPack();
              }}
            >
              <div>
                {addPackLoading ? (
                  <PuffLoader color="white" size={28} />
                ) : (
                  "Save"
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default CreatePack;

import React from "react";
import AppLayout from "../../Components/AppLayout";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Welcome = () => {
  const user = useSelector((state) => state.auth.user);

  // const user = JSON.parse(localStorage.getItem("user"));

  return (
    <AppLayout>
      <section className="welcome-box">
        <div className="welcome-inner-box text-center position-relative">
          <h1>Welcome</h1>
          {user?.role === "2" ? (
            <p className="welcome-text">
              Create a free campaign in seconds to start receiving collaboration
              proposals from creators. Or search and invite creators in 1-click.
            </p>
          ) : (
            <p className="welcome-text">
              Create a free pack in seconds to start receiving collaboration
              proposals from brands. Or search and invite brands in 1-click.
            </p>
          )}
          <div className="button-box">
            {user?.role === "2" ? (
              <Link className="btn common-button-style-filled me-sm-4" to="/addCampaigns">
                Free Campaign
              </Link>
            ) : (
              <Link className="btn common-button-style-filled me-sm-4" to="/pack/create">
                Free Packs
              </Link>
            )}
            {user?.role === "2" ? (
              <Link className="btn common-button-style-outline me-sm-4" to="/creatorsCatalogue">
                Search Creators
              </Link>
            ) : (
              <Link className="btn common-button-style-outline me-sm-4" to="/brandCatalogue">
                Search Brands
              </Link>
            )}
          </div>

          <p className="welcome-bottom-text">
            {user?.role === "2" ? "Campaigns and search features included in Free Plan." : "Packs and search features included in Free Plan."} <span className="text-highlight">More info</span>
          </p>

          <img
            className="img-fluid welcome-sec-main-img"
            src="/assets/images/welcome-screen-img.png"
            alt=""
          />

          <img
            className="circle_top1 welcome-screen-purple-coin"
            src="/assets/images/circle_top1.png"
            alt="circle_top1"
          />
          <img
            className="circle_top1 welcome-screen-dollor-coin"
            src="/assets/images/circle.svg"
            alt="circle_top1"
            width={20}
          />
          <img
            className="circle_3 welcome-screen-yellow-coin"
            src="/assets/images/circle_3.png"
            alt="circle_3"
          />
        </div>
      </section>
    </AppLayout>
  );
};

export default Welcome;

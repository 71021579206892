import React, { useEffect, useState } from "react";

const GenderSelect = ({ width, gender, handleSelectGender }) => {
  const [activeChoice, setActiveChoice] = useState(
    gender === "female"
      ? "women_select"
      : gender === "male"
      ? "men_select"
      : gender === "other"
      ? "other"
      : ""
  );

  useEffect(() => {
    if (gender === "") {
      setActiveChoice("");
    }
  }, [gender]);

  const handleClick = (value) => {
    if (value === "reset" || value === "") {
      handleSelectGender(value);
      setActiveChoice("");
    } else {
      handleSelectGender(
        value === "women_select"
          ? "female"
          : value === "men_select"
          ? "male"
          : value === "other"
          ? "other"
          : ""
      );
      setActiveChoice(value);
    }
  };

  return (
    <>
      <div className="w-100 inputWrapper">
        <label className="w-100">
          <input
            type="radio"
            value="all"
            checked={activeChoice === ""}
            onChange={() => handleClick("")}
          />
          All
        </label>
        <label className="w-100">
          <input
            type="radio"
            value="men_select"
            checked={activeChoice === "men_select"}
            onChange={() => handleClick("men_select")}
          />
          Male
        </label>
        <label className="w-100">
          <input
            type="radio"
            value="women_select"
            checked={activeChoice === "women_select"}
            onChange={() => handleClick("women_select")}
          />
          Female
        </label>
        <label className="w-100">
          <input
            type="radio"
            value="men_select"
            checked={activeChoice === "other"}
            onChange={() => handleClick("other")}
          />
          Other
        </label>
      </div>
      <div
        onClick={() => handleClick("reset")}
        className="d-flex align-items-center text-dark fs-6 mt-2 resetButton"
      >
        Reset
      </div>
    </>
  );
};

export default GenderSelect;

import { createSlice } from "@reduxjs/toolkit";
import {
  AcceptExtensionApi,
  AcceptLinkApi,
  AcceptOfferApi,
  AcceptSubmitWorkApi,
  AskAdjustmentApi,
  GetChatListApi,
  GetMessagesListApi,
  GetPendingCollaborationsCount,
  GetUnreadMessageCountApi,
  MakePaymentBrand,
  RejectExtensionApi,
  RejectLinkApi,
  RejectOfferApi,
  ReportIssueApi,
  SendCustomOfferApi,
  SubmitAppealApi,
  SubmitExtensionApi,
  SubmitLinkApi,
  SubmitWorkApi,
  addReviewApi,
  getCurrentStageOfChat,
  markSingleChatAsReadApi,
} from "../../Common/Repository";
import { logout } from "../auth/authSlice";

const initialState = {
  pendingRequestCount: 0,
  unReadMessagesCount: [],
  showOfferNow: false,
  loadingChats: false,
  loadingMessages: false,
  messageList: [],
  chatList: [],
  error: null,
  success: false,
  // Working Variables of Chat:
  currentUser: null,
  // currentUser:
  //   localStorage.getItem("user") === undefined
  //     ? null
  //     : JSON.parse(localStorage.getItem("user")),
  currentChatId: -1,
  receiverId: "",
  reportIssueTime: "",
  currentOffer: {},
  adjustmentId: "",
  submittedWork: "",
  currentStage: {},
  sender: {},
  receiver: {},
  offer: {},
  totalMessagePages: 0,
  currentPage: 1,
  // Pagination Variables:
  nextPageMessageUrl: "",
  fromMPage: "",
  lastMPage: "",
  // States of Chat below here:
  inviteRequestAccepted: false,
  workInProcess: false,
  deliveryTimeBar: false,
  offerAccepted: false, // Not Used Anywhere
  acceptOffer: false,
  packBought: false,
  workSent: false, // Not Used Anywhere
  workReceived: false,
  workReceivedAgain: false,
  proofSent: false,
  proofReceived: false,
  proofReceivedAgain: false,

  proofAccepted: false,
  proofRejected: false,
  issueReported: false,
  customOfferSent: false, // Not Used Anywhere
  customOfferReceived: false, // Not Used Anywhere
  chatRequestSent: false,
  chatRequestReceived: false,
  changeRequestSent: false, // Not Used Anywhere
  changeRequestReceived: false,
  workSubmitSentAgain: false, // Not Used Anywhere
  changeRequestReceivedAgain: false, // Not Used AnywhereWork Submission Request sent
  appealRequest: false,
  decisionInMyFav: false, // Not Used Anywhere
  decisionInCreatorFav: false, // Not Used Anywhere
  decisionInBrandFav: false,
  paymentProcessed: false,

  extensionRequestSent: false, // Not Used Anywhere
  extensionRequestReceived: false,
  collabComplete: false, // Not Used Anywhere
  reviewIsGiven: false,
  brandFavourDecision: false,
  workAccepted: false,
  workAccept: false,
  // Loading Variables
  loadingAcceptOffer: false,
  loadingRejectOffer: false,
  loadingBrandPayment: false,
  workLoading: false,
  extensionReqRej: false,
  extensionReqRejSuccess: false,
  submitExtensionError: false,
  submitLinkError: false,
  askAdjustmentError: false,
  extensionReqAccept: false,
  extensionReqAcceptSuccess: false,
  isLateDelivery: false,
};

const chatSlice = createSlice({
  name: "chats",
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setCurrentChatId: (state, action) => {
      state.currentChatId = action.payload;
    },
    handleChatStages: (state) => {
      chatSlice.caseReducers.resetStates(state);
      if (state.currentStage.type === "Chat Request Accepted") {
        state.chatRequestSent = false;
        state.acceptOffer = true;
      } else if (
        state.currentStage.type === "Offer Accepted" &&
        state.offer.payment_transaction === null
      ) {
        state.inviteRequestAccepted = true;
      } else if (
        state.currentStage.type.includes("Payment has been received") &&
        state.offer.payment_transaction
      ) {
        state.deliveryTimeBar = true;
      } else if (
        state.currentStage.type === "Offer Accepted" ||
        state.currentStage.type === "Extension Requested Rejected" ||
        state.currentStage.type === "Extension Request Accepted" ||
        state.currentStage.type === "Extension Requested Accepted"
      ) {
        const startTime = Date.now();
        const endTime = new Date(state.offer.delivery_time);

        let remainingTime = endTime - startTime;
        if (remainingTime < 0) {
          state.isLateDelivery = true;
        } else {
          state.isLateDelivery = false;
        }
        state.offerAccepted = true;
        state.deliveryTimeBar = true;
      } else if (state.currentStage.type === "Your pack bought" || state.currentStage.type === "Pack bought") {
        const startTime = Date.now();
        const endTime = new Date(state.offer.delivery_time);

        let remainingTime = endTime - startTime;
        if (remainingTime < 0) {
          state.isLateDelivery = true;
        } else {
          state.isLateDelivery = false;
        }
        state.packBought = true;
        state.deliveryTimeBar = true;
      } else if (state.currentStage.type === "Chat Request Received") {
        state.chatRequestReceived = true;
      } else if (state.currentStage.type === "Chat Request Sent") {
        state.chatRequestSent = true;
      } else if (state.currentStage.type === "Payment successfull by brand") {
        state.deliveryTimeBar = true;

        state.workInProcess = true;
      } else if (state.currentStage.type === "Work Submission Sent") {
        state.workSent = true;
      } else if (state.currentStage.type === "Work Submission Received") {
        state.workReceived = true;
      } else if (state.currentStage.type === "Link Submission Request Sent") {
        state.proofSent = true;
      } else if (
        state.currentStage.type === "Link Submission Request Received"
      ) {
        state.proofReceived = true;
      } else if (
        state.currentStage.type === "Link Submission Request Received Again"
      ) {
        state.proofReceivedAgain = true;
      } else if (state.currentStage.type === "Proof Accepted") {
        state.proofAccepted = true;
      } else if (state.currentStage.type === "Proof Rejected") {
        state.proofRejected = true;
      } else if (state.currentStage.type === "Issue Reported For Proof") {
        state.issueReported = true;
      } else if (state.currentStage.type === "Customized Offer Sent") {
        state.customOfferSent = true;
      } else if (state.currentStage.type === "Customized Offer Received") {
        state.customOfferReceived = true;
      } else if (state.currentStage.type === "Work Change Request Sent") {
        state.changeRequestSent = true;
      } else if (state.currentStage.type === "Work Change Request Received") {
        state.changeRequestReceived = true;
        state.adjustmentId = state.currentStage.adjustment_id;
      } else if (state.currentStage.type === "Work Submission Received Again") {
        state.workReceivedAgain = true;
      } else if (state.currentStage.type === "Work Submission Sent Again") {
        state.workSubmitSentAgain = true;
      } else if (state.currentStage.type === "Appeal Request") {
        state.appealRequest = true;
      } else if (state.currentStage.type === "Decision made in your favor") {
        state.decisionInMyFav = true;
      } else if (
        state.currentStage.type === "Decision made in favor of Brand" || state.currentStage.type === "Report decision made in favor of brand"
      ) {
        state.decisionInBrandFav = true;
      } else if (
        state.currentStage.type === "Payment Processed, Collaboration Completed"
      ) {
        state.paymentProcessed = true;
      } else if (
        state.currentStage.type === "Decision made in favor of Creator" || state.currentStage.type === "Report decision made in favor of creator"
      ) {
        state.decisionInCreatorFav = true;
      } else if (state.currentStage.type === "Extension Request Sent") {
        state.extensionRequestSent = true;
      } else if (
        state.currentStage.type === "Extension Requested Received"
      ) {
        state.extensionRequestReceived = true;
      } else if (state.currentStage.type === "Work Submission Accepted") {
        if (state.currentUser?.role === "1") {
          state.workAccept = true;
        }
      } else if (state.currentStage.type === "Collaboration Completed") {
        state.collabComplete = true;
      } else if (state.currentStage.type === "Review received") {
        if (state?.currentStage?.collaboration_reviews?.length === 2) {
          state.bothHaveGivenReviews = true;
        }
        state.reviewIsReceived = true;
      } else if (state.currentStage.type === "Review added") {
        if (state?.currentStage?.collaboration_reviews?.length === 2) {
          state.bothHaveGivenReviews = true;
        }
        state.reviewIsGiven = true;
      }
    },
    setTotalUnreadMessagesCount: (state, action) => {
      if (state.pendingRequestCount > 0) {
        state.pendingRequestCount = state.pendingRequestCount - 1;
      }
    },
    addMessage: (state, action) => {
      // Push the new message into the messageList when the message is SENT
      state.messageList.push({
        id: action.payload.id,
        sender_id: state.sender.id,
        receiver_id: state.receiver.id,
        message: action.payload.message,
        original_name: action.payload.original_name,
        file: action.payload.file,
        created_at: action.payload.created_at,
        updated_at: action.payload.updated_at,
        collaboration_id: action.payload.collaboration_id,
        offer_id: action.payload.offer_id,
        system_generated: action.payload.system_generated,
        sender: state.sender,
        receiver: state.receiver,
        type: action?.payload?.type
      });
      // Update the last message of the corresponding chat in chatList
      const chatIndex = state.chatList.findIndex(chat => chat.chat_id === action.payload.collaboration_id);
      if (chatIndex !== -1) {
        state.chatList[chatIndex].last_message = action.payload;
      }

    },
    // FOR RECEIVER
    updateChatAndMessage: (state, action) => {
      const payload = action?.payload?.data;
      // Return early if system-generated message has conflicting sender/receiver
      if (
        payload?.system_generated === 1 &&
        payload?.receiver_id === state?.currentUser?.id &&
        payload?.sender_id === state?.currentUser?.id
      ) {
        return;
      }

      const collaborationId = payload?.collaboration_id?.toString();
      const message = payload?.message ?? ""; 
      const isSystemGenerated = payload?.system_generated?.toString() === "1";

      // Find the index of the relevant chat in the chatList
      const chatIndex = state.chatList.findIndex(
        (chat) => chat.chat_id?.toString() === collaborationId
      );

      if (
        message === "Invite request received" ||
        message.includes("has bought your pack")
      ) {
        const newChat = {
          user: {
            id: payload?.sender?.id,
            username: payload?.sender?.username,
            email: payload?.sender?.email,
            creator: payload?.sender?.creator,
            brand: payload?.sender?.brand,
          },
          unread_message_count: 1,
          last_message: {
            id: payload.id,
            sender_id: payload.sender_id,
            receiver_id: payload.receiver_id,
            message,
            file: payload.file || payload.fileData,
            created_at: payload.created_at,
            updated_at: payload.updated_at,
            collaboration_id: payload.collaboration_id,
            offer_id: payload.offer_id,
            system_generated: payload.system_generated,
            is_read: payload.is_read,
            file_submission_id: payload.file_submission_id,
            task_extension_request_id: payload.task_extension_request_id,
            task_submission_id: payload.task_submission_id,
            adjustment_id: payload.adjustment_id,
            type: payload.type,
            original_name: payload.original_name,
            sender: payload.sender,
            receiver: payload.receiver,
          },
          chat_id: payload?.collaboration_id,
        };

        // Add the new chat to the beginning of the chatList
        state.chatList = [newChat, ...state.chatList];
      }

      // Update unread messages and pending request counts based on message types
      const incrementUnreadCounts = () => {
        state.unReadMessagesCount += 1;
        if (message === "Invite request sent" || message === "Invite request received") {
          state.pendingRequestCount += 1;
        }
      };

      if (
        ["Invite request sent", "Invite request received", "Collaboration Accepted", "Collaboration Rejected"].includes(
          message
        )
      ) {
        incrementUnreadCounts();
      }

      // Update existing chat in chatList
      if (chatIndex !== -1) {
        state.chatList[chatIndex] = {
          ...state.chatList[chatIndex],
          last_message: {
            ...state.chatList[chatIndex].last_message,
            message,
            created_at: payload.created_at,
          },
        };

        if (payload.collaboration_id !== state.currentChatId) {
          state.unReadMessagesCount += 1;
          state.chatList[chatIndex].unread_message_count += 1;
        }
      }

      // Update messageList if the current chat is active
      if (state.currentChatId?.toString() === collaborationId) {
        if (isSystemGenerated) {
          state.currentStage = payload.chatFlow;
          state.offer = payload.offer;
        }

        chatSlice.caseReducers.handleChatStages(state); 

        state.messageList.push({
          ...payload,
          message: payload.message ?? "",
          file: payload.file || null,
        });
      }
    },

    resetMessages: (state, action) => {
      // state.messageList = [];
    },
    updateMessageList: (state, action) => {
      state.messageList.unshift(...action.payload.data?.data?.data);

      // state.messageList = [...tmpMessageList, action.payload.data?.data?.data.reverse()]
      state.currentPage = action?.payload?.data?.data?.current_page;
    },
    resetStates: (state, action) => {
      // state.totalMessagePages = 0;
      // state.currentPage = 0
      state.deliveryTimeBar = false;
      state.inviteRequestAccepted = false;
      state.workInProcess = false;
      state.offerAccepted = false;
      state.acceptOffer = false;
      state.packBought = false;
      state.workSent = false;
      state.workReceived = false;
      state.workReceivedAgain = false;
      state.proofSent = false;
      state.proofReceived = false;
      state.proofReceivedAgain = false;

      state.proofAccepted = false;
      state.proofRejected = false;
      state.issueReported = false;
      state.customOfferSent = false;
      state.customOfferReceived = false;
      state.changeRequestSent = false;
      state.changeRequestReceived = false;
      state.workSubmitSentAgain = false;
      state.changeRequestReceivedAgain = false;
      state.appealRequest = false;
      state.decisionInMyFav = false;
      state.decisionInCreatorFav = false;
      state.decisionInBrandFav = false;
      state.paymentProcessed = false;

      state.extensionRequestSent = false;
      state.extensionRequestReceived = false;
      state.collabComplete = false;
      state.reviewIsGiven = false;
      state.reviewIsReceived = false;
      state.bothHaveGivenReviews = false;

      state.brandFavourDecision = false;
      state.workAccepted = false;
      state.workAccept = false;
      state.chatRequestSent = false;
      state.chatRequestReceived = false;
      state.loadingAcceptOffer = false;
      state.loadingRejectOffer = false;

      state.loadingBrandPayment = false;
      // state.offer = {};
      state.extensionReqRej = false;
      state.extensionReqRejSuccess = false;
      state.submitExtensionError = false;
      state.askAdjustmentError = false;
      state.submitLinkError = false;

      state.extensionReqAccept = false;
      state.extensionReqAcceptSuccess = false;
      state.isLateDelivery = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(GetChatListApi.pending, (state, action) => {

        state.chatList = [];
        state.messageList = [];
        state.loadingChats = true;
        state.error = null;
      })
      .addCase(GetChatListApi.fulfilled, (state, action) => {
        const sortedChats = action?.payload?.data?.data?.sort((a, b) => {
          const timestampA = new Date(a?.last_message?.created_at).getTime();
          const timestampB = new Date(b?.last_message?.created_at).getTime();
          return timestampB - timestampA;
        });
        state.loadingChats = false;
        // state.chatList = action.payload.data.data;
        state.chatList = sortedChats;
      })
      .addCase(GetChatListApi.rejected, (state, action) => {


        state.loadingChats = false;
        state.error = action;
      })
      .addCase(GetMessagesListApi.pending, (state, action) => {

        state.loadingMessages = true;
        state.error = null;
      })
      .addCase(GetMessagesListApi.fulfilled, (state, action) => {
        state.reportIssueTime = action?.payload?.data?.submitted_work?.report_issue_time;
        state.loadingMessages = false;
        state.currentStage = action?.payload?.data?.current_stage;
        state.offer = action?.payload?.data?.offer;
        state.currentPage = action?.payload?.data?.data?.current_page;
        state.totalMessagePages = action?.payload?.data?.data?.last_page;

        // Assigning the sender of the message here
        if (
          action?.payload?.data?.data?.data[0]?.sender_id.toString() ===
          state.currentUser.id.toString()
        ) {
          state.sender = action?.payload?.data?.data?.data[0]?.sender;
        } else {
          state.sender = action?.payload?.data?.data?.data[0]?.receiver;
        }

        // Assigning the receiver of the message here
        if (
          action.payload.data?.data?.data[0]?.sender_id.toString() ===
          state.currentUser.id.toString()
        ) {
          state.receiver = action?.payload?.data?.data?.data[0]?.receiver;
        } else {
          state.receiver = action?.payload?.data?.data?.data[0]?.sender;
        }
        chatSlice.caseReducers.handleChatStages(state);

        state.messageList = action?.payload?.data?.data?.data.reverse();
      })
      .addCase(GetMessagesListApi.rejected, (state, action) => {

        state.loadingMessages = false;
        state.error = action;
      })

      .addCase(MakePaymentBrand.pending, (state, action) => {
        state.loadingBrandPayment = true;
      })
      .addCase(MakePaymentBrand.fulfilled, (state, action) => {
        state.loadingBrandPayment = false;
      })
      .addCase(MakePaymentBrand.rejected, (state, action) => {
        state.loadingBrandPayment = false;
      })

      .addCase(AcceptOfferApi.pending, (state, action) => {

        state.loadingAcceptOffer = true;
        state.loadingBrandPayment = true;

      })
      .addCase(AcceptOfferApi.fulfilled, (state, action) => {

        state.loadingAcceptOffer = false;
        state.loadingBrandPayment = false;
      })
      .addCase(AcceptOfferApi.rejected, (state, action) => {

        state.loadingAcceptOffer = false;
        state.loadingBrandPayment = false;

      })
      .addCase(RejectOfferApi.pending, (state, action) => {

        state.loadingRejectOffer = true;
      })
      .addCase(RejectOfferApi.fulfilled, (state, action) => {

        state.loadingRejectOffer = false;
      })
      .addCase(RejectOfferApi.rejected, (state, action) => {

        state.loadingRejectOffer = false;
      })
      .addCase(SubmitWorkApi.pending, (state, action) => {

        state.workLoading = true;
      })
      .addCase(SubmitWorkApi.fulfilled, (state, action) => {
        document.getElementById("submit-work-modal-close").click();

        state.workLoading = false;
        state.workSent = true;
      })
      .addCase(SubmitWorkApi.rejected, (state, action) => {

        state.workLoading = false;
      })
      .addCase(AcceptSubmitWorkApi.pending, (state, action) => {

        state.workLoading = true;
      })
      .addCase(AcceptSubmitWorkApi.fulfilled, (state, action) => {

        state.workLoading = false;
      })
      .addCase(AcceptSubmitWorkApi.rejected, (state, action) => {

        state.workLoading = false;
      })
      .addCase(AskAdjustmentApi.pending, (state, action) => {

        state.loadingAcceptOffer = true;
      })
      .addCase(AskAdjustmentApi.fulfilled, (state, action) => {
        state.loadingAcceptOffer = false;
        document.getElementById("adjustment-modal-close").click();
      })
      .addCase(AskAdjustmentApi.rejected, (state, action) => {
        state.askAdjustmentError = action.payload;

        state.loadingAcceptOffer = false;
      })
      .addCase(SubmitLinkApi.pending, (state, action) => {

        state.workLoading = true;
      })
      .addCase(SubmitLinkApi.fulfilled, (state, action) => {
        state.workLoading = false;
        document.getElementById("close-submit-link-modal").click();
      })
      .addCase(SubmitLinkApi.rejected, (state, action) => {

        state.submitLinkError = action?.payload?.errors;
        state.workLoading = false;
      })
      .addCase(addReviewApi.pending, (state, action) => {

        state.workLoading = true;
      })
      .addCase(addReviewApi.fulfilled, (state, action) => {
        document.getElementById("review-modal-close").click();
        state.reviewIsGiven = true;
        state.workLoading = false;
      })
      .addCase(addReviewApi.rejected, (state, action) => {

        state.workLoading = false;
      })
      .addCase(AcceptLinkApi.pending, (state, action) => {

        state.workLoading = true;
      })
      .addCase(AcceptLinkApi.fulfilled, (state, action) => {
        state.workLoading = false;
      })
      .addCase(AcceptLinkApi.rejected, (state, action) => {

        state.workLoading = false;
      })
      .addCase(RejectLinkApi.pending, (state, action) => {

        state.loadingRejectOffer = true;
      })
      .addCase(RejectLinkApi.fulfilled, (state, action) => {
        state.loadingRejectOffer = false;
        document.getElementById("close-reject-submission-modal").click();

      })
      .addCase(RejectLinkApi.rejected, (state, action) => {

        state.loadingRejectOffer = false;
      })
      .addCase(ReportIssueApi.pending, (state, action) => {

        state.workLoading = true;
      })
      .addCase(ReportIssueApi.fulfilled, (state, action) => {
        state.workLoading = false;
        document.getElementById("close-report-modal").click();
      })
      .addCase(ReportIssueApi.rejected, (state, action) => {

        state.workLoading = false;
      })
      .addCase(AcceptExtensionApi.pending, (state, action) => {

        state.extensionReqAccept = true;
        state.loadingAcceptOffer = true;
      })
      .addCase(AcceptExtensionApi.fulfilled, (state, action) => {

        state.loadingAcceptOffer = false;
        state.extensionReqAccept = false;
        state.extensionReqAcceptSuccess = true;
        state.deliveryTimeBar = true;
        state.workInProcess = true;
      })
      .addCase(AcceptExtensionApi.rejected, (state, action) => {


        state.loadingAcceptOffer = false;

        state.extensionReqAccept = false;
        state.extensionReqAcceptSuccess = false;
      })
      .addCase(RejectExtensionApi.pending, (state, action) => {

        state.extensionReqRej = true;
        state.extensionReqRejSuccess = false;
        state.loadingRejectOffer = true;
      })
      .addCase(RejectExtensionApi.fulfilled, (state, action) => {

        state.loadingRejectOffer = false;
        state.extensionReqRej = false;
        state.extensionReqRejSuccess = true;
      })
      .addCase(RejectExtensionApi.rejected, (state, action) => {

        state.loadingRejectOffer = false;
        state.extensionReqRej = false;
        state.extensionReqRejSuccess = false;
      })
      .addCase(SubmitExtensionApi.pending, (state, action) => {

        state.loadingRejectOffer = true;
      })
      .addCase(SubmitExtensionApi.fulfilled, (state, action) => {
        document.getElementById("request-extension-modal-close").click();

        state.deliveryTimeBar = false;
        state.loadingRejectOffer = false;
      })
      .addCase(SubmitExtensionApi.rejected, (state, action) => {

        state.submitExtensionError = action?.payload?.error;
        state.loadingRejectOffer = false;
      })
      .addCase(SendCustomOfferApi.pending, (state, action) => {

        state.workLoading = true;
      })
      .addCase(SendCustomOfferApi.fulfilled, (state, action) => {
        state.workLoading = false;
      })
      .addCase(SendCustomOfferApi.rejected, (state, action) => {

        state.workLoading = false;
      })
      .addCase(SubmitAppealApi.pending, (state, action) => {
        state.loadingAcceptOffer = true;
      })
      .addCase(SubmitAppealApi.fulfilled, (state, action) => {
        document.getElementById("submit-appeal-modal-close").click();
        state.loadingAcceptOffer = false;
      })
      .addCase(SubmitAppealApi.rejected, (state, action) => {
        state.loadingAcceptOffer = false;
      })


      .addCase(GetUnreadMessageCountApi.fulfilled, (state, action) => {
        // state.pendingRequestCount = action?.payload?.data?.pending_collaborations_count;
        state.unReadMessagesCount = action?.payload?.data?.data;
      })
      .addCase(GetPendingCollaborationsCount.fulfilled, (state, action) => {
        state.pendingRequestCount = action?.payload?.data?.data;

      })
      .addCase(getCurrentStageOfChat.fulfilled, (state, action) => {

        state.reportIssueTime = action?.payload?.data?.latest_system_generated_message?.task_submission?.report_issue_time;
        state.currentStage = action?.payload?.data?.current_stage;
        state.offer = action?.payload?.data?.offer;
        if (!state.messageList.find((d) => d.id === action.payload?.data?.latest_system_generated_message.id)) {
          state.messageList.push(
            action.payload?.data?.latest_system_generated_message
          );
        }

        chatSlice.caseReducers.handleChatStages(state);
      })
      .addCase(markSingleChatAsReadApi.fulfilled, (state, action) => {
        let index = state?.chatList?.findIndex((d) => d.chat_id === state.currentChatId);
        if (index > -1) {
          let numToMinus = state.chatList[index]?.unread_message_count;
          state.chatList[index].unread_message_count = 0;
          if (state.unReadMessagesCount > 0) {
            state.unReadMessagesCount = state.unReadMessagesCount - numToMinus;
          }
        }
      })
      .addCase(logout, (state) => {
        return initialState;
      });
  },
});

export const {
  resetMessages,
  resetStates,
  addMessage,
  setCurrentUser,
  updateChatAndMessage,
  setCurrentChatId,
  setTotalUnreadMessagesCount,
  updateMessageList
} = chatSlice.actions;

export const loadingChats = (state) => state.loadingChats;
export const loadingMessages = (state) => state.loadingMessages;
export const messageList = (state) => state.messageList;
export const unReadMessagesCount = (state) => state.unReadMessagesCount;
export const pendingRequestCount = (state) => state.pendingRequestCount;


export const chatList = (state) => state.chatList;
export const error = (state) => state.error;
export const success = (state) => state.success;

// Working Chat Variables Exported:
export const currentChatId = (state) => state.currentChatId;
export const receiverId = (state) => state.receiverId;
export const currentOffer = (state) => state.currentOffer;
export const adjustmentId = (state) => state.adjustmentId;
export const submittedWork = (state) => state.submittedWork;
export const currentStage = (state) => state.currentStage;
export const offer = (state) => state.offer;

// Working Pagination Variables Exported:
export const nextPageMessageUrl = (state) => state.nextPageMessageUrl;
export const currentPage = (state) => state.currentPage;
export const totalMessagePages = (state) => state.totalMessagePages
export const fromMPage = (state) => state.fromMPage;
export const lastMPage = (state) => state.lastMPage;

// Chat States Exported:
export const inviteRequestAccepted = (state) => state.inviteRequestAccepted;
export const workInProcess = (state) => state.workInProcess;
export const deliveryTimeBar = (state) => state.deliveryTimeBar;
export const isLateDelivery = (state) => state.isLateDelivery;
export const offerAccepted = (state) => state.offerAccepted;
export const acceptOffer = (state) => state.acceptOffer;
export const packBought = (state) => state.packBought;
export const workSent = (state) => state.workSent;
export const workReceived = (state) => state.workReceived;
export const workReceivedAgain = (state) => state.workReceivedAgain;
export const proofSent = (state) => state.proofSent;
export const proofReceived = (state) => state.proofReceived;
export const proofReceivedAgain = (state) => state.proofReceivedAgain;

export const proofAccepted = (state) => state.proofAccepted;
export const proofRejected = (state) => state.proofRejected;
export const issueReported = (state) => state.issueReported;
export const customOfferSent = (state) => state.customOfferSent;
export const customOfferReceived = (state) => state.customOfferReceived;
export const changeRequestSent = (state) => state.changeRequestSent;
export const changeRequestReceived = (state) => state.changeRequestReceived;
export const workSubmitSentAgain = (state) => state.workSubmitSentAgain;
export const changeRequestReceivedAgain = (state) =>
  state.changeRequestReceivedAgain;
export const appealRequest = (state) => state.appealRequest;
export const decisionInMyFav = (state) => state.decisionInMyFav;
export const decisionInCreatorFav = (state) => state.decisionInCreatorFav;
export const decisionInBrandFav = (state) => state.decisionInBrandFav;
export const paymentProcessed = (state) => state.paymentProcessed;

export const extensionRequestSent = (state) => state.extensionRequestSent;
export const extensionRequestReceived = (state) =>
  state.extensionRequestReceived;
export const extensionReqAccept = (state) => state.extensionReqAccept;
export const extensionReqAcceptSuccess = (state) =>
  state.extensionReqAcceptSuccess;
export const extensionReqRej = (state) => state.extensionReqRej;
export const extensionReqRejSuccess = (state) => state.extensionReqRejSuccess;
export const submitExtensionError = (state) => state.submitExtensionError;
export const askAdjustmentError = (state) => state.askAdjustmentError;


export const submitLinkError = (state) => state.submitLinkError;


export const collabComplete = (state) => state.collabComplete;
export const reviewIsGiven = (state) => state.reviewIsGiven;
export const reviewIsReceived = (state) => state.reviewIsReceived;
export const bothHaveGivenReviews = (state) => state.bothHaveGivenReviews;


export const brandFavourDecision = (state) => state.brandFavourDecision;
export const workAccepted = (state) => state.workAccepted;
export const workAccept = (state) => state.workAccept;
export const reportIssueTime = (state) => state.reportIssueTime;
export const chatRequestSent = (state) => state.chatRequestSent;
export const chatRequestReceived = (state) => state.chatRequestReceived;

// Loading Variables Export:
export const loadingAcceptOffer = (state) => state.loadingAcceptOffer;
export const loadingRejectOffer = (state) => state.loadingRejectOffer;

export const loadingBrandPayment = (state) => state.loadingBrandPayment;

export const workLoading = (state) => state.workLoading;

export default chatSlice.reducer;

import React, { useEffect, useState } from "react";
import AppLayout from "../../Components/AppLayout";
import {
  getUserPublicProfile,
  getUserReviews,
  updateBrandProfile,
} from "../../Common/Repository";
import Reviews from "../../Components/Reviews";
import { EmailIcon, PenIcon } from "../../Components/Icons";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CircleImageNFound from "../../Components/404_CircleImage";
import { useFilePicker } from "use-file-picker";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../states/auth/authSlice";

const Profile = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { collaborationsData } = useSelector((state) => state.collaborations);

  const { user, token } = useSelector((state) => state.auth);

  const [isReviews, setIsReviews] = useState(false);

  const [selectedLocalities, setSelectedLocalities] = useState(
    user?.brand?.localities ?? []
  );

  const [brandName, setName] = useState(user?.brand.brand_name ?? "");

  const [bio, setBio] = useState(user?.brand.bio ?? "");

  const [websiteLink, setWebsiteLink] = useState(
    user?.brand?.website_link ?? ""
  );

  const [instagramLink, setInstagramLink] = useState(
    user?.brand?.instagram ?? ""
  );

  const [linkedinLink, setLinkedinLink] = useState(user?.brand?.linkedIn ?? "");

  const [address, setAddress] = useState(user?.brand?.company_address ?? "");

  const [phone, setPhone] = useState(user?.brand?.contact_details ?? "");

  const [reviewsReceived, setReviewsReceived] = useState("");

  const [averageReviews, setAverageReviews] = useState("");

  const [totalReviewCount, setTotalReviewCount] = useState("");

  // eslint-disable-next-line
  const [errors, setErrors] = useState([]);

  const [selectedCategories, setSelectedCategories] = useState(
    user?.brand?.profile_tags || []
  );

  const categories = {
    lifestyle: [
      "Lifestyle",
      "Travel",
      "Pets",
      "Causes",
      "Decor",
      "Family",
      "Wedding",
      "Dogs",
      "Cats",
      "Environmentalism",
      "Garden",
      "Home",
    ],
    arts: [
      "Arts",
      "Crafts",
      "Graffiti",
      "Tattoo",
      "Painting",
      "Sculpture",
      "Photography",
      "DIY",
      "Cats",
      "Handmade",
      "Scrapbooking",
    ],
    beauty: [
      "Fashion",
      "Beauty",
      "Beachwear",
      "Boutique",
      "Menswear",
      "Models",
      "Plus Size",
      "Showroom",
      "Streetwear",
      "Stylist",
      "Lingerie",
      "Makeup",
      "Nails",
      "Lashes",
      "Hairstyling",
      "Skincare",
      "Beautysalon",
      "Barber",
    ],
    health: [
      "Food",
      "Health",
      "Craftbeer",
      "Bakery",
      "Restaurant",
      "Chef",
      "Veganism",
      "Spirituality",
      "Nutrition",
      "Wellness",
      "Meditation",
      "Relaxation",
      "Motivational",
    ],
    sport: [
      "Sport",
      "Fitness",
      "Karate",
      "Boxing",
      "JiuJitsu",
      "Judo",
      "MMA",
      "Wrestling",
      "Running",
      "Athletics",
      "Cycling",
      "Gymnastics",
      "Olympian",
      "Climbing",
      "Diving",
      "Rowing",
      "Skating",
      "Skiing",
      "Tennis",
      "Badminton",
      "Basketball",
      "Cricket",
      "Golf",
      "Handball",
      "Hockey",
    ],
    tech: ["Business", "Tech", "Entrepreneur", "Cars"],
    music: [
      "Music",
      "Media",
      "Cinema",
      "Acting",
      "DJ",
      "Hip-hop",
      "Guitar",
      "Piano",
      "Violin",
      "Singer",
      "Instrumentalist",
      "Songwriter",
      "Music Composer",
      "Dancer",
      "Journalist",
      "Radio",
      "Theater",
    ],
    games: ["Culture", "Games", "Comicbooks"],
  };

  const getPublicView = async () => {
    try {
      dispatch(getUserPublicProfile({ token, user }));
    } catch (error) {
      setErrors(error?.response?.data?.error);
    }
  };

  useEffect(() => {
    getPublicView();
    // eslint-disable-next-line
  }, []);

  const handleCategoriesClick = (categoryName) => {
    let index = selectedCategories.indexOf(categoryName);
    if (index > -1) {
      let newArray = [...selectedCategories];
      newArray.splice(index, 1);
      setSelectedCategories((prevVal) => newArray);
    } else {
      if (selectedCategories.length > 4) {
        toast.error("You can not select more than 5 categories.");
      } else {
        setSelectedCategories((prevVal) => [...prevVal, categoryName]);
      }
    }
  };
  const [openFileSelector] = useFilePicker({
    readAs: "DataURL",
    accept: "image/jpeg, image/png",
    multiple: false,
    limitFilesConfig: { max: 1 },
    maxFileSize: 50,
    onFilesSelected: ({ plainFiles, filesContent, errors }) => {
      // this callback is always called, even if there are errors
      handleProfileImageUpdate(filesContent);
    },
  });

  const uniqueCreators = [
    ...new Set(collaborationsData.map((collab) => collab.creator_id)),
  ].length;

  const completedCollaborations = collaborationsData.filter(
    (collab) => collab.status === "completed"
  ).length;

  const dataURItoBlob = (dataURI) => {
    if (dataURI) {
      const byteString = atob(dataURI.split(",")[1]);
      const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    }
  };

  const handleProfileImageUpdate = async (filesContent) => {
    try {
      let newFormData = new FormData();
      let blobbedImage = dataURItoBlob(filesContent[0]?.content);
      newFormData.append(`profile_image`, blobbedImage);
      let response = await updateBrandProfile(
        {
          Authorization: `Bearer ${token}`,
        },
        newFormData
      );
      if (response?.status === 200) {
        const newUser = {
          ...user,
          brand: {
            ...user?.brand,
            profile_image: filesContent[0]?.content,
          },
        };
        dispatch(setUser(newUser));
        toast.success("Profile Image Updated Successfully!");
      }
    } catch (error) {
      setErrors(error?.response?.data?.error);

      toast.error("Failed to update Profile Image");
    }
  };

  const handleNameUpdate = async () => {
    try {
      const data = {};
      data.brand_name = brandName;
      let response = await updateBrandProfile(
        {
          Authorization: `Bearer ${token}`,
        },
        data
      );
      if (response?.status === 200) {
        const newUser = {
          ...user,
          brand: {
            ...user?.brand,
            brand_name: brandName,
          },
        };
        dispatch(setUser(newUser));
        document.getElementById("brand-name-modal-close").click();
        toast.success("Name Updated Successfully!");
      }
    } catch (error) {
      setErrors(error?.response?.data?.error);
      toast.error("Name Update Failed!");
    }
  };

  const handleBioUpdate = async () => {
    try {
      const data = {};
      data.bio = bio;
      let response = await updateBrandProfile(
        {
          Authorization: `Bearer ${token}`,
        },
        data
      );
      if (response?.status === 200) {
        const newUser = {
          ...user,
          brand: {
            ...user?.brand,
            bio: bio,
          },
        };
        dispatch(setUser(newUser));
        toast.success("Bio updated successfully!");
        document.getElementById("close-bio-modal").click();
      }
    } catch (error) {
      setErrors(error?.response?.data?.error);
      toast.error("Failed to update Bio");
    }
  };

  const handleWebsiteUpdate = async () => {
    try {
      const data = {};
      data.website_link = websiteLink;
      let response = await updateBrandProfile(
        {
          Authorization: `Bearer ${token}`,
        },
        data
      );
      if (response?.status === 200) {
        const newUser = {
          ...user,
          brand: {
            ...user?.brand,
            website_link: websiteLink,
          },
        };
        dispatch(setUser(newUser));
        toast.success("Website link updated successfully!");
        document.getElementById("close-website-modal").click();
      }
    } catch (error) {
      setErrors(error?.response?.data?.error);
      toast.error("Failed to update website link");
    }
  };

  const handleInstagramUpdate = async () => {
    try {
      const data = {};
      data.instagram = instagramLink;
      let response = await updateBrandProfile(
        {
          Authorization: `Bearer ${token}`,
        },
        data
      );
      if (response?.status === 200) {
        const newUser = {
          ...user,
          brand: {
            ...user?.brand,
            instagram: instagramLink,
          },
        };
        dispatch(setUser(newUser));
        toast.success("Instagram handle updated successfully!");
        document.getElementById("close-instagram-modal").click();
      }
    } catch (error) {
      setErrors(error?.response?.data?.error);
      toast.error("Failed to update Instagram handle");
    }
  };

  const handleLinkedinUpdate = async () => {
    try {
      const data = {};
      data.linkedIn = linkedinLink;
      let response = await updateBrandProfile(
        {
          Authorization: `Bearer ${token}`,
        },
        data
      );
      if (response?.status === 200) {
        const newUser = {
          ...user,
          brand: {
            ...user?.brand,
            linkedIn: linkedinLink,
          },
        };
        dispatch(setUser(newUser));
        toast.success("LinkedIn handle updated successfully!");
        document.getElementById("close-linkedin-modal").click();
      }
    } catch (error) {
      setErrors(error?.response?.data?.error);
      toast.error("Failed to update LinkedIn handle");
    }
  };

  const handleAddressUpdate = async () => {
    try {
      const data = {};
      data.company_address = address;
      let response = await updateBrandProfile(
        {
          Authorization: `Bearer ${token}`,
        },
        data
      );
      if (response?.status === 200) {
        const newUser = {
          ...user,
          brand: {
            ...user?.brand,
            company_address: address,
          },
        };
        dispatch(setUser(newUser));
        toast.success("Address Updated Successfully!");
        document.getElementById("address-modal-close").click();
      }
    } catch (error) {
      setErrors(error?.response?.data?.errors);
      toast.error("Failed to update Address");
    }
  };

  const handlePhoneUpdate = async () => {
    try {
      const data = {};
      data.contact_details = phone;

      let response = await updateBrandProfile(
        {
          Authorization: `Bearer ${token}`,
        },
        data
      );
      if (response?.status === 200) {
        const newUser = {
          ...user,
          brand: {
            ...user?.brand,
            contact_details: phone,
          },
        };

        dispatch(setUser(newUser));
        toast.success("Phone Updated Successfully!");
        document.getElementById("phone-modal-close").click();
      }
    } catch (error) {
      setErrors(error?.response?.data?.error);
      toast.error("Failed to update Phone");
    }
  };

  const handleCategoriesUpdate = async () => {
    try {
      const data = {};
      data.niches = selectedCategories.join(","); // Join the array elements with commas

      let response = await updateBrandProfile(
        {
          Authorization: `Bearer ${token}`,
        },
        data
      );

      if (response?.status === 200) {
        // Create a new user object instead of modifying the existing one
        const newUser = {
          ...user,
          brand: {
            ...user.brand,
            profile_tags: selectedCategories,
          },
        };

        dispatch(setUser(newUser));
        toast.success("Categories updated successfully!");
        document.getElementById("profile-tag-modal-close").click();
      }
    } catch (error) {
      setErrors(error?.response?.data?.errors);
      toast.error("Failed to update categories!");
    }
  };

  const handleLocalitiesUpdate = async () => {
    try {
      const data = {};
      data.localities = selectedLocalities;
      let response = await updateBrandProfile(
        {
          Authorization: `Bearer ${token}`,
        },
        data
      );
      if (response?.status === 200) {
        const newUser = {
          ...user,
          brand: {
            ...user?.brand,
            localities: selectedLocalities,
          },
        };

        dispatch(setUser(newUser));
        toast.success("Localities Updated Successfully!");
        document.getElementById("localities-modal-close").click();
      }
    } catch (error) {
      setErrors(error?.response?.data?.error);
      toast.error("Failed to update Localities");
    }
  };

  const handleLocalitiesClick = (tag) => {
    if (selectedLocalities.includes(tag)) {
      setSelectedLocalities(
        selectedLocalities.filter((selectedTag) => selectedTag !== tag)
      );
    } else {
      if (selectedLocalities.length < 6) {
        setSelectedLocalities([...selectedLocalities, tag]);
      }
    }
  };

  // useEffect(() => {
  //   handleLocalitiesUpdate();
  // }, [selectedLocalities]);

  const availableTags = [
    "India",
    "Brasil",
    "Portugal",
    "Germany",
    "France",
    "USA",
  ];

  const handlePublicView = () => {
    navigate("/brandDetails", { state: user?.brand?.id });
  };

  async function handleGetReviews() {
    try {
      const data = {};
      data.user_id = user?.id;
      let response = await getUserReviews(
        {
          Authorization: `Bearer ${token}`,
        },
        data
      );
      if (response?.status === 200) {
        // console.log('response: ', response);
        setReviewsReceived(response.data?.data?.reviews);
        setAverageReviews(response.data?.data?.average_rating);
        setTotalReviewCount(response.data?.data?.total_reviews);
      }
    } catch (error) {
      setErrors(error?.response?.data?.errors);
      toast.error("Failed to get reviews!");
    }
  }

  useEffect(() => {
    handleGetReviews();
    // eslint-disable-next-line
  }, []);

  return (
    <AppLayout>
      <div className="my-5 py-5">
        <div className="mx-auto settings-container mt-3">
          <div className="user-profile-dashboard profile-dashboard">
            <div className="userOutsideProfileBox pt-5">
              <div className="row mobile-col wm-100">
                <div className="col-md-4">
                  <div className="row px-md-3">
                    <div className="col-5">
                      {user?.brand?.profile_image !== undefined &&
                        user?.brand?.profile_image !== null ? (
                        <img
                          className="brand-profile-image"
                          src={user?.brand?.profile_image}
                          alt="avatar"
                        />
                      ) : (
                        <CircleImageNFound width={100} height={100} />
                      )}
                    </div>

                    <div className="col-7">
                      <h6 className="text-center text-truncate">
                        {user?.brand?.brand_name ?? "*"}
                      </h6>
                      <div className="text-center creatorProfileDetail py-2 px-3">
                        <div
                          style={{
                            maxWidth: "100%",
                            maxHeight: "100%",
                          }}
                          className="d-flex justify-content-center align-items-center"
                        >
                          <img
                            src="/assets/images/locationIcon.svg"
                            className="me-1"
                            alt="location"
                            style={{
                              maxWidth: "20px",
                              maxHeight: "20px",
                            }}
                          />
                          <p
                            style={{
                              maxWidth: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              margin: 0,
                            }}
                            className="my-auto"
                          >
                            {user?.brand?.company_address ?? "Location"}
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-7">
                      <h6>{user?.brand?.brand_name ?? "*"}</h6>
                      <div className="creatorProfileDetail">
                        <ul className="ps-0 mb-0">
                          <li className="mt-md-3">
                            <img
                              src="/assets/images/locationIcon.svg"
                              className="mb-md-1 me-1"
                              alt="location"
                            />
                            {user?.brand.company_address ?? "-"}
                          </li>
                        </ul>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="mt-4 mt-md-0 col-md-4 border-start border-end no-border-phone">
                  <div className="row">
                    <div className="col-12 d-flex">
                      <p
                        style={{
                          color:
                            user?.instagram_verified !== null &&
                              user?.instagram_verified !== undefined &&
                              user?.instagram_verified
                              ? "#009966"
                              : "#b92d2d",
                        }}
                        className="confirmationText wm-100"
                      >
                        {/* The {user?.brand.brand_name ?? "*"} has confirmed */}
                        {/* {user?.instagram_verified !== null &&
                          user?.instagram_verified !== undefined &&
                          user?.instagram_verified
                          ? "Vitrine Verified"
                          : "Not Verified"} */}
                      </p>
                    </div>
                    {user?.instagram_verified != null ? (
                      <div className="col-6">
                        <button className="socialIconVerified">
                          <img
                            src="/assets/images/InstaIcon.svg"
                            className="mb-1 me-1"
                            alt="insta"
                          />
                          Instagram
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                    {user?.email_verified_at != null ? (
                      <div className="col-6">
                        <button className="socialIconVerified">
                          <EmailIcon className="socialIcon" />
                          {" Mail"}
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="row justify-content-center d-flex">
                    {/* <div className="col-4 align-center">
                      <div className="iconBg">
                        <img
                          className=""
                          src="/assets/images/share-new.svg"
                          alt="avatar"
                        />
                      </div>
                    </div> */}
                    <div className="col-md-8">
                      <div className="user-profile-box-inner border-0">
                        <div className="public-profile-box text-center">
                          <button
                            onClick={() => {
                              handlePublicView();
                            }}
                            className="profile-btns"
                          >
                            View Public Profile
                          </button>
                        </div>
                        <div className="text-center">
                          <button
                            onClick={() => setIsReviews(!isReviews)}
                            className="profile-btns"
                          >
                            View Reviews
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="ms-1 col-md-2 mt-4 mt-md-0 text-center">
                  <div
                    style={{
                      width: "70%",
                    }}
                    className="link-style creatorProfile whiteButton"
                    onClick={() => openFileSelector()}
                  >
                    Upload Image
                  </div>
                </div>
              </div>
            </div>
            <div className="profile-settings-board mt-5">
              <div className="row">
                <div className="col-6 py-4 d-flex">
                  <p className="profile-name-text-dark mb-0">
                    <b>Total Payouts:</b>
                  </p>
                  <span className="">&nbsp;N/A</span>
                </div>
                <div className="col-6 py-4 d-flex">
                  <p className="profile-name-text-dark mb-0">
                    <b>Completed Collaborations:</b>
                  </p>
                  <span className="">&nbsp;{completedCollaborations}</span>
                </div>
                <div className="col-6 py-4 d-flex">
                  <p className="profile-name-text-dark mb-0">
                    <b>Worked with Creators:</b>
                  </p>
                  <span className="">&nbsp; {uniqueCreators}</span>
                </div>
                {/* <div
                  data-bs-toggle="modal"
                  data-bs-target="#selecttag-modal"
                  className="col-6 py-4 d-flex"
                >
                  <p className="profile-name-text-dark mb-0">
                    <b>Supported Localities:</b>
                  </p>
                  <span className="">
                    {selectedLocalities.length > 0 ? (
                      <>
                        <div>&nbsp;{selectedLocalities.join(", ")}</div>
                      </>
                    ) : (
                      <>
                        <div className="text-highlight ">
                          &nbsp;
                          <span className="link-style">Select Localities</span>
                        </div>
                      </>
                    )}
                  </span>
                </div> */}
              </div>
            </div>
            {isReviews ? (
              reviewsReceived?.length ? (
                <>
                  <Reviews
                    reviewsReceived={reviewsReceived}
                    totalReviews={totalReviewCount}
                    averageStars={averageReviews}
                  />
                </>
              ) : (
                <>
                  <div className="my-5">
                    <div className="reviewHeading">
                      <div>Reviews</div>
                      <div className="reviewStars"></div>
                    </div>
                    <div className="bg-white text-center p-5 rounded-3">
                      No Reviews Yet
                    </div>
                  </div>
                </>
              )
            ) : (
              <>
                <div className="profile-settings-board mt-5">
                  <div className="col-lg-12">
                    <div className="user-profile-description-box d-flex w-100">
                      <div className="user-description-left-box">
                        <p className="profile-name-text-dark mb-0">
                          <b>Profile Name</b>
                        </p>
                        <span className="profileTagDescription">
                          {user?.brand.brand_name ?? "*"}
                        </span>
                      </div>
                      <div className="user-description-right-box w-50 text-end">
                        <button
                          type="button"
                          className="btn btn-primary pe-0 bg-transparent border-0"
                          data-bs-toggle="modal"
                          data-bs-target="#profile-modal"
                        >
                          <PenIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="user-profile-description-box d-flex w-100">
                      <div className="user-description-left-box">
                        <p className="profile-name-text-dark mb-0">
                          <b>Username </b>
                          <span className="profileTagDescription">
                            (not public only for internal system use)
                          </span>
                        </p>
                        <span className="profileTagDescription">
                          {user?.username ?? "*"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="user-profile-description-box d-flex w-100">
                      <div className="user-description-left-box w-50">
                        <p className="profile-name-text-dark mb-0">
                          <b>Bio</b>
                        </p>
                        <span className="profileTagDescription">
                          {user?.brand.bio ??
                            "Tell us a bit about your brand, and what it focuses on."}
                        </span>
                      </div>
                      <div className="user-description-right-box w-50 text-end">
                        <button
                          type="button"
                          className="btn btn-primary pe-0 bg-transparent border-0"
                          data-bs-toggle="modal"
                          data-bs-target="#bio-modal"
                        >
                          <PenIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* // website  */}
                  <div className="col-lg-12">
                    <div className="user-profile-description-box d-flex w-100">
                      <div className="user-description-left-box w-50">
                        <p className="profile-name-text-dark mb-0">
                          <b>Website link</b>
                        </p>
                        <span className="profileTagDescription">
                          {user?.brand.website_link ??
                            "Share your website URL."}
                        </span>
                      </div>
                      <div className="user-description-right-box w-50 text-end">
                        <button
                          type="button"
                          className="btn btn-primary pe-0 bg-transparent border-0"
                          data-bs-toggle="modal"
                          data-bs-target="#website-modal"
                        >
                          <PenIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* // Instagram */}
                  <div className="col-lg-12">
                    <div className="user-profile-description-box d-flex w-100">
                      <div className="user-description-left-box w-50">
                        <p className="profile-name-text-dark mb-0">
                          <b>Instagram handle</b>
                        </p>
                        <span className="profileTagDescription">
                          {user?.brand?.instagram ??
                            "Share your Instagram handle."}
                        </span>
                      </div>
                      <div className="user-description-right-box w-50 text-end">
                        <button
                          type="button"
                          className="btn btn-primary pe-0 bg-transparent border-0"
                          data-bs-toggle="modal"
                          data-bs-target="#instagram-modal"
                        >
                          <PenIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* // linkedin  */}
                  <div className="col-lg-12">
                    <div className="user-profile-description-box d-flex w-100">
                      <div className="user-description-left-box w-50">
                        <p className="profile-name-text-dark mb-0">
                          <b>LinkedIn handle</b>
                        </p>
                        <span className="profileTagDescription">
                          {user?.brand?.linkedIn ??
                            "Share your LinkedIn handle."}
                        </span>
                      </div>
                      <div className="user-description-right-box w-50 text-end">
                        <button
                          type="button"
                          className="btn btn-primary pe-0 bg-transparent border-0"
                          data-bs-toggle="modal"
                          data-bs-target="#linkedin-modal"
                        >
                          <PenIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="user-profile-description-box d-flex w-100">
                      <div className="user-description-left-box">
                        <p className="profile-name-text-dark mb-0">
                          <b>Interested in Creators from</b>
                        </p>
                        <span className="profileTagDescription">
                          {selectedLocalities.length > 0 ? (
                            <>
                              <div>&nbsp;{selectedLocalities.join(", ")}</div>
                            </>
                          ) : (
                            <>
                              <div>Select Localities</div>
                            </>
                          )}
                        </span>
                      </div>
                      <div className="user-description-right-box w-50 text-end">
                        <button
                          type="button"
                          className="btn btn-primary pe-0 bg-transparent border-0"
                          data-bs-toggle="modal"
                          data-bs-target="#selecttag-modal"
                        >
                          <PenIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="user-profile-description-box d-flex w-100">
                      <div className="user-description-left-box w-50">
                        <p className="profile-name-text-dark mb-0">
                          <b>Interested Tags</b>
                        </p>
                        <span className="profileTagDescription">
                          {Array.isArray(user?.brand?.profile_tags)
                            ? user?.brand?.profile_tags.join(", ")
                            : "Enter tags that you want to get creator listings about."}
                        </span>
                      </div>
                      <div className="user-description-right-box w-50 text-end">
                        <button
                          type="button"
                          className="btn btn-primary pe-0 bg-transparent border-0"
                          data-bs-toggle="modal"
                          data-bs-target="#interested-tags-modal"
                        >
                          <PenIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="user-profile-description-box d-flex w-100">
                      <div className="user-description-left-box">
                        <p className="profile-name-text-dark mb-0">
                          <b>Brand Office Address</b>
                        </p>
                        <span className="profileTagDescription">
                          {user?.brand?.company_address ??
                            "Enter your address to receive deliveries."}
                        </span>
                      </div>
                      <div className="user-description-right-box w-50 text-end">
                        <button
                          type="button"
                          className="btn btn-primary pe-0 bg-transparent border-0"
                          data-bs-toggle="modal"
                          data-bs-target="#address-modal"
                        >
                          <PenIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="user-profile-description-box d-flex w-100">
                      <div className="user-description-left-box">
                        <p className="profile-name-text-dark mb-0">
                          <b>Phone</b>
                        </p>
                        <span className="profileTagDescription">
                          {user?.brand?.contact_details ?? "Visible to no one"}
                        </span>
                      </div>
                      <div className="user-description-right-box w-50 text-end">
                        <button
                          type="button"
                          className="btn btn-primary pe-0 bg-transparent border-0"
                          data-bs-toggle="modal"
                          data-bs-target="#phone-modal"
                        >
                          <PenIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        {/* localities modal  */}
        <div
          className="modal fade"
          id="selecttag-modal"
          tabIndex={-1}
          aria-labelledby="user-profile-settings-modal"
          aria-hidden="true"
        >
          <div className="modal-dialog user-settings-modal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="user-profile-Label">
                  Supported Localities
                </h5>
                <button
                  type="button"
                  className="popup-close-btn fs-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="tag-modal-close"
                >
                  <svg
                    width={15}
                    height={15}
                    x={0}
                    y={0}
                    viewBox="0 0 426.667 426.667"
                    style={{ enableBackground: "new 0 0 512 512" }}
                    xmlSpace="preserve"
                  >
                    <g>
                      <path
                        d="M426.667 59.733 366.933 0l-153.6 153.6L59.733 0 0 59.733l153.6 153.6L0 366.933l59.733 59.734 153.6-153.6 153.6 153.6 59.734-59.734-153.6-153.6z"
                        fill="#000000"
                        data-original="#000000"
                      />
                    </g>
                  </svg>
                </button>
              </div>
              <div className="modal-body">
                <div className="col-md-12 popup-box pt-4 d-block">
                  <div className="profile-tags-box-outer mb-5 d-flex flex-wrap">
                    {availableTags.map((tag, index) => (
                      <div key={index} className="radio-with-Icon me-2 mt-2">
                        <p className="radioOption-Item radioOption-Item-category mb-0">
                          <input
                            type="checkbox"
                            name={`category-btn-${index}`}
                            id={`category-btn${index}`}
                            value={tag}
                            checked={selectedLocalities.includes(tag)}
                            onChange={() => handleLocalitiesClick(tag)}
                          />
                          <label
                            className="profile-tag-btn"
                            htmlFor={`category-btn${index}`}
                          >
                            <span>{tag}</span>
                          </label>
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    id="localities-modal-close"
                    className="whiteButton"
                  >
                    Discard
                  </button>
                  <button
                    type="button"
                    className="greenButton btn"
                    onClick={() => {
                      handleLocalitiesUpdate();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
              {/* <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  document.getElementById("tag-modal-close").click();
                }}
              >
                Submit
              </button>
            </div> */}
            </div>
          </div>
        </div>
        {/* Profile-Modal */}
        <div
          className="modal fade"
          id="profile-modal"
          tabIndex={-1}
          aria-labelledby="user-profile-settings-modal"
          aria-hidden="true"
        >
          <div className="modal-dialog userSettingsModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="user-profile-Label">
                  Profile Settings
                </h5>
              </div>
              <div className="modal-body">
                <div className="col-md-12 d-block">
                  <div className="label-box">
                    <label>Profile Name*</label>
                  </div>
                  <div className="input-box">
                    <input
                      type="text"
                      className="w-100"
                      value={brandName}
                      placeholder="Enter your full name"
                      onChange={(event) => {
                        setName(event.target.value);
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          handleNameUpdate();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="brand-name-modal-close"
                  className="whiteButton"
                >
                  Discard
                </button>
                <button
                  type="button"
                  className="greenButton btn"
                  onClick={() => {
                    handleNameUpdate();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Website-modal */}
        <div
          className="modal fade"
          id="website-modal"
          tabIndex={-1}
          aria-labelledby="user-profile-settings-modal"
          aria-hidden="true"
        >
          <div className="modal-dialog userSettingsModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="user-profile-Label">
                  Profile Settings
                </h5>
              </div>
              <div className="modal-body">
                <div className="col-md-12 d-block">
                  <div className="label-box">
                    <label>Website link</label>
                  </div>
                  <div className="input-box">
                    <input
                      className="ant-select-selection-search-input w-100"
                      value={websiteLink}
                      placeholder="Link of you website."
                      onChange={(event) => {
                        setWebsiteLink(event.target.value);
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          handleWebsiteUpdate();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="close-website-modal"
                  className="whiteButton"
                >
                  Discard
                </button>
                <button
                  type="button"
                  className="greenButton btn"
                  onClick={() => {
                    handleWebsiteUpdate();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Instagram-Modal */}
        <div
          className="modal fade"
          id="instagram-modal"
          tabIndex={-1}
          aria-labelledby="user-profile-settings-modal"
          aria-hidden="true"
        >
          <div className="modal-dialog userSettingsModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="user-profile-Label">
                  Profile Settings
                </h5>
              </div>
              <div className="modal-body">
                <div className="col-md-12 d-block">
                  <div className="label-box">
                    <label>Instagram handle</label>
                  </div>
                  <div className="input-box">
                    <input
                      type="text"
                      className="ant-select-selection-search-input w-100"
                      value={instagramLink}
                      placeholder="Share your Instagram handle."
                      onChange={(event) => {
                        setInstagramLink(event.target.value);
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          handleInstagramUpdate();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="close-instagram-modal"
                  className="whiteButton"
                >
                  Discard
                </button>
                <button
                  type="button"
                  className="greenButton btn"
                  onClick={() => {
                    handleInstagramUpdate();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Linkedin-Modal  */}
        <div
          className="modal fade"
          id="linkedin-modal"
          tabIndex={-1}
          aria-labelledby="user-profile-settings-modal"
          aria-hidden="true"
        >
          <div className="modal-dialog userSettingsModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="user-profile-Label">
                  Profile settings
                </h5>
              </div>
              <div className="modal-body">
                <div className="col-md-12 d-block">
                  <div className="label-box">
                    <label>LinkedIn handle</label>
                  </div>
                  <div className="input-box">
                    <input
                      type="text"
                      className="ant-select-selection-search-input w-100"
                      value={linkedinLink}
                      placeholder="Share your linkedIn handle"
                      onChange={(event) => {
                        setLinkedinLink(event.target.value);
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          handleLinkedinUpdate();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="close-linkedin-modal"
                  className="whiteButton"
                >
                  Discard
                </button>
                <button
                  type="button"
                  className="greenButton btn"
                  onClick={() => {
                    handleLinkedinUpdate();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Bio-Modal */}
        <div
          className="modal fade"
          id="bio-modal"
          tabIndex={-1}
          aria-labelledby="user-profile-settings-modal"
          aria-hidden="true"
        >
          <div className="modal-dialog userSettingsModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="user-profile-Label">
                  Profile Settings
                </h5>
              </div>
              <div className="modal-body">
                <div className="col-md-12 d-block">
                  <div className="label-box">
                    <label>Bio*</label>
                  </div>
                  <div className="input-box">
                    <textarea
                      className="textarea w-100"
                      value={bio}
                      placeholder="Tell us a bit about you, your interests, your profile and your social media channels."
                      onChange={(event) => {
                        setBio(event.target.value);
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          handleBioUpdate();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="close-bio-modal"
                  className="whiteButton"
                >
                  Discard
                </button>
                <button
                  type="button"
                  className="greenButton btn"
                  onClick={() => {
                    handleBioUpdate();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Profiletag-Modal */}
        <div
          className="modal fade"
          id="interested-tags-modal"
          tabIndex={-1}
          aria-labelledby="user-profile-settings-modal"
          aria-hidden="true"
        >
          <div className="modal-dialog userSettingsModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="user-profile-Label">
                  Profile Tags
                </h5>
              </div>
              <div className="modal-body">
                <div className="col-md-12 popup-box pt-4 d-block">
                  <div className="heading-box">
                    <p>
                      We want to make sure that your profile outstands. Please,
                      select up to 5 tags that define yourself
                    </p>
                  </div>
                  <div className="profile-tags-box-outer mt-5 mb-5">
                    <span>Lifestyle &amp; Decor</span>
                    <div className="profile-tags-box d-flex flex-wrap">
                      {categories.lifestyle.map((value, index) => {
                        return (
                          <button
                            key={`${value}-${index}`}
                            className={
                              selectedCategories.includes(value)
                                ? "profile-tag-selected mt-2 me-2"
                                : "profile-tag-btn mt-2 me-2"
                            }
                            onClick={() => {
                              handleCategoriesClick(value);
                            }}
                          >
                            {value}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                  <div className="profile-tags-box-outer mt-5 mb-5">
                    <span>Arts</span>
                    <div className="profile-tags-box d-flex flex-wrap">
                      {categories.arts.map((value, index) => {
                        return (
                          <button
                            key={`${value}-${index}`}
                            className={
                              selectedCategories.includes(value)
                                ? "profile-tag-selected mt-2 me-2"
                                : "profile-tag-btn mt-2 me-2"
                            }
                            onClick={() => {
                              handleCategoriesClick(value);
                            }}
                          >
                            {value}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                  <div className="profile-tags-box-outer mt-5 mb-5">
                    <span>Fashion &amp; Beauty</span>
                    <div className="profile-tags-box d-flex flex-wrap">
                      {categories.beauty.map((value, index) => {
                        return (
                          <button
                            key={`${value}-${index}`}
                            className={
                              selectedCategories.includes(value)
                                ? "profile-tag-selected mt-2 me-2"
                                : "profile-tag-btn mt-2 me-2"
                            }
                            onClick={() => {
                              handleCategoriesClick(value);
                            }}
                          >
                            {value}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                  <div className="profile-tags-box-outer mt-5 mb-5">
                    <span>Health &amp; Food</span>
                    <div className="profile-tags-box d-flex flex-wrap">
                      {categories.health.map((value, index) => {
                        return (
                          <button
                            key={`${value}-${index}`}
                            className={
                              selectedCategories.includes(value)
                                ? "profile-tag-selected mt-2 me-2"
                                : "profile-tag-btn mt-2 me-2"
                            }
                            onClick={() => {
                              handleCategoriesClick(value);
                            }}
                          >
                            {value}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                  <div className="profile-tags-box-outer mt-5 mb-5">
                    <span>Sport</span>
                    <div className="profile-tags-box d-flex flex-wrap">
                      {categories.sport.map((value, index) => {
                        return (
                          <button
                            key={`${value}-${index}`}
                            className={
                              selectedCategories.includes(value)
                                ? "profile-tag-selected mt-2 me-2"
                                : "profile-tag-btn mt-2 me-2"
                            }
                            onClick={() => {
                              handleCategoriesClick(value);
                            }}
                          >
                            {value}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                  <div className="profile-tags-box-outer mt-5 mb-5">
                    <span>Tech &amp; Business</span>
                    <div className="profile-tags-box d-flex flex-wrap">
                      {categories.tech.map((value, index) => {
                        return (
                          <button
                            key={`${value}-${index}`}
                            className={
                              selectedCategories.includes(value)
                                ? "profile-tag-selected mt-2 me-2"
                                : "profile-tag-btn mt-2 me-2"
                            }
                            onClick={() => {
                              handleCategoriesClick(value);
                            }}
                          >
                            {value}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                  <div className="profile-tags-box-outer mt-5 mb-5">
                    <span>Music &amp; Media</span>
                    <div className="profile-tags-box d-flex flex-wrap">
                      {categories.music.map((value, index) => {
                        return (
                          <button
                            key={`${value}-${index}`}
                            className={
                              selectedCategories.includes(value)
                                ? "profile-tag-selected mt-2 me-2"
                                : "profile-tag-btn mt-2 me-2"
                            }
                            onClick={() => {
                              handleCategoriesClick(value);
                            }}
                          >
                            {value}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                  <div className="profile-tags-box-outer mt-5 mb-5">
                    <span>Games</span>
                    <div className="profile-tags-box d-flex flex-wrap">
                      {categories.games.map((value, index) => {
                        return (
                          <button
                            key={`${value}-${index}`}
                            className={
                              selectedCategories.includes(value)
                                ? "profile-tag-selected mt-2 me-2"
                                : "profile-tag-btn mt-2 me-2"
                            }
                            onClick={() => {
                              handleCategoriesClick(value);
                            }}
                          >
                            {value}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                  {/* <div class="label-box"><label>Profile Name<span class="text-danger fs-1">*</span></label></div>
                      <div class="input-box"><input type="text" class="w-100"></div> comment till here */}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="profile-tag-modal-close"
                  className="whiteButton"
                >
                  Discard
                </button>
                <button
                  type="button"
                  className="btn common-button-style-filled"
                  onClick={() => {
                    handleCategoriesUpdate();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>

        {/*address-Modal */}
        <div
          className="modal fade"
          id="address-modal"
          tabIndex={-1}
          aria-labelledby="user-profile-settings-modal"
          aria-hidden="true"
        >
          <div className="modal-dialog userSettingsModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="user-profile-Label">
                  Profile settings
                </h5>
              </div>
              <div className="modal-body">
                <div className="col-md-12 d-block">
                  <div className="label-box">
                    <label>Complete address</label>
                  </div>
                  <div className="input-box">
                    <input
                      className="ant-select-selection-search-input w-100"
                      defaultValue={""}
                      value={address}
                      onChange={(event) => {
                        setAddress(event.target.value);
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          handleAddressUpdate();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="address-modal-close"
                  className="whiteButton"
                >
                  Discard
                </button>
                <button
                  type="button"
                  className="greenButton btn"
                  onClick={() => {
                    handleAddressUpdate();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Phone-Modal */}
        <div
          className="modal fade"
          id="phone-modal"
          tabIndex={-1}
          aria-labelledby="user-profile-settings-modal"
          aria-hidden="true"
        >
          <div className="modal-dialog userSettingsModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="user-profile-Label">
                  Profile settings
                </h5>
              </div>
              <div className="modal-body">
                <div className="col-md-12 d-block">
                  <div className="label-box">
                    <label>Contact number</label>
                  </div>
                  <div className="input-box">
                    <input
                      type="tel"
                      className="ant-select-selection-search-input w-100"
                      value={phone}
                      onChange={(event) => {
                        setPhone(event.target.value);
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          handlePhoneUpdate();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="phone-modal-close"
                  className="whiteButton"
                >
                  Discard
                </button>
                <button
                  type="button"
                  className="greenButton btn"
                  onClick={() => {
                    handlePhoneUpdate();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* fee-Modal */}

        {/* <div
          className="modal fade"
          id="fee-modal"
          tabIndex={-1}
          aria-labelledby="user-profile-settings-modal"
          aria-hidden="true"
        >
          <div className="modal-dialog userSettingsModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="user-profile-Label">
                  Profile Settings
                </h5>
                <button
                  type="button"
                  className="popup-close-btn fs-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <svg
                    width={15}
                    height={15}
                    x={0}
                    y={0}
                    viewBox="0 0 426.667 426.667"
                    style={{ enableBackground: "new 0 0 512 512" }}
                    xmlSpace="preserve"
                    className
                  >
                    <g>
                      <path
                        d="M426.667 59.733 366.933 0l-153.6 153.6L59.733 0 0 59.733l153.6 153.6L0 366.933l59.733 59.734 153.6-153.6 153.6 153.6 59.734-59.734-153.6-153.6z"
                        fill="#000"
                        data-original="#549A9C"
                        className
                      />
                    </g>
                  </svg>
                </button>
              </div>
              <div className="modal-body">
                  <div className="col-md-12 d-block">
                    <div className="label-box">
                      <label>
                        Profile Name<span className="text-danger fs-1">*</span>
                      </label>
                    </div>
                    <div className="input-box">
                      <input type="text" className="w-100" />
                    </div>
                  </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="greenButton btn">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div> */}
        {/*location-Modal */}
        {/* <div
          className="modal fade"
          id="location-modal"
          tabIndex={-1}
          aria-labelledby="user-profile-settings-modal"
          aria-hidden="true"
        >
          <div className="modal-dialog userSettingsModal">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="user-profile-Label">
                  Location(City)
                </h5>
                <button
                  type="button"
                  className="popup-close-btn fs-0"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <svg
                    width={15}
                    height={15}
                    x={0}
                    y={0}
                    viewBox="0 0 426.667 426.667"
                    style={{ enableBackground: "new 0 0 512 512" }}
                    xmlSpace="preserve"
                    className
                  >
                    <g>
                      <path
                        d="M426.667 59.733 366.933 0l-153.6 153.6L59.733 0 0 59.733l153.6 153.6L0 366.933l59.733 59.734 153.6-153.6 153.6 153.6 59.734-59.734-153.6-153.6z"
                        fill="#000"
                        data-original="#549A9C"
                        className
                      />
                    </g>
                  </svg>
                </button>
              </div>
              <div className="modal-body">
                  <div className="col-md-12 d-block">
                    <div className="input-box mt-5">
                      <input
                        type="text"
                        placeholder="Enter your location"
                        className="w-100"
                      />
                    </div>
                  </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="greenButton btn">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </AppLayout>
  );
};

export default Profile;

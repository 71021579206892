import { axiosApi, axiosAuthApi } from "../Core/Constant";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setSingleCampaignData } from "../states/singleCampaign/singleCampaignSlice";
import { setCampaigns } from "../states/campaigns/campaignSlice";
import { setCreators } from "../states/creators/creatorSlice";
import {
  setAverageReviewRating,
  setCreatorPacksData,
  setCreatorsCollaborationData,
  setInstagramData,
  setReviewsReceived,
  setSingleCreatorData,
  setTotalReviewCount,
} from "../states/creatorDetails/creatorDetailsSlice";
import { setBrandCatalogueData } from "../states/brands/brandSlice";
import {
  setApprovedCollab,
  setAverageRating,
  setBrandDetails,
  setCollabCount,
  setReviewCount,
  setReviewData,
} from "../states/brandDetails/brandDetailsSlice";
import { setMyCampaigns } from "../states/myCampaigns/myCampaignSlice";
import { setPacks } from "../states/packs/packSlice";
import { setSinglePackData } from "../states/packDetails/packDetailsSlice";
import { setCollaborations } from "../states/collaborations/collaborationSlice";
import {
  setCollaborationOffers,
  setSingleCollaborationData,
} from "../states/singleCollaboration/singleCollaborationSlice";
import { setRequestsList } from "../states/pendingRequests/pendingRequestsSlice";
import { setNotificationsdata, setUpdateUser } from "../states/auth/authSlice";
import { toast } from "react-toastify";
import { updateMessageList } from "../states/chat/chatSlice";

// Auth Action: Register Api
export const RegisterApi = createAsyncThunk(
  "auth/register",
  async ({ data }, { rejectWithValue }) => {
    try {
      let response = await axiosApi.post("/register", data);

      localStorage.setItem("user", JSON.stringify(response?.data?.user));
      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// Auth Action: Login User
export const LoginApi = createAsyncThunk(
  "auth/login",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axiosApi.post("/login", data);
      localStorage.setItem("user", JSON.stringify(response?.data?.user));
      localStorage.setItem("token", response?.data?.token);
      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// Auth Action: Send Forgot Password Mail
export const ForgotPasswordApi = createAsyncThunk(
  "auth/send-forgot-password-mail",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axiosApi.post("/send/reset-code", data);

      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// Auth Action: Reset Password
export const ResetPasswordApi = createAsyncThunk(
  "auth/reset-password",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axiosApi.post("/reset/password", data);

      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// Auth Action: Verify User OTP
export const VerifyOtpApi = createAsyncThunk(
  "auth/verify",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axiosApi.post("/verify-otp", data);

      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// Auth Action: Resend User OTP
export const ResendOtpApi = createAsyncThunk(
  "auth/verify/resend",
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axiosApi.post("/resendOtp", data);

      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// Chat Action: Get Chats List
export const GetChatListApi = createAsyncThunk(
  "chat/list/get",
  async ({ header, data }, { rejectWithValue }) => {
    try {
      const response = await axiosAuthApi(header).post("/get/chats", data);
      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// Chat Action: Get Messages List
export const GetMessagesListApi = createAsyncThunk(
  "messages/list/get",
  async ({ header, formData, dispatch, chat_id }, { rejectWithValue }) => {
    try {
      //
      let data = { chat_id: chat_id };

      const response = await axiosAuthApi(header).post("/get/chat", formData);
      dispatch(markSingleChatAsReadApi({ header, data }));
      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);
export const GetMessagesListPaginatedApi = createAsyncThunk(
  "messages/list/get/paginate",
  async (
    { header, formData, dispatch, chat_id, currentPage },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosAuthApi(header).post(
        `/get/chat?page=${currentPage + 1}`,
        formData
      );
      dispatch(updateMessageList(response));
      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// Chat Action: Accept Offer
export const AcceptOfferApi = createAsyncThunk(
  "messages/offer/accept",
  async (
    { header, formData, dispatch, currentChatId },
    { rejectWithValue }
  ) => {
    try {
      //
      const response = await axiosAuthApi(header).post(
        "/offer/accept",
        formData
      );
      dispatch(getCurrentStageOfChat({ header, currentChatId }));

      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);
export const GetUnreadMessageCountApi = createAsyncThunk(
  "unread/messages/count",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axiosApi.get("/get/unread-messages", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const GetPendingCollaborationsCount = createAsyncThunk(
  "pending/collaboration/count",
  async ({ header, data }, { rejectWithValue }) => {
    try {
      const response = await axiosAuthApi(header).post(
        "/get/pending/collaborations/count",
        data
      );
      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// export const GetUnreadMessageCountApi = createAsyncThunk(
//   "unread/messages/count",
//   async ({ token }, { rejectWithValue, dispatch }) => {
//     try {
//       const response = await axiosApi.get("/get-instagram-link", {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       });
//
//       return response;
//     } catch (error) {
//       // return custom error message from backend if present
//       if (error.response && error.response.data) {
//         return rejectWithValue(error.response.data);
//       } else {
//         return rejectWithValue(error);
//       }
//     }
//   }
// );

export const markSingleChatAsReadApi = createAsyncThunk(
  "messages/single/read",
  async ({ header, data }, { rejectWithValue }) => {
    try {
      //
      const response = await axiosAuthApi(header).post(
        "/mark/single/chat/read",
        data
      );
      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const MakePaymentBrand = createAsyncThunk(
  "messages/brand/payment",
  async (
    { header, formData, dispatch, currentChatId },
    { rejectWithValue }
  ) => {
    try {
      //
      const response = await axiosAuthApi(header).post(
        "/payment/make",
        formData
      );
      dispatch(getCurrentStageOfChat({ header, currentChatId }));

      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);
export const buyPackPaymentSuccessCall = createAsyncThunk(
  "messages/brand/payment",
  async (
    { header, data, dispatch, currentChatId },
    { rejectWithValue }
  ) => {
    try {
      //
      const response = await axiosAuthApi(header).post(
        "/buy/pack",
        data
      );
      dispatch(getCurrentStageOfChat({ header, currentChatId }));

      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);
// export const buyPackPaymentSuccessCall = (header, data) => {
//   return axiosAuthApi(header).post("/buy/pack", data);
// };
// Chat Action: Reject Offer
export const RejectOfferApi = createAsyncThunk(
  "messages/offer/reject",
  async (
    { header, formData, dispatch, currentChatId },
    { rejectWithValue }
  ) => {
    try {
      //
      const response = await axiosAuthApi(header).post(
        "/offer/reject",
        formData
      );
      dispatch(getCurrentStageOfChat({ header, currentChatId }));

      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// Chat Action: Submit Work
export const SubmitWorkApi = createAsyncThunk(
  "messages/work/submit",
  async (
    { header, formData, dispatch, currentChatId, clear, setFilesToBeSent },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosAuthApi(header).post(
        "/work/submit",
        formData
      );
      if (response) {
        clear();
        setFilesToBeSent("");
      }
      dispatch(getCurrentStageOfChat({ header, currentChatId }));

      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// Chat Action: Accept Submitted Work
export const AcceptSubmitWorkApi = createAsyncThunk(
  "messages/work/accept",
  async (
    { header, formData, dispatch, currentChatId },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosAuthApi(header).post(
        "/work/accept",
        formData
      );
      dispatch(getCurrentStageOfChat({ header, currentChatId }));

      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// Chat Action: Ask For Adjustment
export const AskAdjustmentApi = createAsyncThunk(
  "messages/work/adjustment",
  async (
    { header, formData, dispatch, currentChatId },
    { rejectWithValue }
  ) => {
    try {
      //
      const response = await axiosAuthApi(header).post(
        "/ask/adjustment",
        formData
      );
      dispatch(getCurrentStageOfChat({ header, currentChatId }));

      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// Chat Action: Submit Link
export const SubmitLinkApi = createAsyncThunk(
  "messages/link/submit",
  async (
    { header, formData, dispatch, currentChatId },
    { rejectWithValue }
  ) => {
    try {
      //
      const response = await axiosAuthApi(header).post(
        "/submit/link",
        formData
      );
      dispatch(getCurrentStageOfChat({ header, currentChatId }));

      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// Chat Action: add review
export const addReviewApi = createAsyncThunk(
  "messages/add/review",
  async (
    { header, formData, dispatch, currentChatId },
    { rejectWithValue }
  ) => {
    try {
      //
      const response = await axiosAuthApi(header).post(
        "collaboration/review/add",
        formData
      );
      dispatch(getCurrentStageOfChat({ header, currentChatId }));

      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// Chat Action: Accept Link
export const AcceptLinkApi = createAsyncThunk(
  "messages/link/accept",
  async (
    { header, formData, dispatch, currentChatId },
    { rejectWithValue }
  ) => {
    try {
      //
      const response = await axiosAuthApi(header).post(
        "/link/accept",
        formData
      );
      dispatch(getCurrentStageOfChat({ header, currentChatId }));

      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// Chat Action: Reject Link
export const RejectLinkApi = createAsyncThunk(
  "messages/link/reject",
  async (
    { header, formData, dispatch, currentChatId },
    { rejectWithValue }
  ) => {
    try {
      //
      const response = await axiosAuthApi(header).post(
        "/link/reject",
        formData
      );
      dispatch(getCurrentStageOfChat({ header, currentChatId }));

      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// Chat Action: Report Issue
export const ReportIssueApi = createAsyncThunk(
  "messages/report",
  async (
    { header, formData, dispatch, currentChatId, clear, setSubmittingWork },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosAuthApi(header).post(
        "/report/issue",
        formData
      );
      if (response) {
        clear();
        setSubmittingWork("");
      }
      dispatch(getCurrentStageOfChat({ header, currentChatId }));

      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// Chat Action: Accept Extension
export const AcceptExtensionApi = createAsyncThunk(
  "messages/extension/accept",
  async (
    { header, formData, dispatch, currentChatId },
    { rejectWithValue }
  ) => {
    try {
      //
      const response = await axiosAuthApi(header).post(
        "/accept/extension",
        formData
      );
      dispatch(getCurrentStageOfChat({ header, currentChatId }));

      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// Chat Action: Reject Extension
export const RejectExtensionApi = createAsyncThunk(
  "messages/extension/reject",
  async (
    { header, formData, dispatch, currentChatId },
    { rejectWithValue }
  ) => {
    try {
      //
      const response = await axiosAuthApi(header).post(
        "/reject/extension",
        formData
      );
      dispatch(getCurrentStageOfChat({ header, currentChatId }));

      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// Chat Action: Submit Extension
export const SubmitExtensionApi = createAsyncThunk(
  "messages/extension/submit",
  async (
    { header, formData, dispatch, currentChatId },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosAuthApi(header).post(
        "/ask/extension",
        formData
      );

      dispatch(getCurrentStageOfChat({ header, currentChatId }));

      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// Chat Action: Send Custom Offer
export const SendCustomOfferApi = createAsyncThunk(
  "messages/offer/custom/send",
  async (
    { header, formData, dispatch, currentChatId },
    { rejectWithValue }
  ) => {
    try {
      //
      const response = await axiosAuthApi(header).post(
        "/offer/create",
        formData
      );
      dispatch(getCurrentStageOfChat({ header, currentChatId }));

      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// Chat Action: Send Custom Offer
export const SubmitAppealApi = createAsyncThunk(
  "messages/appeal/create",
  async (
    { header, formData, dispatch, currentChatId, setDescription },
    { rejectWithValue }
  ) => {
    try {
      //
      const response = await axiosAuthApi(header).post("/appeal", formData);

      if (response) {
        setDescription("");
      }
      dispatch(getCurrentStageOfChat({ header, currentChatId }));

      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);
export const getCurrentStageOfChat = createAsyncThunk(
  "currentChatStage/getCurrentChatStage",
  async ({ header, currentChatId }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosAuthApi(header).post("/get/current/stage", {
        collaboration_id: currentChatId,
      });
      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const allMsgReadCall = (header) => {
  return axiosAuthApi(header).get("/mark/chat/read");
};

export const markMsgAsReadCall = (header, data) => {
  return axiosAuthApi(header).post("/mark/message/read", data);
};

export const getFilteredChatListCall = (header, data) => {
  return axiosAuthApi(header).post("/get/chats", data);
};

export const sendMessageToReceiver = (header, formData) => {
  return axiosAuthApi(header).post("/message/send", formData);
};

// Pack Action: Edit | Update
export const UpdatePack = createAsyncThunk(
  "pack/update",
  async ({ header, formData }, { rejectWithValue }) => {
    try {
      //
      const response = await axiosAuthApi(header).post(
        "/update/pack-proposal",
        formData
      );
      return response;
    } catch (error) {
      //
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// Filter Creators Catalogue
export const FilterCreatorsCatalogue = createAsyncThunk(
  "filter/creators",

  async ({ token, data, dropdownOpen }, { rejectWithValue }) => {
    try {
      const response = await axiosApi.post("/filter/creator", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dropdownOpen();
      // const response = await axiosAuthApi(header).post("/", data);
      return response;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// Filter Other Brands Catalogue
export const FilterOtherBrandsCatalogue = createAsyncThunk(
  "filter/brands",
  async ({ header, formData }, { rejectWithValue }) => {
    try {
      //

      const response = axiosAuthApi(header).post("/brands/filter", formData);

      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// Filter Creator Campaigns
export const FilterCampaignsCreator = createAsyncThunk(
  "filter/campaigns",
  async ({ header, formData }, { rejectWithValue }) => {
    try {
      const response = axiosAuthApi(header).post("/filter/campaign", formData);

      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const singleCampaignDetails = createAsyncThunk(
  "singleCampaign/getDetails",
  async ({ token, data }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosApi.post("/campaign/get", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(setSingleCampaignData(response?.data?.data));
      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const getCampaigns = createAsyncThunk(
  "campaigns/getCreatorCampaigns",
  async ({ token }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosApi.get("/campaigns/get", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(setCampaigns(response?.data?.data));
      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const getCreators = createAsyncThunk(
  "creators/getCreators",
  async ({ token, dropdownOpen }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosApi.get("/get/creators", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dropdownOpen();
      dispatch(setCreators(response?.data?.data));
      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const getUserPublicProfile = createAsyncThunk(
  "user/public/profile",
  async ({ token, user }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosApi.get("/get/user/public-view", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (user?.role === "2") {
        dispatch(setBrandDetails(response?.data?.data));
      }
      if (user?.role === "1") {
        dispatch(setUpdateUser(response?.data?.data?.user));
        dispatch(setSingleCreatorData(response?.data?.data));
        dispatch(setCreatorPacksData(response?.data?.data?.pack_proposals))
        dispatch(setReviewsReceived(response?.data?.data?.reviews));
        dispatch(setTotalReviewCount(response?.data?.data?.total_reviews));
        dispatch(setAverageReviewRating(response?.data?.data?.average_rating));
      }
      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const getSingleCreatorCall = createAsyncThunk(
  "creatorDetails/getSingleCreator",
  async ({ token, data }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosApi.post("/get/creator", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(setCreatorsCollaborationData(response?.data?.collaborations));
      dispatch(setSingleCreatorData(response?.data?.data));
      dispatch(setReviewsReceived(response?.data?.data?.reviews));
      dispatch(setInstagramData(response?.data?.data?.user?.instagram_data))
      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const getAllPacksForBrandCall = createAsyncThunk(
  "packs/getPacks",
  async ({ token, data }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosApi.post("/get/packs", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(setCreatorPacksData(response?.data?.data));
      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const getBrands = createAsyncThunk(
  "brands/getBrands",
  async ({ token }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosApi.get("/get/brands", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch(setBrandCatalogueData(response?.data?.data));
      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const getSingleBrandCall = createAsyncThunk(
  "brandDetails/getSingleBrand",
  async ({ token, data }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosApi.post("/get/brand", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(setBrandDetails(response?.data?.data));
      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const getReviews = createAsyncThunk(
  "reviews/getReviews",
  async ({ token, data }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosApi.post(
        "/collaboration/brand/reviews/get",
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch(setApprovedCollab(response?.data?.data.active_collaborations));
      dispatch(
        setCollabCount(response?.data?.data.approved_collaborations_count)
      );
      dispatch(setReviewData(response?.data?.data.reviews));
      dispatch(setAverageRating(response?.data?.data.average_rating));
      dispatch(setReviewCount(response?.data?.data.review_count));
      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const getOwnCampaigns = createAsyncThunk(
  "myCampaigns/getMyCampaigns",
  async ({ token }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosApi.get("/get/own/campaigns", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(setMyCampaigns(response?.data?.data));
      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const getMyPacks = createAsyncThunk(
  "myPacks/getMyPacks",
  async ({ token }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosApi.get("/get/pack-proposals", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(setPacks(response?.data?.data?.data));
      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const getSinglePackCall = createAsyncThunk(
  "packDetails/getSinglePack",
  async ({ token, data }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosApi.post("/get/pack-proposal", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(setSinglePackData(response?.data));
      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const getMyCollaborationsCall = createAsyncThunk(
  "myCollaborations/getMyCollaborations",
  async ({ token, data }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosApi.post("/get/my-collaborations", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(setCollaborations(response?.data?.data?.collaborations));
      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const getMyPendingCollaborationsCall = createAsyncThunk(
  "myPendingCollaborations/getMyPendingCollaborations",
  async ({ token, data }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosApi.post("/get/my-collaborations", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(setRequestsList(response?.data?.data?.collaborations));
      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const getSingleCollaborationDataCall = createAsyncThunk(
  "collaborationDetails/getCollaborationDetails",
  async ({ token, data }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosApi.post("/collaboration/get", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(setCollaborationOffers(response?.data?.data?.offers));
      dispatch(setSingleCollaborationData(response?.data?.data));
      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const contactUsCall = createAsyncThunk(
  "contactUsPage/contactUs",
  async ({ token, data }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosApi.post("/contact", data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // if (response.status === 200) {
      //   toast.success(
      //     "Successfully sent your request!"
      //   );
      // }
      return response;
    } catch (error) {
      // return custom error message from backend if present
      toast.error("Failed to send your request, please try again later!");
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const getNotificationsApi = createAsyncThunk(
  "notifications/getNotifications",
  async ({ token }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosApi.get("/notifications/get", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(setNotificationsdata(response?.data?.data));

      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const addPack = createAsyncThunk(
  "addPack/addNewPack",
  async (
    { token, formData, navigate, setFiles },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await axiosApi.post("/add/pack-proposal", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      navigate("/packs/view");
      setFiles([]);
      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const sendCollaborationCall = createAsyncThunk(
  "initeCreatorCall/inviteCreator",
  async ({ token, data }, { rejectWithValue }) => {
    try {
      const response = await axiosApi.post(
        "/collaboration/request/send",
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const AcceptAgencyInviteCall = createAsyncThunk(
  "agency/invite/accept",
  async (
    { data, newTabUrl },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosApi.post("/accept/agency/invite", data);

      toast.success(response.data.message, {
        autoClose: 1000,
        onClose: () => {
          window.open(newTabUrl, '_blank');
        }
      });

      return response;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const RejectAgencyInviteCall = createAsyncThunk(
  "agency/invite/reject",
  async (
    { data, newTabUrl },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosApi.post("/reject/agency/invite", data);
      toast.success(response.data.message, {
        autoClose: 1000,
        onClose: () => {
          window.open(newTabUrl, '_blank');
        }
      });
      return response;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

export const disconnectInstagramApi = createAsyncThunk(
  "disconnect/disconnectInstagram",
  async ({ token }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosApi.get("/instagram/disconnect", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setTimeout(() => {
        dispatch(setInstagramData(null))
      }, 2000);
      return response;
    } catch (error) {
      // return custom error message from backend if present
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue(error);
      }
    }
  }
);

// export const sendCollaborationCall = (header, data) => {
//   return axiosAuthApi(header).post("/collaboration/request/send", data);
// };

// export const addPack = (header, data) => {
//   return axiosAuthApi(header).post("/add/pack-proposal", data);
// };

// export const getNotificationsApi = (header) => {
//   return axiosAuthApi(header).get("/notifications/get");
// };

// export const contactUsCall = (header, data) => {
//   return axiosAuthApi(header).post("/contact", data);
// };

export const updateCreatorProfile = (header, data) => {
  return axiosAuthApi(header).post("/update/creator-profile", data);
};

export const deleteUserProfile = (header, data) => {
  return axiosAuthApi(header).post("/delete/user", data);
};

export const updateUserSettings = (header, data) => {
  return axiosAuthApi(header).post("/update/settings", data);
};

export const updateBrandProfile = (header, data) => {
  return axiosAuthApi(header).post("/update/brand/profile", data);
};

export const editPack = (header, data) => {
  return axiosAuthApi(header).post("/update/pack-proposal", data);
};

export const filterPacks = (header, formData) => {
  return axiosAuthApi(header).post("/filter/pack-proposal", formData);
};

export const deletePack = (header, data) => {
  return axiosAuthApi(header).post("/delete/pack-proposal", data);
};

export const addCampaign = (header, formData) => {
  return axiosAuthApi(header).post("/add/campaign", formData);
};

export const updateCampaign = (header, formData) => {
  return axiosAuthApi(header).post("/update/campaign", formData);
};

// export const pendingRequestsCall = (header) => {
//   return axiosAuthApi(header).get("/get/pending/collaborations");
// };

export const acceptCollaborationCall = (header, data) => {
  return axiosAuthApi(header).post("/collaboration/accept", data);
};

export const rejectCollaborationCall = (header, data) => {
  return axiosAuthApi(header).post("/collaboration/reject", data);
};

export const deleteCampaign = (header, formData) => {
  return axiosAuthApi(header).post("/delete/campaign", formData);
};

export const filterCreatorsCatalogueCall = (header, data) => {
  return axiosAuthApi(header).post("/filter/creator", data);
};

export const filterOwnCampaign = (header, formData) => {
  return axiosAuthApi(header).post("/filter/my/campaign", formData);
};

export const filterCollaborations = (header, formData) => {
  return axiosAuthApi(header).post("/collaborations/filter", formData);
};

export const filterCampaignsCreator = (header, formData) => {
  return axiosAuthApi(header).post("/filter/campaign", formData);
};

export const filterOtherBrands = (header, formData) => {
  return axiosAuthApi(header).post("/brands/filter", formData);
};

export const customOfferCall = (header, data) => {
  return axiosAuthApi(header).post("/offer/update", data);
};

export const addBankDetailsCall = (header, data) => {
  return axiosAuthApi(header).post("/add/user-bank", data);
};

export const updateBankDetailsCall = (header, data) => {
  return axiosAuthApi(header).post("/update/user-bank", data);
};

export const deleteBankDetailsCall = (header, data) => {
  return axiosAuthApi(header).post("/delete/user-bank", data);
};

export const addToFavouriteCall = (header, data) => {
  return axiosAuthApi(header).post("/favourite/creator", data);
};

export const removeFavouriteCall = (header, data) => {
  return axiosAuthApi(header).post("/remove/favourite/creator", data);
};

export const getFavouriteCreatorsCall = (header) => {
  return axiosAuthApi(header).get("/get/my/favourite-creators");
};

export const intiateAddBankApi = (header, formData) => {
  return axiosAuthApi(header).post("/onboard", formData);
};

export const getCategoriesApi = (header) => {
  return axiosAuthApi(header).get("/categories/get");
};

export const getUserReviews = (header, formData) => {
  return axiosAuthApi(header).post("/user/review/get", formData);
};

export const getInstagramAccount = (header) => {
  return axiosAuthApi(header).get("/get-instagram-link");
};

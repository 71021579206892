import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import About from "./Pages/About";
import Learn from "./Pages/Learn";
import VitrineLive from "./Pages/Live/VitrineLive";
import AddCampaigns from "./Pages/Brand/AddCampaigns";
// import BrandChat from "./Pages/Brand/BrandChat";
import BrandDetails from "./Pages/Brand/BrandDetails";
import Campaigns from "./Pages/Brand/Campaigns";
import Collabration from "./Pages/Brand/Collabration";
import Profile from "./Pages/Brand/Profile";
import Setting from "./Pages/Brand/Setting";
import SingleCampaignDetails from "./Pages/Brand/SingleCampaignDetails";
import Welcome from "./Pages/Brand/Welcome";
import BrandCatalogue from "./Pages/Brand/BrandCatalogue";
import CreatorsCatalogue from "./Pages/Creators/CreatorsCatalogue";
import CatalogueDetails from "./Pages/Creators/CatalogueDetails";
import CreatorsProfile from "./Pages/Creators/CreatorsProfile";
import CreatorsSetting from "./Pages/Creators/CreatorsSetting";
import MainHelpView from "./Pages/HelpCenter/MainHelpView";
import BrandHelpCenter from "./Pages/HelpCenter/BrandHelpCenter";
import CreatorsHelpCenter from "./Pages/HelpCenter/CreatorsHelpCenter";
import CreatorDetails from "./Pages/Brand/CreatorDetails";
import CampaignDetailsSection from "./Pages/Brand/CampaignDetailsSection";
import OtpVerification from "./Pages/Creators/OtpVerification";
import Packs from "./Pages/Creators/Packs";
import CreatorChat from "./Pages/Creators/CreatorChat";
import CreatorCampaigns from "./Pages/Creators/CreatorCampaigns";
import CreatePack from "./Pages/Creators/Packs/CreatePack";
import EditPack from "./Pages/Creators/Packs/EditPack";
import PackDetails from "./Pages/Creators/Packs/PackDetails";
import CreatorCompleteProfile from "./Pages/Creators/CreatorCompleteProfile";
import BrandCompleteProfile from "./Pages/Brand/BrandCompleteProfile";
import EditCampaign from "./Pages/Brand/EditCampaign";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import CollaborationDetails from "./Pages/CollaborationDetails";
import TermsConditions from "./Pages/TermsConditions";
import ContactUs from "./Pages/ContactUs";
import WrappedPaymentForm from "./Stripe/WrappedPaymentForm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { messaging, onMessageListener, requestPermission } from "./firebase";
import { useEffect } from "react";
import ForgotPasswordPage from "./Pages/ForgotPassword";
import AgencyInvite from "./Pages/Creators/AgencyInvite";
import AuthRoute from "./Components/AuthRoutes";

function App() {
  // const [token, setToken] = useState(localStorage.getItem("token"));
  useEffect(() => {
    if (messaging !== undefined && messaging !== null) {
      requestPermission();
    }
  
    let unsubscribe = null;
  
    onMessageListener()
      .then((payload) => {
        toast.success(
          `${payload?.notification?.title}: ${payload?.notification?.body}`
        );
      })
      .catch(() => {
        console.log("Failed to get notification!");
      })
      .then((cleaner) => {
        unsubscribe = cleaner; // Store the cleanup function
      });
  
    return () => {
      console.log("Return Called....");
      if (typeof unsubscribe === "function") {
        unsubscribe();
      }
    };
  }, []);
  
  // function requireAuth(nextState, replace) {
  //   console.log("Check Token: ", token);
  //   if (token) {
  //     replace({
  //       pathname: '/',
  //       state: { nextPathname: nextState.location.pathname }
  //     })
  //   }
  // }

  return (
    <Router>
      <Routes>
        {/* Un-Authenticated Routes - Start */}
        <Route path="/" element={<VitrineLive />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />

        <Route path="/contactUs" element={<ContactUs />} />

        <Route path="/brandHelp" element={<BrandHelpCenter />} />
        <Route path="/creatorsHelp" element={<CreatorsHelpCenter />} />

        <Route path="/mainHelp" element={<MainHelpView />} />

        <Route path="/otpVerification" element={<OtpVerification />} />
        <Route path="/about" element={<About />} />
        <Route path="/learn" element={<Learn />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/termsAndConditions" element={<TermsConditions />} />

        {/* Un-Authenticated Routes - End */}

        {/* Authenticated Routes - Start */}
        <Route
          path="/addCampaigns"
          element={
            <AuthRoute>
              <AddCampaigns />
            </AuthRoute>
          }
        />
        <Route
          path="/creatorCompleteProfile"
          element={
            <AuthRoute>
              <CreatorCompleteProfile />
            </AuthRoute>
          }
        />
        <Route
          path="/brandCompleteProfile"
          element={
            <AuthRoute>
              <BrandCompleteProfile />
            </AuthRoute>
          }
        />
        <Route
          path="/creatorsCatalogue"
          element={
            <AuthRoute>
              <CreatorsCatalogue />
            </AuthRoute>
          }
        />
        <Route
          path="/brandDetails"
          element={
            <AuthRoute>
              <BrandDetails />
            </AuthRoute>
          }
        />
        <Route
          path="/campaignDetailsSection"
          element={
            <AuthRoute>
              <CampaignDetailsSection />
            </AuthRoute>
          }
        />
        <Route
          path="/myCampaigns"
          element={
            <AuthRoute>
              <Campaigns />
            </AuthRoute>
          }
        />
        <Route
          path="/creatorCampaigns"
          element={
            <AuthRoute>
              <CreatorCampaigns />
            </AuthRoute>
          }
        />
        <Route
          path="/edit-campaign"
          element={
            <AuthRoute>
              <EditCampaign />
            </AuthRoute>
          }
        />
        <Route
          path="/brandCatalogue"
          element={
            <AuthRoute>
              <BrandCatalogue />
            </AuthRoute>
          }
        />
        <Route
          path="/collabration"
          element={
            <AuthRoute>
              <Collabration />
            </AuthRoute>
          }
        />
        <Route
          path="/collabrationDetails"
          element={
            <AuthRoute>
              <CollaborationDetails />
            </AuthRoute>
          }
        />
        <Route
          path="/creatorDetails"
          element={
            <AuthRoute>
              <CreatorDetails />
            </AuthRoute>
          }
        />
        <Route
          path="/brandProfile"
          element={
            <AuthRoute>
              <Profile />
            </AuthRoute>
          }
        />
        <Route
          path="/brandSetting"
          element={
            <AuthRoute>
              <Setting />
            </AuthRoute>
          }
        />
        <Route
          path="/singleCampaignDetails"
          element={
            <AuthRoute>
              <SingleCampaignDetails />
            </AuthRoute>
          }
        />
        <Route
          path="/welcome"
          element={
            <AuthRoute>
              <Welcome />
            </AuthRoute>
          }
        />
        <Route
          path="/creatorsCatalogueDetails"
          element={
            <AuthRoute>
              <CatalogueDetails />
            </AuthRoute>
          }
        />
        <Route
          path="/creatorsProfile"
          element={
            <AuthRoute>
              <CreatorsProfile />
            </AuthRoute>
          }
        />
        <Route
          path="/agencyInvite"
          element={
            <AuthRoute>
              <AgencyInvite />
            </AuthRoute>
          }
        />

        <Route
          path="/packs/view"
          element={
            <AuthRoute>
              <Packs />
            </AuthRoute>
          }
        />
        <Route
          path="/creatorChat"
          element={
            <AuthRoute>
              <CreatorChat />
            </AuthRoute>
          }
        />
        <Route
          path="/creatorsSetting"
          element={
            <AuthRoute>
              <CreatorsSetting />
            </AuthRoute>
          }
        />
        <Route
          path="/pack/create"
          element={
            <AuthRoute>
              <CreatePack />
            </AuthRoute>
          }
        />
        <Route
          path="/pack/edit"
          element={
            <AuthRoute>
              <EditPack />
            </AuthRoute>
          }
        />
        <Route
          path="/pack/details"
          element={
            <AuthRoute>
              <PackDetails />
            </AuthRoute>
          }
        />
        <Route
          path="/stripe-payment"
          element={
            <AuthRoute>
              <WrappedPaymentForm />
            </AuthRoute>
          }
        />
        {/* Authenticated Routes - End */}

        {/* Path for Adding a redirect to show popup */}
        <Route
          path="/signup/brand"
          element={<Navigate to="/?signup=brand" />}
        />
        <Route
          path="/signup/creator"
          element={<Navigate to="/?signup=creator" />}
        />

        {/* Redirects all the routes that are not defined to home page. */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <ToastContainer />
    </Router>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import AppLayout from '../../Components/AppLayout';
import { useDispatch, useSelector } from 'react-redux';
import { AcceptAgencyInviteCall, RejectAgencyInviteCall } from '../../Common/Repository';
import { PuffLoader } from 'react-spinners';
import { useLocation } from 'react-router-dom';

const AgencyInvite = () => {
    const dispatch = useDispatch();
    const { acceptAgencyLoading, rejectAgencyLoading } = useSelector(
        (state) => state.creators
    );

    const location = useLocation();
    const [inviteData, setInviteData] = useState({
        message: '',
        userId: '',
        agencyUserId: '',
        username: ''
    });

    const [isInviteAccepted, setIsInviteAccepted] = useState(false);
    
    const [isInviteRejected, setIsInviteRejected] = useState(false);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const userId = searchParams.get('userId') || '';
        const agencyUserId = searchParams.get('agencyUserId') || '';
        const message = searchParams.get('message') || '';
        const username = searchParams.get('username') || '';

        setInviteData({ userId, agencyUserId, message, username });
    }, [location]);

    const handleAcceptInvite = async () => {
        try {
            let data = {
                agencyUserId: inviteData.agencyUserId,
                userId: inviteData.userId
            };
            await dispatch(AcceptAgencyInviteCall({ data, newTabUrl: '/' }));
            setIsInviteAccepted(true); 
        } catch (error) {
            console.log('error: accept agency invite', error);
        }
    };

    const handleRejectInvite = async () => {
        try {
            let data = {
                agencyUserId: inviteData.agencyUserId,
                userId: inviteData.userId
            };
            await dispatch(RejectAgencyInviteCall({ data, newTabUrl: '/' }));
            setIsInviteRejected(true); 
        } catch (error) {
            console.log('error: reject agency invite', error);
        }
    };

    return (
        <AppLayout>
            <div className="rounded-4 w-75 mx-auto bg-white p-5 m-5">
                <h4 className='text-center'>
                    You have received an Invite From <span className='text-highlight text-underline pointer-cursor'>
                        {inviteData.username || 'Agency'}
                    </span>
                </h4>
                <div
                    style={{ letterSpacing: "1px" }}
                    className="px-md-5 px-3 text-center text-md-start py-3 lh-lg ">
                    {inviteData.message || ''}
                </div>
                <div className="d-flex justify-content-end mt-5">
                    <div className='d-flex w-50'>

                        {!isInviteAccepted && (
                            <button
                                onClick={handleRejectInvite}
                                className="whiteButton w-100 otherButton d-flex justify-content-center me-3"
                                disabled={isInviteRejected} 
                            >
                                {rejectAgencyLoading ? (
                                    <PuffLoader
                                        color='#65acae'
                                        size={22}
                                        className='mt-1'
                                    />
                                ) :
                                    <>
                                        {isInviteRejected ? (
                                            <p className="d-flex h-100 align-items-center">
                                                Rejected
                                            </p>
                                        ) : (
                                            <p className="d-flex h-100 align-items-center">
                                                Reject Invite
                                            </p>
                                        )}
                                    </>
                                }
                            </button>
                        )}

                        {!isInviteRejected && (
                            <button
                                onClick={handleAcceptInvite}
                                className="greenButton w-100 submitButton d-flex justify-content-center"
                                disabled={isInviteAccepted} 
                            >
                                {acceptAgencyLoading ? (
                                    <PuffLoader
                                        className="justify-content-center"
                                        color="white"
                                        size={22}
                                    />
                                ) : (
                                    <>
                                        {isInviteAccepted ? 'Accepted' : 'Accept Invite'}
                                    </>
                                )}
                            </button>
                        )}

                    </div>
                </div>
            </div>
        </AppLayout>
    );
};

export default AgencyInvite;

import React, { useState } from "react";
import AppLayout from "../../Components/AppLayout";
import { useNavigate } from "react-router-dom";
import { deleteUserProfile, updateUserSettings } from "../../Common/Repository";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { logout, setToken, setUser } from "../../states/auth/authSlice";

const CreatorsSetting = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { user, token } = useSelector((state) => state.auth);

  const [verifyMail, setVerifyMail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  // const [instaLoader, setInstaLoader] = useState(false);
  const [errors, setErrors] = useState([]);

  const handleUserDelete = async () => {
    try {
      if (user?.email === verifyMail) {
        let data = {};
        data.email = verifyMail;
        let response = await deleteUserProfile(
          {
            Authorization: `Bearer ${token}`,
          },
          data
        );
        if (response?.status === 200) {
          dispatch(setUser(null));
          dispatch(setToken(""));
          dispatch(logout());
          localStorage.setItem("user", null);
          localStorage.setItem("token", "");
          document.getElementById("delete-account-modal-close").click();
          navigate("/");
          toast.success("Your Account has been deleted!");
        }
      } else {
        setErrors("Email does not match!");
      }
    } catch (error) {
      setErrors(error?.response?.data?.errors);
      toast.error("Failed to delete your Account!");
    }
  };

  const handleChangePassword = async () => {
    try {
      setErrors([]);
      if (currentPassword === newPassword) {
        if (newPassword === confirmNewPassword) {
          const data = {};

          data.current_password = currentPassword;
          data.new_password = newPassword;
          data.confirm_new_password = confirmNewPassword;

          let response = await updateUserSettings(
            {
              Authorization: `Bearer ${token}`,
            },
            data
          );
          if (response?.status === 200) {
            document.getElementById("change-password-modal-close").click();
          }
        } else {
          setErrors("New Password and Confirm Password should be same!");
        }
      } else {
        setErrors("Old and New Password can not be same");
      }
    } catch (error) {
      setErrors(error?.response?.data?.error);
    }
  };

  return (
    <AppLayout>
      <div className="container settings-container mt-5">
        <img
          className="circle_top1"
          src="/assets/images/circle_top1.png"
          alt="circle_top1"
        />
        <img
          className="circle-03"
          src="/assets/images/circle-03.png"
          alt="circle_top1"
        />

        <div className="user-settings-container">
          <form className="form" action="{{ url('#') }}">
            <div className="user-profile-settings-box-outer settings-top-box w-100 d-flex">
              <div className="user-profile-settings-left-box w-50">
                <h1>Settings</h1>
              </div>
            </div>
            <div className="profile-settings-board">
              <div className="col-lg-12">
                <div className="user-profile-description-box d-flex w-100">
                  <div className="user-description-left-box">
                    <p className="profile-name-text-dark mb-0">
                      <b>Password</b>
                    </p>
                    <span className="profileTagDescription">********</span>
                  </div>
                  <div className="user-description-right-box w-50 text-end">
                    <button
                      type="button"
                      className="btn btn-primary pe-0 bg-transparent border-0"
                      data-bs-toggle="modal"
                      data-bs-target="#user-password-settings-modal"
                    >
                      <span>
                        <svg
                          width={20}
                          height={20}
                          x={0}
                          y={0}
                          viewBox="0 0 492.493 492"
                          style={{ enableBackground: "new 0 0 512 512" }}
                          xmlSpace="preserve"
                        >
                          <g>
                            <path
                              d="M304.14 82.473 33.165 353.469a10.799 10.799 0 0 0-2.816 4.949L.313 478.973a10.716 10.716 0 0 0 2.816 10.136 10.675 10.675 0 0 0 7.527 3.114 10.6 10.6 0 0 0 2.582-.32l120.555-30.04a10.655 10.655 0 0 0 4.95-2.812l271-270.977zM476.875 45.523 446.711 15.36c-20.16-20.16-55.297-20.14-75.434 0l-36.949 36.95 105.598 105.597 36.949-36.949c10.07-10.066 15.617-23.465 15.617-37.715s-5.547-27.648-15.617-37.719zm0 0"
                              fill="#549A9C"
                              data-original="#000000"
                            />
                          </g>
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="user-profile-description-box d-flex w-100">
                  <div className="user-description-left-box w-50">
                    <p className="profile-name-text-dark mb-0">
                      <b>Email</b>
                    </p>
                    <span className="profileTagDescription">{user?.email}</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="user-profile-description-box d-flex w-100">
                  <div className="user-description-left-box w-50">
                    <p className="profile-name-text-dark mb-0">
                      <b>Language</b>
                    </p>
                    <span className="profileTagDescription">English(EN)</span>
                  </div>
                  <div className="user-description-right-box w-50 text-end">
                    <button
                      type="button"
                      className="btn btn-primary pe-0 bg-transparent border-0"
                      data-bs-toggle="modal"
                      data-bs-target="#user-language-settings-modal"
                    >
                      <span>
                        <svg
                          width={20}
                          height={20}
                          x={0}
                          y={0}
                          viewBox="0 0 492.493 492"
                          style={{ enableBackground: "new 0 0 512 512" }}
                          xmlSpace="preserve"
                        >
                          <g>
                            <path
                              d="M304.14 82.473 33.165 353.469a10.799 10.799 0 0 0-2.816 4.949L.313 478.973a10.716 10.716 0 0 0 2.816 10.136 10.675 10.675 0 0 0 7.527 3.114 10.6 10.6 0 0 0 2.582-.32l120.555-30.04a10.655 10.655 0 0 0 4.95-2.812l271-270.977zM476.875 45.523 446.711 15.36c-20.16-20.16-55.297-20.14-75.434 0l-36.949 36.95 105.598 105.597 36.949-36.949c10.07-10.066 15.617-23.465 15.617-37.715s-5.547-27.648-15.617-37.719zm0 0"
                              fill="#549A9C"
                              data-original="#000000"
                            />
                          </g>
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="user-profile-description-box d-flex w-100">
                  <div className="user-description-left-box">
                    <p className="profile-name-text-dark mb-0">
                      <b>Delete Account</b>
                    </p>
                    <span className="profileTagDescription">
                      Erase all the data and contacts associated to your profile
                    </span>
                  </div>
                  <div className="user-description-right-box verification-settings text-end w-50 border-0">
                    <button
                      type="button"
                      className="btn btn-primary pe-0 bg-transparent text-dark border-0"
                      data-bs-toggle="modal"
                      data-bs-target="#user-delete-settings-modal"
                    >
                      <img src="/assets/images/trash-icon.svg" alt="Delete" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* Password-Modal  */}
      <div
        className="modal fade"
        id="user-password-settings-modal"
        tabIndex={-1}
        aria-labelledby="user-profile-settings-modal"
        aria-hidden="true"
      >
        <div className="modal-dialog user-settings-modal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="user-profile-Label">
                Change Password
              </h5>
              <div
                className="close-btn-box new-lg-close-position "
                data-bs-dismiss="modal"
                aria-label="Close"
                id="change-password-modal-close"
              >
                <img src="/assets/images/close-Icon.png" alt="X" />
              </div>
            </div>
            <div className="modal-body">
              <form>
                <div className="col-md-12 d-block mt-2">
                  <div className="label-box">
                    <label>Current Password*</label>
                  </div>
                  <div className="input-box">
                    <input
                      type="text"
                      className="w-100"
                      value={currentPassword}
                      placeholder="Enter your current password"
                      onChange={(event) => {
                        setCurrentPassword(event.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-12 d-block mt-3">
                  <div className="label-box">
                    <label>New Password*</label>
                  </div>
                  <div className="input-box">
                    <input
                      type="text"
                      className="w-100"
                      value={newPassword}
                      placeholder="Create a new password for you account"
                      onChange={(event) => {
                        setNewPassword(event.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-12 d-block mt-3">
                  <div className="label-box">
                    <label>Confirm Password*</label>
                  </div>
                  <div className="input-box">
                    <input
                      type="text"
                      className="w-100"
                      value={confirmNewPassword}
                      placeholder="Confirm the password"
                      onChange={(event) => {
                        setConfirmNewPassword(event.target.value);
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          handleChangePassword();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-12 d-block mt-3">
                  {/* <a
                    href="/#"
                    className="forgot-password text-decoration-underline"
                  >
                    Forgot Password
                  </a> */}
                  {errors.length > 0 ? (
                    <small className="ms-4 text-danger ">{errors}</small>
                  ) : (
                    ""
                  )}
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn common-button-style-filled"
                onClick={handleChangePassword}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Language Modal  */}
      <div
        className="modal fade"
        id="user-language-settings-modal"
        tabIndex={-1}
        aria-labelledby="user-profile-settings-modal"
        aria-hidden="true"
      >
        <div className="modal-dialog user-settings-modal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="user-profile-Label">
                Language
              </h5>
              <div
                className="close-btn-box new-lg-close-position "
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <img src="/assets/images/close-Icon.png" alt="X" />
              </div>
            </div>
            <div className="modal-body">
              <form>
                <div className="col-md-12 d-block">
                  <div className="input-box">
                    <select
                      className="form-select"
                      aria-label="Default select example"
                    >
                      <option value={2}>Language</option>
                      <option value={1}>English(EN)</option>
                    </select>
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                data-bs-dismiss="modal"
                aria-label="Close"
                type="button"
                className="btn common-button-style-filled"
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Delete-Modal   */}
      <div
        className="modal fade"
        id="user-delete-settings-modal"
        tabIndex={-1}
        aria-labelledby="user-profile-settings-modal"
        aria-hidden="true"
      >
        <div className="modal-dialog user-settings-modal  position-relative">
          <div className="modal-content">
            <div className="modal-body delete-account-modal">
              <img
                className="blue-ball-brand-popup"
                src="/assets/images/blue_ball.png"
                alt="blue-ball"
                width={25}
              />
              <img
                className="popup-yellow-brand-circle position-absolute"
                src="assets/images/yellow_circle.png"
                alt="circle_3"
                width={16}
              />
              <img
                className="purple-empty-circle-brand-popup"
                src="assets/images/purple_empty_circle.png"
                alt="empty_circle"
                width={20}
              />
              <img
                className="purple-plus-brand-popup"
                src="assets/images/purple_plus.png"
                alt="plus"
                width={16}
              />
              <img
                className="purple-empty-circle-2-brand-popup position-absolute"
                src="assets/images/purple_empty_circle.png"
                alt="empty_circle_2"
                width={16}
              />
              <div
                className=" close-btn-box new-lg-close-position"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="delete-account-modal-close"
                onClick={() => {
                  setErrors([]);
                  setVerifyMail("");
                }}
              >
                <img src="/assets/images/close-Icon2.svg" alt="X" />
              </div>
              <div className="text-center m-4">
                <div>Are you sure you want to delete your account?</div>
                <div className="warning">
                  You will not be able to revert this change!!
                </div>
                <div>
                  To confirm please enter your email{" "}
                  <span className="email-highlight">{user?.email}</span> in the
                  text box below...
                </div>
              </div>
              <div className="col-md-12 d-block">
                <div className="ant-select-selector mx-4">
                  <span className="ant-select-selection-search">
                    <input
                      type="search"
                      autoComplete="off"
                      className="ant-select-selection-search-input w-100 py-3"
                      placeholder="E-mail..."
                      role="combobox"
                      aria-haspopup="listbox"
                      aria-owns="rc_select_8_list"
                      aria-autocomplete="list"
                      aria-controls="rc_select_8_list"
                      aria-activedescendant="rc_select_8_list_-1"
                      id="rc_select_8"
                      aria-expanded="false"
                      value={verifyMail}
                      onChange={(event) => {
                        setErrors([]);
                        setVerifyMail(event.target.value);
                      }}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          handleUserDelete();
                        }
                      }}
                    />
                    {errors ? (
                      <small className="ms-3 text-danger ">{errors}</small>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </div>
            </div>

            <div className="modal-footer">
              <div className="action-btns d-flex w-100 justify-content-between mx-5">
                <div
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="common-button-style-outline"
                  onClick={() => {
                    setErrors("");
                    setVerifyMail("");
                  }}
                >
                  Cancel
                </div>
                <div
                  className="common-button-style-filled"
                  onClick={handleUserDelete}
                >
                  Delete
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default CreatorsSetting;

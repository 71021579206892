import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  const navigateToLinkedIn = () => {
    window.open("https://www.linkedin.com/company/vitrinelive/", "_blank");
  };

  const navigateToX = () => {
    window.open("https://x.com/vitrine_live?s=21&t=RIJgSPeiVhMOIy5-Xqd9ww/", "_blank");
  };

  const navigateToInstagram = () => {
    window.open("https://instagram.com/vitrinelive?igshid=MzMyNGUyNmU2YQ==/", "_blank");
  };

  return (
    <>
      <footer className="position-relative overflow-hidden">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center pt-5">
              <img
                src="/assets/images/LP-logo.svg"
                alt="Vitrine"
                width={250}
              />
              <p className="col-md-9 text-center m-auto p-4">
                Collaboration made easy!
              </p>
              <ul className="navbar-nav footer-nav d-flex flex-row justify-content-center border-bottom pb-md-5">
                <li className="nav-item">
                  <Link className="nav-link" to="/about">
                    About Us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/mainHelp">
                    Help Center
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/termsAndConditions">
                    Terms and Conditions
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/privacy-policy">
                    Privacy Policy
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contactUs">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-12 copyright-div pt-4 pb-4">
              <div className="row align-items-center">
                <div className="col-12 col-md-6">
                  <p className="m-0">
                    Copyright © 2023. All Rights Reserved By Vitrine
                  </p>
                </div>
                <div className="col-12 col-md-6">
                  <ul className="d-flex flex-row justify-content-md-end justify-content-center p-0 m-0">
                    <li
                      onClick={navigateToInstagram}
                      className="d-flex justify-content-center align-items-center ms-3">
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clipPath="url(#clip0_1871_5635)">
                          <path
                            d="M0 10.4461C0 8.14965 0 5.85324 0 3.55649C0.00821476 3.51232 0.015745 3.46781 0.0249866 3.42364C0.0770133 3.17472 0.105765 2.91793 0.185517 2.67826C0.724952 1.05224 2.14953 0.0110359 3.85477 0.00453053C5.94988 -0.00368684 8.04498 0.00144902 10.1401 0.00316097C10.8034 0.00350336 11.4363 0.149019 12.0093 0.484219C13.3106 1.24638 13.9832 2.39133 13.9925 3.90025C14.0055 5.96829 13.9983 8.03632 13.9928 10.1044C13.9921 10.3625 13.9774 10.6268 13.9216 10.8778C13.5725 12.446 12.6182 13.4512 11.0708 13.8847C10.8664 13.9419 10.6521 13.9624 10.4423 14.0001C8.14596 14.0001 5.84993 14.0001 3.55357 14.0001C3.41084 13.9771 3.26605 13.9621 3.12537 13.9302C1.55601 13.5755 0.549362 12.6237 0.115349 11.074C0.0581879 10.8699 0.037651 10.6556 0 10.4461ZM6.99589 10.8378C9.10537 10.8419 10.8168 9.13505 10.8339 7.01018C10.851 4.90585 9.11941 3.1689 7 3.16479C4.89052 3.16068 3.17911 4.8675 3.162 6.99238C3.14488 9.09671 4.87648 10.8337 6.99589 10.8378ZM11.0673 1.76955C10.4314 1.76887 9.90049 2.29718 9.89981 2.93163C9.89913 3.56334 10.4314 4.09678 11.0649 4.09952C11.6971 4.10226 12.2273 3.57155 12.2294 2.93402C12.2311 2.29546 11.7071 1.77058 11.0673 1.76955Z"
                            fill="#549a9c"
                          />
                          <path
                            d="M7.00143 3.99122C8.66526 3.9943 10.0159 5.35153 10.007 7.01144C9.9981 8.67238 8.65088 10.0135 6.99424 10.0108C5.33075 10.0077 3.97976 8.65012 3.98832 6.99056C3.99722 5.32962 5.34478 3.98813 7.00143 3.99122Z"
                            fill="#549a9c"
                          />
                          <path
                            d="M11.4022 2.93087C11.4029 3.11508 11.2471 3.27224 11.065 3.27087C10.8846 3.26984 10.7255 3.10755 10.7286 2.92745C10.7313 2.75043 10.8836 2.59875 11.0613 2.5967C11.2478 2.59465 11.4015 2.74496 11.4022 2.93087Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1871_5635">
                            <rect width="14" height="14" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </li>
                    <li
                      onClick={navigateToLinkedIn}
                      className="d-flex justify-content-center align-items-center ms-3"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.6276 3.33302C4.62743 3.68665 4.48678 4.02571 4.23661 4.27564C3.98643 4.52556 3.64723 4.66587 3.2936 4.66569C2.93998 4.66551 2.60091 4.52487 2.35099 4.27469C2.10107 4.02452 1.96076 3.68531 1.96094 3.33169C1.96111 2.97807 2.10176 2.639 2.35193 2.38908C2.60211 2.13915 2.94132 1.99885 3.29494 1.99902C3.64856 1.9992 3.98763 2.13985 4.23755 2.39002C4.48747 2.64019 4.62778 2.9794 4.6276 3.33302ZM4.6676 5.65302H2.00094V13.9997H4.6676V5.65302ZM8.88094 5.65302H6.2276V13.9997H8.85427V9.61969C8.85427 7.17969 12.0343 6.95302 12.0343 9.61969V13.9997H14.6676V8.71302C14.6676 4.59969 9.96094 4.75302 8.85427 6.77302L8.88094 5.65302Z"
                          fill="#549a9c"
                        />
                      </svg>
                    </li>
                    <li
                      onClick={navigateToX}
                      className="d-flex justify-content-center align-items-center ms-3">
                      <svg
                        width="53"
                        height="53"
                        viewBox="0 0 53 53"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M28.3563 24.7981L35.4306 16.752H33.7548L27.6096 23.7368L22.7051 16.752H17.0469L24.4651 27.3153L17.0469 35.7519H18.7227L25.208 28.374L30.3887 35.7519H36.0469M19.3275 17.9885H21.902L33.7536 34.5761H31.1784"
                          fill="#549A9C"
                        />
                      </svg>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-ft-shape position-absolute" />
        <img
          src="/assets/images/left-footimg-opt.png"
          className="left-footimg img-fluid position-absolute"
          alt="..."
        />
        <img
          src="/assets/images/right-footimg-opt.png"
          className="right-footimg img-fluid position-absolute "
          alt="..."
        />
      </footer>
    </>
  );
};

export default Footer;


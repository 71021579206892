import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  addCampaign,
  getCategoriesApi,
  updateCampaign,
} from "../../Common/Repository";
import { useFilePicker } from "use-file-picker";
import { toast } from "react-toastify";
import {
  ArrowFilledBig,
  ArrowOutlineSmall,
  InfoIcon,
} from "../../Components/Icons";
import { ChromePicker } from "react-color";
import IconButton from "@mui/material/IconButton";
import LightTooltip from "../../Components/LightToolTip";

import { PuffLoader } from "react-spinners";

import GooglePlacesAutocomplete from "react-google-places-autocomplete";

const AddCampaigns = () => {
  const navigate = useNavigate();

  const [nextLoading, setNextLoading] = useState(false);
  const [campaignId, setCampaignId] = useState(0);
  const token = localStorage.getItem("token");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [minFollower, setMinFollower] = useState(0);
  const [engageRate, setEngageRate] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [worldWideLocation, setWorldWideLocation] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [descriptionText, setDescriptionText] = useState("");
  const [otherCategory, setOtherCategory] = useState("");
  const [otherTag, setOtherTag] = useState("");
  const [otherTagSelected, setOtherTagSelected] = useState("");
  const [urlShare, setUrl] = useState("");
  const [hashTags, setHashTags] = useState("");
  const [mentions, setMentions] = useState("");
  const [timeLimit, setTimeLimit] = useState("");
  const [dealsDesired, setDealsDesired] = useState("");
  const [campaignIncentive, setCampaignIncentive] = useState("");
  const [colorPicker, setColorPicker] = useState(false);
  const [color, setPickedColor] = useState("");
  const [selectedGender, setSelectedGender] = useState("all");

  const [selectedTags, setSelectedTags] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);

  const [titleError, setTitleError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [incentiveError, setIncentiveError] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  const [minFollowerError, setMinFollowerError] = useState(false);
  const [minEngagementError, setMinEngagementError] = useState(false);
  const [tagError, setTagError] = useState(false);
  const [urlError, setUrlError] = useState(false);
  const [hashTagError, setHashTagError] = useState(false);
  const [mentionError, setMentionError] = useState(false);

  const [stepTwoChange, setStepTwoChange] = useState(false);
  const [stepThreeChange, setStepThreeChange] = useState(false);
  const [stepFourChange, setStepFourChange] = useState(false);
  const [stepFiveChange, setStepFiveChange] = useState(false);
  const [stepSixChange, setStepSixChange] = useState(false);

  let [errorData, setErrorData] = useState(new Map());

  const [availableTags, setAvailableTags] = useState(
    [
      "Lifestyle",
      "Travel",
      "Pets",
      "Causes",
      "Decor",
      "Family",
      "Weddings",
      "Dogs",
      "Cats",
      "Environmentalism",
      "Garden",
      "Home",
    ]
  );

  const specificGender = ["all", "male", "female", "others"];

  const [customCategory, setCustomCategory] = useState("");

  const [customCategoryDescription, setCustomCategoryDescription] =
    useState("");

  const handleAddCategory = () => {
    if (true) {
      document.getElementById("close-modal").click();
      toast.success("Campaign added");
    } else {
      toast.error("Campaign can not be empty!");
    }
  };

  const handleTagClick = (tag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(
        selectedTags.filter((selectedTag) => selectedTag !== tag)
      );
    } else {
      if (selectedTags.length < 5) {
        setSelectedTags([...selectedTags, tag]);
      } else {
        toast.error("You can only add upto 5 Tags")
      }
    }
  };

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const saveCurrentState = async (formData) => {
    setNextLoading(true);

    try {
      let response = await addCampaign(
        {
          Authorization: `Bearer ${token}`,
        },
        formData
      );
      if (response?.status === 200) {
        setCampaignId(response?.data?.id);
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  const updateCurrentCampaign = async (formData) => {
    setNextLoading(true);

    try {
      let response = await updateCampaign(
        {
          Authorization: `Bearer ${token}`,
        },
        formData
      );
      if (response?.status === 200) {
        return true;
      }
      return false;
    } catch (error) {
      toast.error("Failed to save this part.");
      return false;
    }
  };

  const [openFileSelector, { filesContent, loading, fileErrors }] =
    useFilePicker({
      readAs: "DataURL",
      accept: "image/jpeg, image/png",
      multiple: false,
      limitFilesConfig: { max: 1 },
      minFileSize: 0.018, // in megabytes
      maxFileSize: 50,
      onFilesRejected: ({ errors }) => {
        // this callback is called when there were validation errors
        if (errors.length > 0) {
          toast.error(
            errors[0].fileSizeTooSmall
              ? "Attached File is smaller than allowed size limit of 200kb"
              : errors[0].fileSizeToolarge
                ? "Attached File exceed the maximum size limit of 50MB"
                : "Could not select the image, please try again!"
          );
        }
      },
    });

  if (loading) {
  }

  if (fileErrors) {
  }

  const validateStepTwo = () => {
    let returnVal = true;
    let errorMap = new Map();
    if (titleText.length < 1) {
      errorMap.set("title", "Campaign title can not be empty!");
      setTitleError(true);
      returnVal = false;
    }
    if (titleText.length > 0 && titleText.length < 5) {
      errorMap.set(
        "title",
        "Please add a valid campaign name! (Needs more than 4 characters"
      );
      setTitleError(true);
      returnVal = false;
    }

    if (descriptionText.length < 1) {
      errorMap.set("description", "Campaign description can not be empty!");
      setDescriptionError(true);
      returnVal = false;
    }
    if (descriptionText.length > 0 && descriptionText.length < 20) {
      errorMap.set(
        "description",
        "Please add a some more details about the campaign! (Needs minimum 20 characters"
      );
      setDescriptionError(true);
      returnVal = false;
    }

    if (!errorMap.has("title")) {
      setTitleError(false);
    }

    if (!errorMap.has("description")) {
      setDescriptionError(false);
    }

    if (errorMap.size > 0) {
      setErrorData(errorMap);
    }

    return returnVal;
  };

  const validateStepThree = () => {
    let returnVal = true;
    let errorMap = new Map();
    if (campaignIncentive.length < 1) {
      errorMap.set("incentive", "Please select an incentive method!");
      setIncentiveError(true);
      returnVal = false;
    }

    if (!errorMap.has("incentive")) {
      setIncentiveError(false);
    }

    if (errorMap.size > 0) {
      setErrorData(errorMap);
    }

    return returnVal;
  };

  const validateStepFour = () => {
    let returnVal = true;
    let errorMap = new Map();
    if (selectedLocation.length < 1) {
      errorMap.set("location", "Please enter a location!");
      setLocationError(true);
      returnVal = false;
    }

    if (selectedLocation === "worldwide" && !worldWideLocation) {
      errorMap.set(
        "location",
        "Please agree to receive proposals from anywhere in the world!"
      );
      setLocationError(true);
      returnVal = false;
    }

    if (!errorMap.has("location")) {
      setLocationError(false);
    }

    if (errorMap.size > 0) {
      setErrorData(errorMap);
    }

    return returnVal;
  };

  const validateStepFive = () => {
    let returnVal = true;
    let errorMap = new Map();
    if (filesContent.length < 1 && color.length < 1) {
      errorMap.set("image", "Please select an image or a color");
      setImageError(true);
      returnVal = false;
    }

    if (!errorMap.has("image")) {
      setImageError(false);
    }

    if (errorMap.size > 0) {
      setErrorData(errorMap);
    }

    return returnVal;
  };

  const validateStepSix = () => {
    let returnVal = true;
    let errorMap = new Map();
    if (selectedCategory.length < 1) {
      errorMap.set("category", "Please select a category!");
      setCategoryError(true);
      returnVal = false;
    }

    if (minFollower < 1) {
      errorMap.set("follower", "Please enter minimum followers!");
      setMinFollowerError(true);
      returnVal = false;
    }

    console.log("Engage Rate: ", engageRate);

    if (engageRate < 0) {
      errorMap.set("engage", "Please enter minimum engagement!");
      setMinEngagementError(true);
      returnVal = false;
    }

    if (selectedTags.length < 1) {
      errorMap.set("tags", "Please select a tag!");
      setTagError(true);
      returnVal = false;
    }

    if (!errorMap.has("category")) {
      setCategoryError(false);
    }

    if (!errorMap.has("follower")) {
      setMinFollowerError(false);
    }

    if (!errorMap.has("engage")) {
      setMinEngagementError(false);
    }

    if (!errorMap.has("tags")) {
      setTagError(false);
    }

    if (errorMap.size > 0) {
      setErrorData(errorMap);
    }

    return returnVal;
  };

  const validateStepSeven = () => {
    let returnVal = true;
    let errorMap = new Map();
    if (urlShare.length < 1) {
      errorMap.set("url", "Please enter a url!");
      setUrlError(true);
      returnVal = false;
    }

    if (hashTags.length < 1) {
      errorMap.set("htags", "Please add some hashtags!");
      setHashTagError(true);
      returnVal = false;
    }

    if (mentions.length < 1) {
      errorMap.set("mention", "Please add some mentions!");
      setMentionError(true);
      returnVal = false;
    }

    if (!errorMap.has("url")) {
      setUrlError(false);
    }

    if (!errorMap.has("htags")) {
      setHashTagError(false);
    }

    if (!errorMap.has("mention")) {
      setMentionError(false);
    }

    if (errorMap.size > 0) {
      setErrorData(errorMap);
    }

    return returnVal;
  };

  const removeError = (toRemove) => {
    if (toRemove === "title") {
      setTitleError(false);
    }

    if (toRemove === "description") {
      setDescriptionError(false);
    }

    if (toRemove === "incentive") {
      setIncentiveError(false);
    }

    if (toRemove === "location") {
      setLocationError(false);
    }

    if (toRemove === "image") {
      setImageError(false);
    }

    if (toRemove === "category") {
      setCategoryError(false);
    }

    if (toRemove === "follower") {
      setMinFollowerError(false);
    }

    if (toRemove === "engage") {
      setMinEngagementError(false);
    }

    if (toRemove === "tags") {
      setTagError(false);
    }

    if (toRemove === "url") {
      setUrlError(false);
    }

    if (toRemove === "htags") {
      setHashTagError(false);
    }

    if (toRemove === "mention") {
      setMentionError(false);
    }
  };

  const moveToNext = async (curr, nextId) => {
    // setNextLoading(true);
    const formData = {};
    let val = false;

    if (curr === "campaign-step-2" && validateStepTwo()) {
      if (stepTwoChange) {
        if (campaignId !== 0) {
          formData.campaign_id = campaignId;
        }
        formData.title = titleText;
        formData.description = descriptionText;

        if (campaignId === 0) {
          val = await saveCurrentState(formData);
        } else {
          val = await updateCurrentCampaign(formData);
        }
      } else {
        val = true;
      }

      if (val && stepTwoChange) {
        setStepTwoChange(false);
        toast.success("Campaign Data Saved Successfully!");
      }

      // val = await updateCurrentCampaign(formData);
    }

    if (curr === "campaign-step-3" && validateStepThree()) {
      if (stepThreeChange) {
        formData.campaign_id = campaignId;
        formData.campaign_incentive = campaignIncentive;
        val = await updateCurrentCampaign(formData);
      } else {
        val = true;
      }

      if (val && stepThreeChange) {
        setStepThreeChange(false);
        toast.success("Campaign Data Saved Successfully!");
      }
    }

    if (curr === "campaign-step-4" && validateStepFour()) {
      if (stepFourChange) {
        formData.campaign_id = campaignId;
        formData.location = selectedLocation;
        val = await updateCurrentCampaign(formData);
      } else {
        val = true;
      }

      if (val && stepFourChange) {
        setStepFourChange(false);
        toast.success("Campaign Data Saved Successfully!");
      }
    }

    if (curr === "campaign-step-5" && validateStepFive()) {
      if (stepFiveChange) {
        let newFormData = new FormData();
        // for (var i = 0; i < filesContent.length; i++) {
        // }
        if (color !== "") {
          newFormData.append("color", color);
        } else {
          let asd = dataURItoBlob(filesContent[0]?.content);

          newFormData.append(`image`, asd);
        }

        newFormData.append("campaign_id", campaignId);
        val = await updateCurrentCampaign(newFormData);
      } else {
        val = true;
      }

      if (val && stepFiveChange) {
        setStepFiveChange(false);
        toast.success("Campaign Data Saved Successfully!");
      }
    }

    if (curr === "campaign-step-6" && validateStepSix()) {
      if (stepSixChange) {
        formData.campaign_id = campaignId;
        formData.category_id = selectedCategory;

        if (selectedCategory === "15" || selectedCategory === 15) {
          formData.append("other_category", otherCategory);
        }

        formData.minimum_follower = minFollower;
        formData.engagement_rate = engageRate;

        if (Array.isArray(selectedTags)) {
          formData.ideal_category = selectedTags.join(", ");
        }
        // else if (selectedTags === "Other") {
        //   formData.ideal_category = otherTag;
        // }
        else {
          formData.ideal_category = selectedTags;
        }

        val = await updateCurrentCampaign(formData);
      } else {
        val = true;
      }

      if (val && stepSixChange) {
        setStepSixChange(false);
        toast.success("Campaign Data Saved Successfully!");
      }

      // val = await saveCurrentState(formData);
    }

    if (val) {
      setNextLoading(false);
    }

    if ((!nextLoading && val) || (!nextLoading && curr === "campaign-step-1")) {
      var currentSec = window.$("#" + curr).outerHeight(true);
      var currScroll = window.$(".campaign-col").scrollTop();
      var scrollVal = parseInt(currScroll) + parseInt(currentSec);
      if (currScroll === 0) {
        scrollVal = parseInt(scrollVal) - 80;
      }
      let imageStep = nextId.split("-");
      imageStep = imageStep[2];
      window.$(".campaign-col").animate(
        {
          scrollTop: scrollVal,
        },
        1500
      );
      window
        .$(".campaign-col .campaign-section")
        .removeClass("active-campaign");
      window
        .$(".campaign-col .campaign-section")
        .removeClass("animate__fadeInUp animate__fadeInDown");
      window
        .$(".campaign-images-sec .inner-curve img")
        .removeClass("active-image animate__fadeInRight animate__fadeInLeft");

      window.$("#" + nextId).addClass("active-campaign");
      window.$("#" + nextId).addClass("animate__fadeInUp");
      window.$("#campaign-img" + imageStep).addClass("active-image");
      window.$("#campaign-img" + imageStep).addClass("animate__fadeInRight");
      document.getElementById(curr).style.display = "none";
      document.getElementById(nextId).style.display = "block";
      document.getElementById("bottom-numbers").innerHTML = `${nextId
        .toString()
        .slice(nextId.length - 1)
        .padStart(2, "0")}`;
      setNextLoading(false);
    }
  };

  const moveToBack = (curr, prevId) => {
    var currentSec = window.$("#" + curr).outerHeight(true);
    var currScroll = window.$(".campaign-col").scrollTop();
    var scrollVal = parseInt(currScroll) - parseInt(currentSec);
    if (currScroll === 0) {
      scrollVal = parseInt(scrollVal) + 80;
    }
    let imageStep = prevId.split("-");
    imageStep = imageStep[2];
    window.$(".campaign-col").animate(
      {
        scrollTop: scrollVal,
      },
      "fast"
    );
    window.$(".campaign-col .campaign-section").removeClass("active-campaign");
    window
      .$(".campaign-col .campaign-section")
      .removeClass("animate__fadeInUp animate__fadeInDown");
    window
      .$(".campaign-images-sec .inner-curve img")
      .removeClass("active-image animate__fadeInRight animate__fadeInLeft");
    window.$("#" + prevId).addClass("active-campaign");
    window.$("#" + prevId).addClass("animate__fadeInDown");
    document.getElementById(curr).style.display = "none";
    document.getElementById(prevId).style.display = "block";
    document.getElementById("bottom-numbers").innerHTML = `${prevId
      .toString()
      .slice(prevId.length - 1)
      .padStart(2, "0")}`;
    window.$("#campaign-img" + imageStep).addClass("active-image");
    window.$("#campaign-img" + imageStep).addClass("animate__fadeInLeft");
  };

  const submitCampaign = async () => {
    try {
      if (validateStepSeven()) {
        setNextLoading(true);
        const formData = {};
        let val = false;

        formData.campaign_id = campaignId;
        formData.collabrations_url = urlShare;
        formData.hashtag = hashTags;
        formData.mention = mentions;
        if (timeLimit) {
          formData.time_limit = timeLimit;
        }
        if (dealsDesired) {
          formData.deals_per_month = dealsDesired;
        }
        formData.gender = selectedGender;
        formData.is_complete = 1;

        val = await updateCurrentCampaign(formData);
        if (val) {
          setTimeout(() => {
            setNextLoading(false);
            toast.success("Campaign Added Successfully!");
            navigate("/campaignDetailsSection", { state: campaignId });
            document.getElementById("campaign-success-modal-btn").click();
          }, 500);
        }
      }
    } catch (error) {
      toast.error("Failed to add a campaign.");
    }
  };

  const checkboxSelected = () => {
    var checkBox = document.getElementById("campaign-checkbox");
    // var text = document.getElementById("currency-dropdown-box");
    if (checkBox.checked === true) {
      // text.style.display = "block";
      setCampaignIncentive("fixed");
    } else {
      setCampaignIncentive("");
    }
  };

  // const chooseImage = () => {
  //   window.$("#formFile").trigger("click");
  // };

  // const onTagChanged = (selectVal) => {
  //   setSelectedTag(selectVal);
  // };

  async function getCategories() {
    try {
      let response = await getCategoriesApi({
        Authorization: `Bearer ${token}`,
      });
      if (response?.status === 200) {
        setCategoriesList(response?.data?.data);
      }
    } catch (error) {
      toast.error("Error Fetching Categories.");
    }
  }

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // const descriptionElement = document.getElementById("campaignDescription");
    // const desCounterElement = document.getElementById("descriptionCounter");

    // descriptionElement.addEventListener("input", function (e) {
    //   const target = e.target;

    //   // Count the current number of characters
    //   const currentLength = target.value.length;

    //   desCounterElement.innerHTML = `${currentLength}`;
    // });

    const tilteElement = document.getElementById("campaignTitle");
    const titleCounterElement = document.getElementById("titleCounter");

    tilteElement.addEventListener("input", function (e) {
      const target = e.target;

      // Count the current number of characters
      const currentLength = target.value.length;

      titleCounterElement.innerHTML = `${currentLength}`;
    });
  });

  function changeColorPicker() {
    setStepFiveChange(true);
    setColorPicker(!colorPicker);
  }

  function selectColor(color) {
    setStepFiveChange(true);
    setPickedColor(color.hex);
  }

  const handleGenderChange = (event) => {
    setSelectedGender(event.target.value);
  };

  return (
    <section className="campaign-faq-section-main">
      <div
        data-bs-toggle="modal"
        data-bs-target="#add-custom-category-modal"
        id="add-custom-category-modal-btn"
        className="border d-flex justify-content-center fw-bold"
      ></div>
      <div
        data-bs-toggle="modal"
        data-bs-target="#campaign-success-modal"
        id="campaign-success-modal-btn"
      ></div>
      <div className="container-fluid pe-0">
        <div className="col-md-12 p-0 d-flex align-items-center campaign-row">
          <div className="col-md-7 campaign-col campaignLeftCol">
            {/* <img
              className="purple-coin"
              src="/assets/images/circle_top1.png"
              alt="circle_top1"
            />
            <img
              className="ethereum-coin"
              src="/assets/images/ethereum-01.png"
              alt="ethereum-01"
            /> */}
            <img
              src="/assets/images/right-footimg-opt.png"
              className="right-campaign-img img-fluid position-absolute "
              alt="..."
            />
            <ArrowOutlineSmall className="background-arrow-small-one" />
            <ArrowFilledBig className="background-arrow-campagin" />
            <ArrowOutlineSmall className="background-arrow-small-two" />
            {/* <img className="dollor-coin" src="/assets/images/dollar-icon.png" alt="circle_top1"> */}
            <img
              className="purple-plus-right"
              src="assets/images/purple_plus.png"
              alt="plus"
              width={16}
            />
            <img
              className="purple-empty-circle-right"
              src="assets/images/purple_empty_circle.png"
              alt="empty_circle_2"
              width={24}
            />
            <img
              className="purple-plus-center"
              src="assets/images/purple_plus.png"
              alt="plus"
              width={16}
            />
            <img
              className="purple-empty-circle-center"
              src="assets/images/purple_empty_circle.png"
              alt="empty_circle_2"
              width={16}
            />
            {/* Bottom Numbering */}
            <div className="bottom-numbering">
              <h4>
                <span id="bottom-numbers">01</span>
                <span className="lightGreenColor"> /07</span>
              </h4>
            </div>
            {/* Bottom Numbering - End */}
            <div
              className="campaignWelcome campaign-section position-relative spacing active-campaign"
              id="campaign-step-1"
            >
              <h1>Welcome to Vitrine</h1>
              <p>
                You will be given multiple options to fill in and once done you
                can submit request to publish your campaign.
              </p>
              <button
                className="btn common-button-style-filled lets-go-btn"
                onClick={() => moveToNext("campaign-step-1", "campaign-step-2")}
              >
                Let's Go
              </button>
            </div>
            {/* campaign-kind-sec */}
            <div
              className="campaign-kind-sec-main campaign-section position-relative"
              style={{ display: "none" }}
              id="campaign-step-2"
            >
              <h3>Give a Title and Description to your New Campaign</h3>
              <div className="col-md-12 mb-5">
                <div className="col-md-12">
                  <div>
                    <label className="campaign-sec-label">Add a title</label>
                  </div>
                  <div>
                    <input
                      id="campaignTitle"
                      type="text"
                      value={titleText}
                      placeholder="e.g. We're looking for beauty ambassadors to..."
                      name="title"
                      className={
                        titleError
                          ? "campaignInput redOutline"
                          : "campaignInput"
                      }
                      maxLength="120"
                      onChange={(event) => {
                        setStepTwoChange((prev) => true);
                        setTitleText(event.target.value);
                        if (titleError) {
                          removeError("title");
                        }
                      }}
                    />
                    <div
                      className={
                        titleError ? "counterText mb-0" : "counterText"
                      }
                    >
                      <span id="titleCounter">0</span>/120 Characters
                    </div>
                    {titleError ? (
                      <div className="mb-2">
                        <span className="errorText">
                          {errorData.get("title")}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div>
                  <label className="campaign-sec-label">
                    Add a description
                  </label>
                </div>
                <div>
                  <textarea
                    // id="campaignDescription"
                    className={
                      descriptionError
                        ? "campaign-textarea redOutline"
                        : "campaign-textarea"
                    }
                    name="description"
                    placeholder="e.g. We are looking for ambassadors..."
                    value={descriptionText}
                    // maxLength="500"
                    onChange={(event) => {
                      setStepTwoChange((prev) => true);
                      setDescriptionText(event.target.value);
                      if (descriptionError) {
                        removeError("description");
                      }
                    }}
                    onKeyUp={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        moveToNext("campaign-step-2", "campaign-step-3");
                      }
                    }}
                  />
                  {descriptionError ? (
                    <div className="mb-2">
                      <span className="errorText">
                        {errorData.get("description")}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* <div className="counterText">
                    <span id="descriptionCounter">0</span>/500 Characters
                  </div> */}
                </div>
              </div>
              <div className="col-md-12">
                <div className="button-box">
                  <button
                    className="btn back-btn me-2"
                    onClick={() =>
                      moveToBack("campaign-step-2", "campaign-step-1")
                    }
                  >
                    Back
                  </button>
                  <button
                    className="btn common-button-style-filled next-btn me-sm-4"
                    onClick={() =>
                      moveToNext("campaign-step-2", "campaign-step-3")
                    }
                  >
                    {nextLoading ? (
                      <PuffLoader
                        className="d-flex justify-content-center"
                        color="white"
                        size={22}
                      />
                    ) : (
                      "Next"
                    )}
                  </button>
                </div>
              </div>
            </div>
            {/* campaign-incentives-section */}
            <div
              className="campaign-incentives campaign-section position-relative"
              style={{ display: "none" }}
              id="campaign-step-3"
            >
              <h3>Choose one or more campaign incentives</h3>
              <div className="row">
                <div className="col-md-12 mb-5">
                  <div className="campaign-incentive-outer d-flex align-items-center mt-3">
                    <div className="campaign-incentive-inner1">
                      <input
                        type="checkbox"
                        defaultValue="negotiated_perContent"
                        id="campaign-checkbox"
                        onClick={() => {
                          setStepThreeChange((prev) => true);
                          checkboxSelected();
                          if (incentiveError) {
                            removeError("incentive");
                          }
                        }}
                        className="incentive-checkbox"
                      />
                    </div>
                    <div className="campaign-incentive-inner2 d-flex">
                      <div className="w-75">
                        <span>
                          Fee - Fixed per content
                          <LightTooltip
                            placement="right"
                            title="This is a pre-arranged payment amount that you agree upon with a collaborator for each piece of content/the work they create. This ensures transparency and consistency, as the collaborator knows exactly what they will be compensated for their work regardless of its reach or engagement."
                          >
                            <IconButton>
                              <InfoIcon width="18" height="18" />
                            </IconButton>
                          </LightTooltip>
                        </span>
                        <p className="mb-0">
                          A payment negotiated with each of your collaborators
                        </p>
                      </div>
                      {/* <div
                        className="w-25 currency-dropdown-box "
                        id="currency-dropdown-box"
                      >
                        <select
                          className="form-select mb-0 campaignSelect currency-dropdown-box"
                          name="creator_categories"
                          aria-label="Default select example"
                        >
                          <option selected>$ (USD)</option>
                          <option value={1}>$ (EUR)</option>
                          <option value={2}>$ (JPY)</option>
                          <option value={3}>$ (GBP)</option>
                          <option value={4}>$ (USD)</option>
                          <option value={5}>$ (USD)</option>
                        </select>
                        <p className="ms-2 text-end">*Choose a currency</p>
                      </div> */}
                    </div>
                  </div>
                  <div className="campaign-incentive-outer d-flex align-items-center mt-2">
                    <div className="campaign-incentive-inner1">
                      <input
                        type="checkbox"
                        className="incentive-checkbox"
                        defaultValue="negotiated_product"
                        disabled={true}
                      // onClick={() =>
                      //   setCampaignIncentive("product_or_service")
                      // }
                      />
                    </div>

                    <div className="campaign-incentive-inner2 d-flex">
                      <div className="w-60">
                        <span className="text-muted">
                          Product or service
                          <img
                            className="ms-2"
                            src="/assets/images/comingsoon.png"
                            alt="coming-soon"
                            width={70}
                          />
                        </span>
                        <p className="mb-0">
                          You collaborate with creators in exchange of one of
                          your products or services
                        </p>
                      </div>
                      {/* <div className="w-40 d-flex ms-auto">
                        <select
                          className="form-select mb-0 campaignSelect currency-dropdown-box"
                          name="creator_categories"
                          aria-label="Default select example"
                        >
                          <option selected>$ (USD)</option>
                          <option value={1}>$ (EUR)</option>
                          <option value={2}>$ (JPY)</option>
                          <option value={3}>$ (GBP)</option>
                          <option value={4}>$ (USD)</option>
                          <option value={5}>$ (USD)</option>
                        </select>
                        <div className="campaignSelect-box">
                          <input
                            className="ms-2 mb-0 campaignSelect campaignSelect-box"
                            placeholder
                            type="number"
                            name
                          />
                          <p className="ms-2 mb-0 text-end">
                            *Indicate the value
                          </p>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="campaign-incentive-outer d-flex mt-3">
                    <div className="campaign-incentive-inner1">
                      <input
                        type="checkbox"
                        defaultValue="negotiated_commision"
                        className="incentive-checkbox"
                        disabled={true}
                      // onClick={() => setCampaignIncentive("commission")}
                      />
                    </div>
                    <div className="campaign-incentive-inner2 d-flex">
                      <div className="w-60">
                        <span className="text-muted">
                          Commission - Variable fee per acquisition
                          <img
                            className="ms-2"
                            src="/assets/images/comingsoon.png"
                            alt="coming-soon"
                            width={70}
                          />
                        </span>
                        <p>
                          For every purchase generated, your collaborator will
                          receive a commission
                        </p>
                      </div>

                      {/* <div className="w-40 ms-auto">
                        <input
                          className=" mb-0 campaignSelect campaignSelect-box"
                          placeholder
                          type="number"
                          name
                        />
                      </div> */}
                    </div>
                  </div>
                </div>
                {incentiveError ? (
                  <div className="mb-2 errorText">
                    {errorData.get("incentive")}
                  </div>
                ) : (
                  ""
                )}
                <div className="col-md-12">
                  <div className="button-box">
                    <button
                      className="btn back-btn me-2"
                      onClick={() =>
                        moveToBack("campaign-step-3", "campaign-step-2")
                      }
                    >
                      Back
                    </button>
                    <button
                      className="btn common-button-style-filled next-btn me-sm-4"
                      onClick={() =>
                        moveToNext("campaign-step-3", "campaign-step-4")
                      }
                    >
                      {nextLoading ? (
                        <PuffLoader
                          className="d-flex justify-content-center"
                          color="white"
                          size={22}
                        />
                      ) : (
                        "Next"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* campaign-locations-section */}
            <div
              className="campaign-location-outer campaign-section position-relative"
              style={{ display: "none" }}
              id="campaign-step-4"
            >
              <h3>
                Location (where you want the creators to be from)
                <LightTooltip
                  placement="right"
                  title="This will determine which creators are best suited for your campaign and will position this campaign on campaigns visible to creators in “country” demographics. If your campaign can be undertook by anyone regardless of geographical location select “Worldwide”"
                >
                  <IconButton>
                    <InfoIcon width="24" height="24" />
                  </IconButton>
                </LightTooltip>
              </h3>
              <div className="row">
                <div className="col-md-12">
                  <div className="campaign-location-btn-outer mt-4 d-flex">
                    <div className="location-tabs-section">
                      <nav>
                        <div
                          className="nav nav-tabs"
                          id="nav-tab"
                          role="tablist"
                        >
                          <button
                            className={`nav-link location-btn fw-bolder ${selectedLocation !== "worldwide" ? "active" : ""
                              }`}
                            id="nav-home-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-home"
                            type="button"
                            role="tab"
                            aria-controls="nav-home"
                            aria-selected={selectedLocation !== "worldwide"}
                            onClick={() => {
                              setStepFourChange((prev) => true);
                              setSelectedLocation("");
                            }}
                          >
                            Specific Location
                          </button>
                          <button
                            className={`nav-link ms-4 location-btn fw-bolder ${selectedLocation === "worldwide" ? "active" : ""
                              }`}
                            id="nav-profile-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#nav-profile"
                            type="button"
                            role="tab"
                            aria-controls="nav-profile"
                            aria-selected={selectedLocation === "worldwide"}
                            onClick={() => {
                              setStepFourChange((prev) => true);
                              setSelectedLocation("worldwide");
                            }}
                          >
                            Worldwide
                          </button>
                        </div>
                      </nav>
                      <div className="tab-content" id="nav-tabContent">
                        {selectedLocation !== "worldwide" ? (
                          <div
                            className="tab-pane position-relative fade show mt-5 active"
                            id="nav-home"
                            role="tabpanel"
                            aria-labelledby="nav-home-tab"
                          >
                            <label className="campaign-sec-label mb-2">
                              Select one or more Locations
                            </label>
                            <GooglePlacesAutocomplete
                              apiKey="AIzaSyD7bv-x9cb4Dw90PPk8jGchL0-miIVyxSI"
                              selectProps={{
                                placeholder:
                                  selectedLocation.length > 0
                                    ? selectedLocation
                                    : "Add a location",
                                value: selectedLocation,
                                onChange: (event) => {
                                  setStepFourChange((prev) => true);
                                  setSelectedLocation(event.label);
                                  if (locationError) {
                                    removeError("location");
                                  }
                                },
                              }}
                              styles={{
                                width: "77%",
                              }}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                        <div
                          className="tab-pane fade mt-5"
                          id="nav-profile"
                          role="tabpanel"
                          aria-labelledby="nav-profile-tab"
                        >
                          <div className="d-flex agree-box">
                            <input
                              value={worldWideLocation}
                              type="checkbox"
                              className="me-3"
                              onChange={() => {
                                setStepFourChange((prev) => true);
                                setWorldWideLocation(!worldWideLocation);
                                if (locationError) {
                                  removeError("location");
                                }
                              }}
                            />

                            <span>
                              I agree to receive proposals from anywhere in the
                              world.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div
                    className="map-box"
                    id="map-box"
                    style={{ border: "0px solid red", height: "90px" }}
                  >
                    <img
                      style={{
                        zIndex: -1,
                        position: "absolute",
                        bottom: "4rem",
                      }}
                      className="img-fluid"
                      src="/assets/images/world_map.png"
                      alt=""
                    />
                  </div>
                </div>
                {locationError ? (
                  <div className="mb-2 errorText">
                    {errorData.get("location")}
                  </div>
                ) : (
                  ""
                )}
                <div className="col-md-12">
                  <div className="button-box">
                    <button
                      className="btn back-btn me-2"
                      onClick={() =>
                        moveToBack("campaign-step-4", "campaign-step-3")
                      }
                    >
                      Back
                    </button>
                    <button
                      className="btn common-button-style-filled next-btn me-sm-4"
                      onClick={() =>
                        moveToNext("campaign-step-4", "campaign-step-5")
                      }
                    >
                      {nextLoading ? (
                        <PuffLoader
                          className="d-flex justify-content-center"
                          color="white"
                          size={22}
                        />
                      ) : (
                        "Next"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* Add images campaign */}
            <div
              className="add-images-campaign campaign-section position-relative"
              style={{ display: "none" }}
              id="campaign-step-5"
            >
              <h3>
                Add an Image to your campaign.
                <LightTooltip
                  placement="right"
                  title="Choose a striking image to represent your campaign. You can upload a custom photo that embodies your message or select a color that aligns with your brand identity. This image will be the first thing people see in your campaign, so make sure it captures attention and conveys the essence of your initiative."
                >
                  <IconButton>
                    <InfoIcon width="24" height="24" />
                  </IconButton>
                </LightTooltip>
              </h3>
              <div className="col-md-12 add-image-col d-flex align-items-center">
                <div className="drop_box">
                  {filesContent.length > 0 ? (
                    <img
                      onClick={() => {
                        openFileSelector();
                        setStepFiveChange(true);
                        setPickedColor("");
                      }}
                      className="campaign-upload-image"
                      src={filesContent[0].content}
                      alt="..."
                    />
                  ) : (
                    <>
                      <span
                        className="btn"
                        onClick={() => {
                          openFileSelector();
                          setStepFiveChange(true);
                          setPickedColor("");
                          if (imageError) {
                            removeError("image");
                          }
                        }}
                      >
                        <div className="camapign-upload-icon">
                          <svg
                            width={20}
                            height={20}
                            x={0}
                            y={0}
                            viewBox="0 0 512.056 512.056"
                            style={{ enableBackground: "new 0 0 512 512" }}
                            xmlSpace="preserve"
                          >
                            <g>
                              <path
                                d="M426.635 188.224C402.969 93.946 307.358 36.704 213.08 60.37 139.404 78.865 85.907 142.542 80.395 218.303 28.082 226.93-7.333 276.331 1.294 328.644c7.669 46.507 47.967 80.566 95.101 80.379h80v-32h-80c-35.346 0-64-28.654-64-64 0-35.346 28.654-64 64-64 8.837 0 16-7.163 16-16-.08-79.529 64.327-144.065 143.856-144.144 68.844-.069 128.107 48.601 141.424 116.144a16 16 0 0 0 13.6 12.8c43.742 6.229 74.151 46.738 67.923 90.479-5.593 39.278-39.129 68.523-78.803 68.721h-64v32h64c61.856-.187 111.848-50.483 111.66-112.339-.156-51.49-35.4-96.241-85.42-108.46z"
                                fill="#000000"
                                data-original="#000000"
                              />
                              <path
                                d="m245.035 253.664-64 64 22.56 22.56 36.8-36.64v153.44h32v-153.44l36.64 36.64 22.56-22.56-64-64c-6.241-6.204-16.319-6.204-22.56 0z"
                                fill="#000000"
                                data-original="#000000"
                              />
                            </g>
                          </svg>
                        </div>
                        <div className="campaign-upload-text">
                          Upload Your Image
                        </div>
                      </span>
                    </>
                  )}
                </div>
                {filesContent.length > 0 ? (
                  <div
                    onClick={() => {
                      openFileSelector();
                      setStepFiveChange(true);
                    }}
                    className="rounded-pill p-1 px-2 text-highlight fw-bold change-img"
                  >
                    Change Image
                  </div>
                ) : null}
                <div className="center-seprater">
                  <span>Or</span>
                </div>
                <div className="color-selector position-relative">
                  <button
                    className="color-selector-btn text-light"
                    style={{ backgroundColor: color ?? "#ff7070" }}
                    onClick={changeColorPicker}
                  >
                    Select the color
                  </button>
                  {colorPicker ? (
                    <div>
                      <ChromePicker
                        id="chromePicker"
                        className="chrome-picker-d"
                        color={color}
                        onChange={selectColor}
                      />
                      <div
                        className="colorPickerButton"
                        onClick={() => {
                          setColorPicker(false);
                          if (imageError) {
                            removeError("image");
                          }
                        }}
                      >
                        Select
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="photo-box-outer">
                <div className="photo-box">
                  <div className="img-box2">
                    <div className="gallery" />
                  </div>
                </div>
              </div>
              {imageError ? (
                <div className="mb-2 errorText">{errorData.get("image")}</div>
              ) : (
                ""
              )}
              <div className="col-md-12">
                <div className="button-box">
                  <div
                    className="btn back-btn me-2"
                    onClick={() =>
                      moveToBack("campaign-step-5", "campaign-step-4")
                    }
                  >
                    Back
                  </div>
                  <div
                    className="btn common-button-style-filled next-btn me-sm-4"
                    onClick={() =>
                      moveToNext("campaign-step-5", "campaign-step-6")
                    }
                  >
                    {nextLoading ? (
                      <PuffLoader
                        className="d-flex justify-content-center"
                        color="white"
                        size={22}
                      />
                    ) : (
                      "Next"
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* about-campaign */}
            <div
              className="about-campaign campaign-section position-relative"
              style={{ display: "none" }}
              id="campaign-step-6"
            >
              <form
                action="/vitrine/add-campaign"
                autoComplete="off"
                className="create-campaign"
                id="campaign-form"
                encType="multipart/form-data"
                method="POST"
              >
                <div className="campaign-kind-sec spacing mt-5">
                  <h3>
                    What kind of campaign will you offer?
                    <LightTooltip
                      placement="right"
                      title="Tailor your campaign by choosing a category that aligns with your goals and target audience. This helps you to connect with the right collaborators and customers who are interested in what you have to offer."
                    >
                      <IconButton>
                        <InfoIcon width="24" height="24" />
                      </IconButton>
                    </LightTooltip>
                  </h3>
                  <p>Choose the category that best describes your campaign</p>
                  <div className="campaign-category-box mt-2 mb-2 d-flex">
                    {categoriesList.map((categoryData) => {
                      return (
                        <div key={`Category-${categoryData.id}`}>
                          <input
                            id={`Category${categoryData.id}`}
                            className="categoryInputRadio"
                            type="radio"
                            name="campaignDescribes"
                            value={categoryData.id}
                            onChange={(event) => {
                              setStepSixChange(true);
                              setSelectedCategory(event.target.value);
                              if (categoryError) {
                                removeError("category");
                              }

                              if (categoryData.name === "o") {
                                document
                                  .getElementById(
                                    "add-custom-category-modal-btn"
                                  )
                                  .click();
                              }
                            }}
                          />
                          <label htmlFor={`Category${categoryData.id}`}>
                            <div
                              className={
                                "categoryBackground" +
                                (selectedCategory === categoryData.id.toString()
                                  ? " selected"
                                  : "")
                              }
                            >
                              <img
                                src={`data:image/svg+xml;utf8,${encodeURIComponent(
                                  categoryData.svg
                                )}`}
                                alt={`Category-${categoryData.id}-Icon`}
                              />
                              {categoryData.name}
                            </div>
                          </label>
                        </div>
                      );
                    })}
                  </div>

                  {selectedCategory === 15 || selectedCategory === "15" ? (
                    <div>
                      <input
                        className="campaign-select"
                        placeholder="Other Category"
                        value={otherCategory}
                        type="text"
                        name="minm_follower"
                        onChange={(event) => {
                          setStepSixChange(true);
                          setOtherCategory(event.target.value);
                          if (categoryError) {
                            removeError("category");
                          }
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {categoryError ? (
                    <div className="mb-2">
                      <span className="errorText">
                        {errorData.get("category")}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="collaborator">
                  <h5>Tell us about your ideal collaborator</h5>
                  {/* <div className="row"></div> */}
                  <div className="row pb-4">
                    <div className="col-md-6 m-0">
                      <div className="position-relative">
                        <div className="category-label-box d-block">
                          <div className="category-label-box">
                            <label className="campaign-sec-label">
                              What's their minimum number of followers?
                              <LightTooltip
                                placement="right"
                                title="Enter minimum number of followers a creator should have, the number should be in thousands."
                              >
                                <IconButton>
                                  <InfoIcon width="18" height="18" />
                                </IconButton>
                              </LightTooltip>
                            </label>
                          </div>
                        </div>
                        <input
                          className={
                            minFollowerError
                              ? "campaignSelect redOutline"
                              : "campaignSelect"
                          }
                          placeholder="Followers Count"
                          value={minFollower}
                          type="tel"
                          name="minm_follower"
                          onChange={(event) => {
                            const inputValue = event.target.value;
                            if (/^\d*$/.test(inputValue)) {
                              setStepSixChange(true);
                              setMinFollower(inputValue);
                            }
                            if (minFollowerError) {
                              removeError("follower");
                            }
                          }}
                        />
                        {minFollowerError ? (
                          <div className="mb-2">
                            <span className="errorText">
                              {errorData.get("follower")}
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 m-0">
                      <div className="category-label-box d-block">
                        <div className="category-label-box">
                          <label className="campaign-sec-label">
                            What's their minimum engagement rate? (in %)
                            <LightTooltip
                              placement="right"
                              title="Enter the lowest engagement rate you'd consider acceptable for your campaign collaborators. 'Engagement rate' is a metric typically used to measure the level of interaction (such as likes, comments, shares) content receives relative to the audience size. As Vitrine scaled we will soon provide a suggested figure based on industry standards and past campaign data to help you set a realistic and effective threshold. Average engagement rate on instagram is between 1%-3% for niche smaller creators an average of 4%-7% is a good rate. If unsure select 0% and review each creator application."
                            >
                              <IconButton>
                                <InfoIcon width="18" height="18" />
                              </IconButton>
                            </LightTooltip>
                          </label>
                        </div>
                      </div>
                      <input
                        className={
                          minEngagementError
                            ? "campaignSelect redOutline"
                            : "campaignSelect"
                        }
                        placeholder="Engagement Rate (%)"
                        value={engageRate}
                        type="number"
                        min={0}
                        max={100}
                        step="0.01"
                        name="engagement_rate"
                        onChange={(event) => {
                          const inputValue = event.target.value;
                          const formattedValue = inputValue.includes(".")
                            ? inputValue.split(".")[0] +
                            "." +
                            inputValue.split(".")[1].slice(0, 3)
                            : inputValue;

                          if (formattedValue <= 100 && formattedValue >= 0) {
                            setStepSixChange(true);
                            setEngageRate(formattedValue);
                          }

                          if (minEngagementError) {
                            removeError("engage");
                          }
                        }}
                      />

                      {minEngagementError ? (
                        <div className="mb-2">
                          <span className="errorText">
                            {errorData.get("engage")}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 d-block">
                      <div className="category-label-box">
                        <label className="campaign-sec-label ps-3">
                          Choose ideal creator categories. You can select up to
                          5 tags.*
                          <LightTooltip
                            placement="right"
                            title="You can set up to 5 tags for your campaing to assist in matching you with the creators specialized in those areas. This ensures that the creators who see your campaign are the ones most likely to be interested in and influential within those particular topics."
                          >
                            <IconButton>
                              <InfoIcon width="18" height="18" />
                            </IconButton>
                          </LightTooltip>
                        </label>
                      </div>
                      <div className="creator-category-modal-btn-box">
                        <button
                          type="button"
                          className={
                            tagError
                              ? "btn creator-category-modal-btn btn-primary mb-0 redOutline"
                              : "btn creator-category-modal-btn btn-primary"
                          }
                          data-bs-toggle="modal"
                          data-bs-target="#selecttag-modal"
                          onClick={() => setOtherTagSelected(false)}
                          style={{ color: "#616161" }}
                        >
                          {selectedTags.length > 0
                            ? selectedTags.join(", ")
                            : "Select Tags"}
                        </button>
                      </div>
                      {tagError ? (
                        <div className="mb-2">
                          <span className="errorText">
                            {errorData.get("tags")}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </form>
              <div className="col-md-12 mt-xxl-5">
                <div className="button-box">
                  <button
                    className="btn back-btn me-2"
                    onClick={() =>
                      moveToBack("campaign-step-6", "campaign-step-5")
                    }
                  >
                    Back
                  </button>
                  <button
                    className="btn common-button-style-filled next-btn me-sm-4"
                    onClick={() =>
                      moveToNext("campaign-step-6", "campaign-step-7")
                    }
                  >
                    {nextLoading ? (
                      <PuffLoader
                        className="d-flex justify-content-center"
                        color="white"
                        size={22}
                      />
                    ) : (
                      "Next"
                    )}
                  </button>
                </div>
              </div>
            </div>
            {/* campaign-detail-section */}
            <div
              className="campaign-detail campaign-section position-relative"
              style={{ display: "none" }}
              id="campaign-step-7"
            >
              <h3>Almost there! Just a few more details…</h3>
              <div className="custom-height-campaign">
                <div className="col-md-12">
                  <div>
                    <label className="campaign-sec-label">
                      Include the URL you want to be shared in your
                      collaborations
                      <LightTooltip
                        placement="right"
                        title="This link will be shared by collaborators in their content to direct traffic to your designated page. Ensure it's correct, as this is key for tracking campaign performance and engagement."
                      >
                        <IconButton>
                          <InfoIcon width="18" height="18" />
                        </IconButton>
                      </LightTooltip>
                    </label>
                  </div>
                  <div className="pb-4">
                    <input
                      type="text"
                      placeholder="https://www."
                      value={urlShare}
                      name="_url"
                      className={
                        urlError ? "campaignInput redOutline" : "campaignInput"
                      }
                      onChange={(event) => {
                        setUrl(event.target.value);
                        if (urlError) {
                          removeError("url");
                        }
                      }}
                    />
                    {urlError ? (
                      <div className="mb-2">
                        <span className="errorText">
                          {errorData.get("url")}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div>
                    <label className="campaign-sec-label">
                      # Hashtags - Include the hashtags you want to be shared by
                      the creator in the description of their content.
                      <LightTooltip
                        placement="right"
                        title="Specify the hashtags that you wish creators to use in their posts about your campaign. Hashtags increase the visibility of content and help it reach a targeted audience. Limit the hashtags to 3."
                      >
                        <IconButton>
                          <InfoIcon width="18" height="18" />
                        </IconButton>
                      </LightTooltip>
                    </label>
                  </div>
                  <div className="pb-4">
                    <input
                      type="text"
                      placeholder="e.g. #beautyskinnaturals #skinnaturalsxmas"
                      name="_tags"
                      className={
                        hashTagError
                          ? "campaignInput redOutline"
                          : "campaignInput"
                      }
                      value={hashTags}
                      onChange={(event) => {
                        setHashTags(event.target.value);
                        if (hashTagError) {
                          removeError("htags");
                        }
                      }}
                    />
                    {hashTagError ? (
                      <div className="mb-2">
                        <span className="errorText">
                          {errorData.get("htags")}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div>
                    <label className="campaign-sec-label">
                      @ Mentions - Include the mentions you want to be shared by
                      the creator in the description of their content.
                      <LightTooltip
                        placement="right"
                        title="Indicate the specific usernames or profiles that you want creators to mention in their content descriptions. Mentions can increase the reach of your campaign by alerting your profile to engaged users and drawing their followers' attention."
                      >
                        <IconButton>
                          <InfoIcon width="18" height="18" />
                        </IconButton>
                      </LightTooltip>
                    </label>
                  </div>
                  <div className="pb-4">
                    <input
                      type="text"
                      placeholder="e.g. @beautyskinnaturals"
                      name="_mentions"
                      className={
                        mentionError
                          ? "campaignInput redOutline"
                          : "campaignInput"
                      }
                      value={mentions}
                      onChange={(event) => {
                        setMentions(event.target.value);
                        if (mentionError) {
                          removeError("mention");
                        }
                      }}
                    />
                    {mentionError ? (
                      <div className="mb-2">
                        <span className="errorText">
                          {errorData.get("mention")}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div>
                    <label className="campaign-sec-label">
                      Time limit for publications - Your campaign deadline, how
                      many days remain for your campaign.
                      <LightTooltip
                        placement="right"
                        title="If you have a specific date by which all activities/posts should be completed, enter it here to keep everyone aware of the schedule."
                      >
                        <IconButton>
                          <InfoIcon width="18" height="18" />
                        </IconButton>
                      </LightTooltip>
                    </label>
                  </div>
                  <div className="pb-4">
                    <input
                      type="text"
                      placeholder="Add a deadline, if you have one"
                      name="timeline"
                      className="campaignInput"
                      value={timeLimit}
                      onChange={(event) => setTimeLimit(event.target.value)}
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <label className="campaign-sec-label">
                    Desired deals per month (confidential) - This is used for
                    the user to measure expected activity vs real activity, it
                    is confidential and will only be visible to you.
                    <LightTooltip
                      placement="right"
                      title="Enter the number of collaborations you aim to achieve each month for this campaign. If this is a camapign to be undertook by one single creator set “1”. This goal-setting feature helps you track the performance of your campaign against your expectations."
                    >
                      <IconButton>
                        <InfoIcon width="18" height="18" />
                      </IconButton>
                    </LightTooltip>
                  </label>
                  <div className="pb-4">
                    <input
                      type="number"
                      name="deals_perMonth"
                      placeholder="Add your expected collaborations per month"
                      className="campaignInput"
                      value={dealsDesired}
                      onChange={(event) => setDealsDesired(event.target.value)}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          submitCampaign();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div>
                    <label className="campaign-sec-label">
                      Preferred Campaigns from:
                    </label>
                  </div>
                  {specificGender.map((gender, index) => {
                    return (
                      <div key={index} className="d-flex">
                        <input
                          type="radio"
                          id={`gender-${index}`}
                          name="gender"
                          value={gender}
                          checked={selectedGender === gender}
                          onChange={handleGenderChange}
                        />
                        <label htmlFor={`gender-${index}`}>
                          {gender.charAt(0).toUpperCase() + gender.slice(1)}
                        </label>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col-md-12 mt-xxl-5 my-xl-3">
                <div className="button-box">
                  <button
                    href="#"
                    className="btn back-btn me-2"
                    onClick={() =>
                      moveToBack("campaign-step-7", "campaign-step-6")
                    }
                  >
                    Back
                  </button>
                  <button
                    className="btn common-button-style-filled next-btn me-sm-4"
                    type="submit"
                    onClick={submitCampaign}
                  >
                    {nextLoading ? (
                      <PuffLoader
                        className="d-flex justify-content-center"
                        color="white"
                        size={22}
                      />
                    ) : (
                      "Publish Campaign"
                    )}
                  </button>
                </div>
              </div>
            </div>
            {/* campaign-thank-you */}
          </div>
          <div className="col-md-5 h-100 campaignRightCol">
            <div className="campaign-images-sec h-100 position-relative">
              <div className="d-flex justify-content-end contact-btn-box-outer w-100 mt-3 position-absolute">
                <div className="contact-btn-box">
                  {/* <button className="campaign-top-btn" type="button">Contact Support</button> */}
                </div>
                <div className="contact-btn-box">
                  <button
                    className="campaign-top-btn"
                    type="button"
                    onClick={() => {
                      navigate("/mainHelp");
                    }}
                  >
                    Help Center
                  </button>
                </div>
                <div
                  className="close-btn-box me-3"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <img src="/assets/images/close-Icon.png" alt="X" />
                </div>
              </div>
              <div className="inner-curve">
                <div className="" id="campaign-img1">
                  <img
                    src="/assets/images/banner/rotate-glass-opt_2.gif"
                    className="rotating-top img-fluid active-image"
                    alt=".6.sss."
                  />
                  <img
                    src="/assets/images/podium_landscape -opt.png"
                    className="sticky-rotate-bottom img-fluid active-image"
                    alt="..12."
                  />
                </div>
                {/* <img
                  className="img-fluid sec-img active-image"
                  src="/assets/images/campaign/campaign-img1.png"
                  id="campaign-img1"
                  alt=""
                /> */}
                <img
                  className="img-fluid sec-img"
                  src="/assets/images/campaign/campaign_image_2.png"
                  id="campaign-img2"
                  alt=""
                />
                <img
                  className="img-fluid sec-img"
                  src="/assets/images/campaign/campaign_image_3.png"
                  id="campaign-img3"
                  alt=""
                />
                <img
                  className="img-fluid sec-img"
                  src="/assets/images/campaign/campaign_image_4.png"
                  alt=""
                  id="campaign-img4"
                />
                <img
                  className="img-fluid sec-img"
                  src="/assets/images/campaign/campaign_image_5.png"
                  alt=""
                  id="campaign-img5"
                />
                <img
                  className="img-fluid sec-img"
                  src="/assets/images/campaign/campaign_image_6.svg"
                  alt=""
                  id="campaign-img6"
                />
                <img
                  className="img-fluid sec-img"
                  src="/assets/images/campaign/campaign_image_7.png"
                  alt=""
                  id="campaign-img7"
                />
              </div>
              {/* <img
                className="yellow-circle-ball"
                src="/assets/images/circle_3.png"
                alt="circle_3"
              />
              <img
                className="bitcoin"
                src="/assets/images/bitcoin_1.png"
                alt="bitcoin_1"
              /> */}
            </div>
          </div>
        </div>
      </div>
      {/* Select Tag-Modal */}
      <div
        className="modal fade mt-5"
        id="selecttag-modal"
        tabIndex={-1}
        aria-labelledby="user-profile-settings-modal"
        aria-hidden="true"
      >
        <div className="modal-dialog mt-5 pt-5 user-settings-modal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="user-profile-Label">
                Select tags
              </h5>
              <button
                type="button"
                className="popup-close-btn fs-0"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="tag-modal-close"
              >
                <svg
                  width={15}
                  height={15}
                  x={0}
                  y={0}
                  viewBox="0 0 426.667 426.667"
                  style={{ enableBackground: "new 0 0 512 512" }}
                  xmlSpace="preserve"
                >
                  <g>
                    <path
                      d="M426.667 59.733 366.933 0l-153.6 153.6L59.733 0 0 59.733l153.6 153.6L0 366.933l59.733 59.734 153.6-153.6 153.6 153.6 59.734-59.734-153.6-153.6z"
                      fill="#000000"
                      data-original="#000000"
                    />
                  </g>
                </svg>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-md-12 popup-box pt-4 d-block">
                <div className="profile-tags-box-outer mb-5 d-flex flex-wrap">
                  {availableTags.map((tag, index) => (
                    <div key={index} className="radio-with-Icon me-2 mt-2">
                      <p className="radioOption-Item radioOption-Item-category mb-0">
                        <input
                          type="checkbox"
                          name={`category-btn-${index}`}
                          id={`category-btn${index}`}
                          value={tag}
                          checked={selectedTags.includes(tag)}
                          onChange={() => {
                            setStepSixChange(true);
                            handleTagClick(tag);
                            if (tagError) {
                              removeError("tags");
                            }
                          }}
                        />
                        <label
                          className="profile-tag-btn"
                          htmlFor={`category-btn${index}`}
                        >
                          <span>{tag}</span>
                        </label>
                      </p>
                    </div>
                  ))}
                  <label
                    onClick={() => {
                      if (selectedTags.length < 5) {
                        setOtherTagSelected(!otherTagSelected);
                      } else {
                        toast.error("You can only add upto 5 Tags");
                      }
                    }}
                    className={`mt-2 profile-tag-btn ${otherTagSelected ? "tag-selected" : ""}`}
                  >
                    <span>Other</span>
                  </label>
                </div>

                {otherTagSelected && (
                  <div className="position-relative">
                    <label className="campaign-sec-label">
                      Please type out the tag name that you want it to be:
                    </label>
                    <input
                      className="campaign-select w-75"
                      placeholder="Other Tag"
                      value={otherTag}
                      type="text"
                      name="other_tag_name"
                      onChange={(event) => {
                        setOtherTag(event.target.value);
                      }}
                    />
                    <button
                      onClick={() => {
                        if (!availableTags.includes(otherTag) && selectedTags.length < 5) {
                          if (otherTag) {
                            setAvailableTags([...availableTags, otherTag]);
                            setSelectedTags([...selectedTags, otherTag]);
                            setOtherTag("");
                          } else {
                            toast.error("Invalid entry")
                          }
                        } else {
                          toast.error("You can only add upto 5 Tags");
                          setTimeout(() => {
                            setOtherTag("");
                            setOtherTagSelected(false);

                          }, 1000);
                        }
                      }}
                      className="new-filled-btn rounded-pill border-0 ms-2 fs-6 px-3"
                    >
                      Add
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Custom Category Modal  */}
      <div
        className="modal fade mt-5 pt-5 "
        id="add-custom-category-modal"
        tabIndex={-1}
        aria-labelledby="add-custom-category-modal"
        aria-hidden="true"
      >
        <div className="modal-dialog user-settings-modal">
          <div className="modal-content">
            <div className="modal-body">
              <div
                className=" close-btn-box new-lg-close-position"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="close-modal"
              >
                <img src="/assets/images/close-Icon2.svg" alt="X" />
              </div>

              <div className="mt-3 info-heading fs-4">
                Add a category your Brand falls under
              </div>
              <div className="d-flex justify-content-center my-4">
                <input
                  type="text"
                  className="ant-select-selection-search-input w-100"
                  placeholder="Add a category"
                  value={customCategory}
                  onKeyUp={(event) => {
                    if (event.key === "Enter") {
                      handleAddCategory();
                    }
                  }}
                  onChange={(e) => {
                    setCustomCategory(e.target.value);
                  }}
                />
              </div>
              <div className="d-flex justify-content-center my-4">
                <textarea
                  style={{
                    minHeight: "100px",
                    maxHeight: "200px",
                    overflowY: "auto",
                  }}
                  className="w-100 pt-3 ant-select-selection-search-input"
                  name="description"
                  placeholder="Tell us more about this category (FOR VITRINE)"
                  value={customCategoryDescription}
                  // maxLength="500"
                  onChange={(event) => {
                    setCustomCategoryDescription(event.target.value);
                  }}
                  onKeyUp={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                    }
                  }}
                />
              </div>
              <div className="d-flex justify-content-end">
                <button
                  onClick={() => {
                    handleAddCategory();
                  }}
                  className="btn common-button-style-filled"
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* CAMPAIGN SUBMITTED MODAL  */}
      <div
        className="modal fade mt-5 pt-5 "
        id="campaign-success-modal"
        tabIndex={-1}
        aria-labelledby="campaign-success-modal"
        aria-hidden="true"
      >
        <div className="modal-dialog user-settings-modal">
          <div className="modal-content">
            <div className="modal-body">
              <div
                className=" close-btn-box new-lg-close-position"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <img src="/assets/images/close-Icon2.svg" alt="X" />
              </div>
              <div className="d-flex justify-content-center mt-4">
                <img
                  src="/assets/images/info-received.png"
                  alt="Info Received"
                  width={250}
                />
              </div>
              <div className="mt-3 info-heading">Campaign Submitted</div>
              <div className="d-flex justify-content-center">
                <p className="mx-5 px-2 text-center lh-lg">
                  We’re pleased to inform you that your campaign details have
                  been successfully submitted and are currently under
                  verification with Vitrine. Upon approval, they will go live
                  and be accessible to creators.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddCampaigns;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFilePicker } from "use-file-picker";
import { toast } from "react-toastify";
import { updateBrandProfile } from "../../Common/Repository";
import { useDispatch, useSelector } from "react-redux";
import { logout, setUser } from "../../states/auth/authSlice";
import DropdownForList from "../../Components/DropdownForList";
import LightTooltip from "../../Components/LightToolTip";
import { IconButton } from "@mui/material";
import { InfoIcon } from "../../Components/Icons";
import { PuffLoader } from "react-spinners";

const BrandCompleteProfile = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { user, token } = useSelector((state) => state.auth);

  const [step, setStep] = useState(1);

  const [isStepLoading, setIsStepLoading] = useState(true);

  const [showErrors, setShowErrors] = useState(null);

  const [bio, setBio] = useState(user?.brand.bio ?? "");

  const [websiteLink, setWebsiteLink] = useState(
    user?.brand.website_link ?? ""
  );

  const [instaLink, setInstaLink] = useState(user?.brand.instagram ?? "");

  const [customIndustry, setCustomIndustry] = useState("");

  const [industry, setIndustry] = useState(user?.brand?.brand_industry ?? "");

  const [estDate, setEstDate] = useState(user?.brand?.established_date ?? "");

  const [socialInterest, setSocialInterest] = useState(
    user?.brand?.brand_social_interest ?? ""
  );

  const [linkedinLink, setLinkedinLink] = useState(user?.brand.linkedIn ?? "");

  const [industrySearch, setIndustrySearch] = useState("");
  const [makeIndSearch, setMakeIndSearch] = useState(false);
  const [filterIndustries, setFilteredIndustries] = useState([]);

  const [interestSearch, setInterestSearch] = useState("");
  const [makeInterestSearch, setMakeInterestSearch] = useState(false);
  const [filterInterests, setFilteredInterests] = useState([]);

  const profileTag = user?.brand?.profile_tags;

  // If profileTag is null or undefined, default to an empty array
  const parsedProfileTags = Array.isArray(profileTag) ? profileTag : [];

  // Join the array into a string
  const profileTagsString = parsedProfileTags.join(",");

  const [profileTags, setProfileTags] = useState(profileTagsString);

  useEffect(() => {
    const savedStep = localStorage.getItem("currentStep");
    if (savedStep) {
      setStep(Number(savedStep));
    } else {
      setStep(1);
    }
    setIsStepLoading(false);
  }, []);

  const handleStepBack = () => {
    if (step === 1) {
      navigate(-1); // Navigates back in history
    } else {
      setStep((prevStep) => {
        const newStep = prevStep - 1;
        localStorage.setItem("currentStep", newStep); // Update localStorage with the new step
        return newStep;
      });
    }
  };

  const handleNext = () => {
    if (step === 2) {
      navigate("/creatorsCatalogue");
    } else {
      const newStep = step + 1;
      setStep(newStep);
      localStorage.setItem("currentStep", newStep);
    }
  };

  const handleAddIndustry = () => {
    if (customIndustry !== "") {
      setIndustry(customIndustry);
      setIndustryData([...industryData, customIndustry]);
      dropdownOpen();
      document.getElementById("close-modal").click();
      toast.success("Industry added");
    } else {
      toast.error("Industry can not be empty!");
    }
  };

  const submitProfileDetails = async (action) => {
    try {
      const data = {};
      data.bio = bio;
      data.brand_industry = industry || "";
      data.established_date = estDate || "";
      data.brand_social_interest = socialInterest || "";
      data.linkedIn = linkedinLink || "";
      data.instagram = instaLink || "";
      data.website_link = websiteLink || "";
      data.profile_complete = action === "finish" ? "1" : "0";
      data.profile_tags = profileTags?.split(",");
      const response = await updateBrandProfile(
        {
          Authorization: `Bearer ${token}`,
        },
        data
      );

      if (response) {
        const newUser = {
          ...user,
          brand: {
            ...user.brand,
            bio: bio || "",
            website_link: websiteLink || "",
            linkedIn: linkedinLink || "",
            instagram: instaLink || "",
            profile_tags: JSON.stringify(profileTags?.split(",")),
            brand_industry: industry,
            established_date: estDate,
            brand_social_interest: socialInterest,
            profile_complete: action === "finish" ? "1" : "0",
          },
        };
        dispatch(setUser(newUser));
        if (action === "save") {
          toast.success("Saved For Later!");
          document.getElementById("close-page").click();
        } else if (action === "finish") {
          toast.success("Information Sent Successfully!");
          setTimeout(() => {
            dispatch(logout());
            navigate("/");
            document.getElementById("info-received-modal-btn").click();
          }, 500);
        }
      }
    } catch (error) {
      setShowErrors(error?.response?.data?.error);
      toast.error("Failed to add Information.");
    }
  };

  const handleBack = () => {
    submitProfileDetails("save");
  };

  const [openFileSelector, { filesContent }] = useFilePicker({
    readAs: "DataURL",
    accept: "image/jpeg, image/png",
    multiple: false,
    limitFilesConfig: { max: 1 },
    maxFileSize: 50,
    onFilesSelected: ({ plainFiles, filesContent, errors }) => {
      // this callback is always called, even if there are errors
      handleProfileImageUpdate(filesContent);
    },
  });

  const dataURItoBlob = (dataURI) => {
    if (dataURI) {
      const byteString = atob(dataURI.split(",")[1]);
      const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    }
  };

  const handleProfileImageUpdate = async (filesContent) => {
    try {
      let newFormData = new FormData();
      let blobbedImage = dataURItoBlob(filesContent[0]?.content);
      newFormData.append(`profile_image`, blobbedImage);

      let response = await updateBrandProfile(
        {
          Authorization: `Bearer ${token}`,
        },
        newFormData
      );

      if (response?.status === 200) {
        const newUser = {
          ...user,
          brand: {
            ...user.brand,
            profile_image: filesContent[0]?.content,
          },
        };
        dispatch(setUser(newUser));
        toast.success("Profile Image Updated Successfully!");
      }
    } catch (error) {
      toast.error("Failed to update Profile Image");
    }
  };

  const [industryData, setIndustryData] = useState([
    "Clothing and fashion",
    "Jewelry",
    "Cosmetics",
    "Hardware",
    "Software",
    "Food and beverage",
    "Health and fitness",
    "Travel and tourism",
    "Home decor",
    "Beauty and skincare",
    "Parenting and childcare",
    "Gaming",
    "Education",
    "Technology",
    "Pets",
    "Investing",
    "Sports",
  ]);

  const socialInterestData = [
    "Instagram campaigns",
    "TikTok campaigns",
    "LinkedIn campaigns",
  ];

  const handleIndustryClick = (value) => {
    setIndustry(value);
    dropdownOpen();
  };

  const handleSocialInterestClick = (value) => {
    setSocialInterest(value);
    dropdownOpen("interest");
  };

  const handleSearchClick = (value) => {
    if (value === "industry") {
      setMakeIndSearch(true);
      let myList = [];
      industryData.forEach((value, index) => {
        if (value.toLowerCase().indexOf(industrySearch.toLowerCase()) > -1) {
          myList.push(value);
        }
      });
      setFilteredIndustries(myList);
    }
    if (value === "interest") {
      setMakeInterestSearch(true);
      let myList = [];
      socialInterestData.forEach((value, index) => {
        if (value.toLowerCase().indexOf(interestSearch.toLowerCase()) > -1) {
          myList.push(value);
        }
      });
      setFilteredInterests(myList);
    }
  };

  const dropdownOpen = (event) => {
    setIndustrySearch("");
    setInterestSearch("");
    let obj;
    if (event === "industry") {
      setCustomIndustry("");
      obj = document.getElementById("industry-dropdown")?.parentNode;
    }
    if (event === "interest") {
      obj = document.getElementById("interested-dropdown")?.parentNode;
    }
    if (window.$(obj).find(".inner-dropdown").hasClass("open")) {
      window.$(obj).find(".inner-dropdown").removeClass("open");
    } else {
      window.$(".inner-dropdown").removeClass("open");
      window.$(obj).find(".inner-dropdown").addClass("open");
      window.$(obj).find(".inner-dropdown").removeClass("d-none");
    }
  };

  const handleResetClick = (key) => {
    if (key === "interest") {
      setSocialInterest("");
      setInterestSearch("");
      setFilteredInterests([]);
      setMakeInterestSearch(false);
    } else if (key === "industry") {
      setIndustry("");
      setIndustrySearch("");
      setFilteredIndustries([]);
      setMakeIndSearch(false);
    }
  };
  return (
    <section className="campaign-faq-section-main">
      {isStepLoading ? (
        <>
          <div className="p-5 m-5 d-flex h-100 align-items-center justify-content-center">
            <PuffLoader color="#65acae" size={42} />
          </div>
        </>
      ) : (
        <>
          <div
            data-bs-toggle="modal"
            data-bs-target="#info-received-modal"
            id="info-received-modal-btn"
          ></div>
          <div
            onClick={() => {
              navigate(-1);
            }}
            id="close-page"
            className="close-btn-box-profile me-3"
          >
            <img src="/assets/images/close-Icon.png" alt="X" />
          </div>
          <div className="container-fluid pe-0">
            <div className="col-md-12 p-0 d-flex align-items-center campaign-row">
              <div className="col-md-7 campaign-col pt-5">
                <img
                  className="purple-coin"
                  src="/assets/images/circle_top1.png"
                  alt="circle_top1"
                />
                {step === 1 && (
                  <div
                    id="profile-step-1"
                    className="add-profile-pic-section complete-profile-section"
                  >
                    <div className="inner-section">
                      <h5 className="text-start">
                        <div className="mb-5">
                          <div
                            onClick={() => {
                              handleStepBack();
                            }}
                            className="btn common-button-style-filled"
                          >
                            &#x2190; Back
                          </div>
                        </div>
                        Add your Brand's logo to make your profile stand out
                      </h5>
                      {showErrors != null && showErrors.profile_image ? (
                        <>
                          <div className="text-danger mt-2">
                            {showErrors.profile_image[0]}
                          </div>
                        </>
                      ) : null}
                      {user?.brand?.profile_image !== undefined &&
                      user?.brand?.profile_image !== null ? (
                        <>
                          <div
                            className="m-5 d-flex justify-content-around"
                            onClick={() => openFileSelector()}
                          >
                            <img
                              className="brand-profile-image"
                              src={user?.brand?.profile_image}
                              alt="avatar"
                            />
                            <div className="align-center link-style">
                              Change image
                            </div>
                          </div>
                        </>
                      ) : (
                        <div
                          onClick={() => {
                            openFileSelector();
                            const newError = {
                              ...showErrors, // Assuming existingErrors contains the provided error object
                              profile_image: [""], // Modify the error message for the bio field
                            };
                            setShowErrors(newError);
                          }}
                          className="image-circle"
                        >
                          <img
                            src="/assets/images/Upload_Image.svg"
                            alt="uploadImage"
                          />
                        </div>
                      )}

                      <div className="d-flex justify-content-start">
                        <button
                          className="btn back-btn me-5"
                          onClick={handleBack}
                        >
                          Save for later
                        </button>
                        <button
                          disabled={
                            filesContent.length > 0 ||
                            user?.brand?.profile_image
                              ? false
                              : true
                          }
                          className="btn common-button-style-filled next-btn me-4"
                          onClick={handleNext}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {step === 2 && (
                  <div
                    id="profile-step-2"
                    className="add-more-info-section complete-profile-section"
                  >
                    <div className="inner-section">
                      <div className="mb-5">
                        <div
                          onClick={() => {
                            handleStepBack();
                          }}
                          className="btn common-button-style-filled"
                        >
                          &#x2190; Back
                        </div>
                      </div>
                      <h5>Add more info that is required</h5>
                      <div className="mt-4 d-flex align-items-center">
                        Add a short bio about your Brand
                        <span
                          style={{
                            color: "red",
                            fontSize: "28px",
                            marginLeft: "4px",
                          }}
                        >
                          *
                        </span>
                      </div>
                      <input
                        type="search"
                        className="ant-select-selection-search-input w-100"
                        id="search"
                        placeholder="Short bio here..."
                        role="combobox"
                        aria-haspopup="listbox"
                        aria-owns="rc_select_8_list"
                        aria-autocomplete="list"
                        aria-controls="rc_select_8_list"
                        aria-activedescendant="rc_select_8_list_-1"
                        aria-expanded="false"
                        // onKeyUp="search();"
                        value={bio}
                        onChange={(event) => {
                          const newError = {
                            ...showErrors, // Assuming existingErrors contains the provided error object
                            bio: [""], // Modify the error message for the bio field
                          };
                          setShowErrors(newError);
                          setBio(event.target.value);
                        }}
                      />
                      {showErrors != null && showErrors.bio ? (
                        <>
                          <div className="text-danger mt-2">
                            {showErrors.bio[0]}
                          </div>
                        </>
                      ) : null}
                      <div className="row ">
                        <div className="col-6">
                          <div className="mt-4 d-flex align-items-center">
                            Add an industry of your Brand
                            <span
                              style={{
                                color: "red",
                                fontSize: "28px",
                                marginLeft: "4px",
                              }}
                            >
                              *
                            </span>
                          </div>
                        </div>
                        <div className="col-6 ">
                          <div className="mt-4 d-flex align-items-center">
                            Campaigns my Brand is interested in
                            <span
                              style={{
                                color: "red",
                                fontSize: "28px",
                                marginLeft: "4px",
                              }}
                            >
                              *
                            </span>
                          </div>
                        </div>
                        <div className="col-6 profile-complete">
                          <div className="dropdown-box dropdown-box-list">
                            <div
                              className="dropdown-button d-flex"
                              onClick={(event) => {
                                dropdownOpen("industry");
                              }}
                            >
                              <p className="m-0 me-2 w-100 d-flex align-items-center text-truncate">
                                {industry
                                  ? ` ${
                                      industry.charAt(0).toUpperCase() +
                                      industry.slice(1)
                                    }`
                                  : "Industry"}
                              </p>
                              <div className="d-inline-block">
                                <img
                                  src="/assets/images/arrow-sign.png"
                                  alt=""
                                  width={15}
                                  className=""
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            id="industry-dropdown"
                            className="inner-dropdown mt-3"
                          >
                            <div className="mx-2">
                              <div className="d-flex justify-content-between">
                                <input
                                  type="text"
                                  className="form-control form-control-sm search-input-style"
                                  placeholder="Type to Search"
                                  value={industrySearch}
                                  onChange={(event) => {
                                    if (event.target.value === "") {
                                      handleResetClick("industry");
                                    }
                                    setIndustrySearch(event.target.value);
                                  }}
                                  onKeyUp={(event) => {
                                    let keyPressed = event.code;
                                    if (keyPressed === "Enter") {
                                      handleSearchClick("industry");
                                    }
                                  }}
                                />
                                <div
                                  className="search-filter-icon"
                                  onClick={() => handleSearchClick("industry")}
                                >
                                  <img
                                    alt="s"
                                    src="/assets/images/searchIcon-new.svg"
                                  />
                                </div>
                                <div
                                  onClick={() => handleResetClick("industry")}
                                  className="d-flex align-items-center resethover text-dark fs-6 ms-2 link-style reset-filter-icon"
                                >
                                  <img
                                    src="/assets/images/reset-icon.svg"
                                    alt="reset"
                                  />

                                  {/* Reset */}
                                </div>
                              </div>
                            </div>
                            <DropdownForList
                              dropdownType="industry"
                              selectedValue={industry}
                              data={
                                makeIndSearch ? filterIndustries : industryData
                              }
                              handleClick={(value) =>
                                handleIndustryClick(value)
                              }
                            />
                          </div>
                        </div>

                        <div className="col-6 profile-complete">
                          <div className="dropdown-box dropdown-box-list">
                            <div
                              className="dropdown-button d-flex"
                              onClick={(event) => {
                                dropdownOpen("interest");
                              }}
                            >
                              <p className="m-0 me-2 w-100 d-flex align-items-center text-truncate">
                                {socialInterest
                                  ? ` ${socialInterest}`
                                  : "Interested in"}
                              </p>
                              <div className="d-inline-block">
                                <img
                                  src="/assets/images/arrow-sign.png"
                                  alt=""
                                  width={15}
                                  className=""
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            id="interested-dropdown"
                            className="inner-dropdown mt-3"
                          >
                            <div className="mx-2">
                              <div className="d-flex justify-content-between">
                                <input
                                  type="text"
                                  className="form-control form-control-sm search-input-style"
                                  placeholder="Type to Search"
                                  aria-controls="brand-detail-listing-table"
                                  value={interestSearch}
                                  onChange={(event) => {
                                    if (event.target.value === "") {
                                      handleResetClick("interest");
                                    }
                                    setInterestSearch(event.target.value);
                                  }}
                                  onKeyUp={(event) => {
                                    let keyPressed = event.code;
                                    if (keyPressed === "Enter") {
                                      handleSearchClick("interest");
                                    }
                                  }}
                                />
                                <div
                                  className="search-filter-icon"
                                  onClick={(event) =>
                                    handleSearchClick("interest")
                                  }
                                >
                                  <img
                                    alt="s"
                                    src="/assets/images/searchIcon-new.svg"
                                  />
                                </div>
                                <div
                                  onClick={() => handleResetClick("interest")}
                                  className="d-flex align-items-center resethover text-dark fs-6 ms-2 link-style reset-filter-icon"
                                >
                                  <img
                                    src="/assets/images/reset-icon.svg"
                                    alt="reset"
                                  />

                                  {/* Reset */}
                                </div>
                              </div>
                            </div>
                            <DropdownForList
                              selectedValue={socialInterest}
                              data={
                                makeInterestSearch
                                  ? filterInterests
                                  : socialInterestData
                              }
                              handleClick={handleSocialInterestClick}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-between">
                        <div className="mt-4 d-flex align-items-center">
                          Brand established since
                          <span
                            style={{
                              color: "red",
                              fontSize: "28px",
                              marginLeft: "4px",
                            }}
                          >
                            *
                          </span>
                        </div>
                        <div className="d-flex align-items-center">
                          <LightTooltip
                            placement="left"
                            title="Date must be on or before the current date"
                          >
                            <IconButton>
                              <InfoIcon width="20" height="20" />
                            </IconButton>
                          </LightTooltip>
                        </div>
                      </div>
                      <input
                        type="date"
                        className="ant-select-selection-search-input w-100"
                        id="search"
                        placeholder="Established Since..."
                        max={new Date().toISOString().split("T")[0]} // Disable the future dates
                        value={estDate}
                        onChange={(event) => {
                          setEstDate(event.target.value);
                        }}
                      />
                      <div className="mt-4 d-flex align-items-center">
                        Add your profile tags, if more than one separate it with
                        comma
                        <span
                          style={{
                            color: "red",
                            fontSize: "28px",
                            marginLeft: "4px",
                          }}
                        >
                          *
                        </span>
                      </div>
                      <input
                        type="search"
                        className="ant-select-selection-search-input w-100"
                        id="search"
                        placeholder="e.g. Blog Ads"
                        role="combobox"
                        aria-haspopup="listbox"
                        aria-owns="rc_select_8_list"
                        aria-autocomplete="list"
                        aria-controls="rc_select_8_list"
                        aria-activedescendant="rc_select_8_list_-1"
                        aria-expanded="false"
                        onkeyup="search();"
                        onChange={(event) => {
                          setProfileTags(event.target.value);
                        }}
                        value={profileTags}
                      />
                      <div className="mt-4 mb-2 d-flex align-items-center">
                        Please add your company website link
                      </div>
                      <input
                        type="search"
                        className="ant-select-selection-search-input w-100"
                        id="search"
                        placeholder="e.g. www....."
                        role="combobox"
                        aria-haspopup="listbox"
                        aria-owns="rc_select_8_list"
                        aria-autocomplete="list"
                        aria-controls="rc_select_8_list"
                        aria-activedescendant="rc_select_8_list_-1"
                        aria-expanded="false"
                        onkeyup="search();"
                        value={websiteLink}
                        onChange={(event) => {
                          const newError = {
                            ...showErrors, // Assuming existingErrors contains the provided error object
                            website_link: [""], // Modify the error message for the bio field
                          };
                          setShowErrors(newError);
                          setWebsiteLink(event.target.value);
                        }}
                      />
                      {showErrors != null && showErrors.website_link ? (
                        <>
                          <div className="text-danger mt-2">
                            {showErrors.website_link[0]}
                          </div>
                        </>
                      ) : null}
                      <div className="mt-4 mb-2 d-flex align-items-center">
                        Please add your Instagram handle
                      </div>
                      <input
                        type="search"
                        className="ant-select-selection-search-input w-100"
                        id="search"
                        placeholder="e.g. @vitrinelive"
                        role="combobox"
                        aria-haspopup="listbox"
                        aria-owns="rc_select_8_list"
                        aria-autocomplete="list"
                        aria-controls="rc_select_8_list"
                        aria-activedescendant="rc_select_8_list_-1"
                        aria-expanded="false"
                        onkeyup="search();"
                        value={instaLink}
                        onChange={(event) => {
                          const newError = {
                            ...showErrors, // Assuming existingErrors contains the provided error object
                            instagram: [""], // Modify the error message for the bio field
                          };
                          setShowErrors(newError);
                          setInstaLink(event.target.value);
                        }}
                      />
                      {showErrors != null && showErrors.instagram ? (
                        <>
                          <div className="text-danger mt-2">
                            {showErrors.instagram[0]}
                          </div>
                        </>
                      ) : null}
                      <div className="mt-4 mb-2 d-flex align-items-center">
                        Please add your LinkedIn handle
                      </div>
                      <div className="position-relative">
                        <input
                          type="search"
                          className="ant-select-selection-search-input w-100"
                          id="search"
                          placeholder="e.g. https://www.linkedin.com/company/vitrinelive"
                          role="combobox"
                          aria-haspopup="listbox"
                          aria-owns="rc_select_8_list"
                          aria-autocomplete="list"
                          aria-controls="rc_select_8_list"
                          aria-activedescendant="rc_select_8_list_-1"
                          aria-expanded="false"
                          onkeyup="search();"
                          value={linkedinLink}
                          onChange={(event) => {
                            const newError = {
                              ...showErrors, // Assuming existingErrors contains the provided error object
                              linkedIn: [""], // Modify the error message for the bio field
                            };
                            setShowErrors(newError);
                            setLinkedinLink(event.target.value);
                          }}
                        />
                        {showErrors != null && showErrors.linkedIn ? (
                          <>
                            <div className="text-danger mt-2">
                              {showErrors.linkedIn[0]}
                            </div>
                          </>
                        ) : null}
                      </div>
                      <div className="d-flex justify-content-between mt-5">
                        <button className="btn back-btn" onClick={handleBack}>
                          Save for later
                        </button>

                        {step === 2 ? (
                          <>
                            <div className="position-relative">
                              <button
                                disabled={
                                  bio !== "" &&
                                  socialInterest !== "" &&
                                  industry !== "" &&
                                  estDate !== "" &&
                                  profileTags !== ""
                                    ? ""
                                    : "true"
                                }
                                className="btn common-button-style-filled next-btn"
                                onClick={() => {
                                  submitProfileDetails("finish");
                                }}
                              >
                                Finish
                              </button>
                              {bio !== "" &&
                              socialInterest !== "" &&
                              industry !== "" &&
                              estDate !== "" &&
                              profileTags !== "" ? null : (
                                <>
                                  <div className="d-flex align-items-center tooltipIconBtn">
                                    <LightTooltip
                                      placement="right"
                                      title="Must fill out all details to finish"
                                    >
                                      <IconButton>
                                        <InfoIcon width="20" height="20" />
                                      </IconButton>
                                    </LightTooltip>
                                  </div>
                                </>
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            <button
                              className="btn common-button-style-filled next-btn"
                              onClick={handleNext}
                            >
                              Next
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md-5 h-100">
                <div className="campaign-images-sec h-100 position-relative">
                  <div className="d-flex justify-content-end contact-btn-box-outer w-100 mt-3 position-absolute"></div>
                  <div className="inner-curve">
                    <img
                      id="complete-profile-img1"
                      className={`img-fluid sec-img ${
                        step === 1 ? "active-image" : ""
                      }`}
                      src="/assets/images/male_female.svg"
                      alt="img2"
                    />
                    <img
                      id="complete-profile-img2"
                      className={`img-fluid sec-img ${
                        step === 2 ? "active-image" : ""
                      }`}
                      src="/assets/images/girl_talking.svg"
                      alt="img5"
                    />
                  </div>
                  <img
                    className="yellow-circle-ball"
                    src="/assets/images/circle_3.png"
                    alt="circle_3"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* INFO RECEIVED MODAL  */}
          <div
            className="modal fade"
            id="info-received-modal"
            tabIndex={-1}
            aria-labelledby="info-received-modal"
            aria-hidden="true"
          >
            <div className="modal-dialog user-settings-modal">
              <div className="modal-content">
                <div className="modal-body">
                  <div
                    className=" close-btn-box new-lg-close-position"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <img src="/assets/images/close-Icon2.svg" alt="X" />
                  </div>
                  <div className="d-flex justify-content-center mt-4">
                    <img
                      src="/assets/images/info-received.png"
                      alt="Info Received"
                      width={250}
                    />
                  </div>
                  <div className="mt-3 info-heading">Info Received</div>
                  <div className="d-flex justify-content-center">
                    <p className="mx-5 px-2 text-center lh-lg">
                      Thank you for completing your profile. We’ve received your
                      information and will notify you via email once it has been
                      validated.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Custom Industry Modal  */}
          <div
            className="modal fade mt-5 pt-5 "
            id="add-custom-industry-modal"
            tabIndex={-1}
            aria-labelledby="add-custom-industry-modal"
            aria-hidden="true"
          >
            <div className="modal-dialog user-settings-modal">
              <div className="modal-content">
                <div className="modal-body">
                  <div
                    className=" close-btn-box new-lg-close-position"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    id="close-modal"
                  >
                    <img src="/assets/images/close-Icon2.svg" alt="X" />
                  </div>

                  <div className="mt-3 info-heading fs-4">
                    Add an industry that suits your Brand
                  </div>
                  <div className="d-flex justify-content-center my-4">
                    <input
                      type="text"
                      className="ant-select-selection-search-input w-100"
                      placeholder="Add an industry"
                      value={customIndustry}
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          handleAddIndustry();
                        }
                      }}
                      onChange={(e) => {
                        setCustomIndustry(e.target.value);
                      }}
                    />
                  </div>
                  <div className="d-flex justify-content-end">
                    <button
                      onClick={() => {
                        handleAddIndustry();
                      }}
                      className="btn common-button-style-filled"
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default BrandCompleteProfile;

import React, { useEffect, useState } from "react";
import AppLayout from "../../../Components/AppLayout";
import { useNavigate, useLocation } from "react-router-dom";
import "./Packs.css";
import { deletePack, getSinglePackCall } from "../../../Common/Repository";
import { toast } from "react-toastify";
import { CloseIcon } from "../../../Components/Icons";
import { useDispatch, useSelector } from "react-redux";
import { PuffLoader } from "react-spinners";
import { setLoading } from "../../../states/packDetails/packDetailsSlice";
import { resetPackState } from "../../../states/packs/packSlice";
import LightTooltip from "../../../Components/LightToolTip";

const PackDetails = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { user, token } = useSelector((state) => state?.auth);

  const { singlePackDetails, loading } = useSelector((state) => state?.packDetails);

  const [verifyPackId, setVerifyPackId] = useState("");

  const packId = useLocation()?.state?.packId;

  const creatorId = useLocation()?.state?.creatorId;

  const creatorsCollaborationData = useLocation()?.state?.creatorsCollaborationData;

  const [errors, setErrors] = useState([]);

  const handleEditClick = (sendData) => {
    navigate("/pack/edit", { state: sendData });
  };

  const handleBuyPackClick = () => {
    navigate("/stripe-payment", {
      state: {
        pack_id: packId,
        payment_type: "buy_pack",
        collaboration_offer: singlePackDetails
      }
    });
  };

  const handlePackDelete = async () => {
    try {
      if (packId === parseInt(verifyPackId)) {
        const data = {};
        data.pack_proposal_id = packId;
        let response = await deletePack(
          {
            Authorization: `Bearer ${token}`,
          },
          data
        );
        if (response?.status === 200) {
          document.getElementById("pack-delete-modal-close").click();
          navigate("/packs/view");
          toast.success("Pack Deleted Successfully!");
          // TODO: Show success reponse
        }
      }
    } catch (error) {
      toast.error("Failed to delete the pack!");
      setErrors(error?.response?.data?.errors);
    }
  };

  const getSinglePackDetail = async () => {
    if (!packId) {
      return;
    }
    if (singlePackDetails?.id !== packId) {
      dispatch(setLoading(true));
    }
    try {
      let data = {};
      data.pack_proposal_id = packId;
      if (user && user?.role === "2" && creatorId) {
        data.creator_id = creatorId
      }
      dispatch(getSinglePackCall({ token, data }))
    } catch (error) {

    }
  }

  useEffect(() => {
    if (packId && packId !== undefined) {
      getSinglePackDetail();
    }
    dispatch(resetPackState());
  }, []);

  return (
    <AppLayout>
      {loading ? (
        <>
          <div className="p-5 m-5 d-flex justify-content-center">
            <PuffLoader color="#65acae" size={42} />
          </div>
        </>
      ) : (
        <>
          <div className="container container-animation mt-5">
            <div
              onClick={() => {
                if(user?.role == 1){
                  navigate("/packs/view");
                } else if(user?.role == 2){
                  navigate("/creatorDetails");
                }
              }}
              className="btn common-button-style-filled"
            >
              &#x2190; Back
            </div>
            <div className="mt-5 packDetails">
              <div className="packCount">
                Pack #{singlePackDetails?.id} - Details
              </div>
              <div className="backgroundDetails">
                <div className="myRow">
                  <h4 className="packTitle">{singlePackDetails?.title}</h4>
                  <div className="packColumnRight">
                    <div className="smallHeading">
                      ${singlePackDetails?.price} - Per{" "}
                      {singlePackDetails?.object}
                    </div>
                    <div className="smallHeading">
                      Delivery Time: {singlePackDetails?.time_duration} Days
                    </div>
                  </div>
                </div>

                <div className="myRow my-4 justify-content-start">
                  {singlePackDetails?.pack_images &&
                    singlePackDetails?.pack_images?.map((imgData, index) => (
                      <div
                        key={index}
                        className="packImageWrapper"
                        style={{
                          backgroundColor: "#f2f2f2",
                          width: "19vw",
                          height: "19vw",
                          borderRadius: "30px",
                          overflow: "hidden",
                        }}
                      >
                        <img
                          className="packImage"
                          src={`${imgData?.image || imgData?.content || ""}`}
                          alt={`Pack image ${index}`}
                          style={{
                            filter: "blur(5px)",
                            opacity: 0,
                            width: "100%",
                            height: "100%",
                            transition: "opacity 0.5s ease, filter 0.5s ease",
                          }}
                          onLoad={(e) => {
                            e.target.style.filter = "none";
                            e.target.style.opacity = 1;
                          }}
                        />
                      </div>
                    ))}
                </div>


                <div className="myRow">
                  <p className="packContent">
                    {singlePackDetails?.description}
                  </p>
                </div>

                {/* BUY OR EDIT OPTION  */}
                {user?.role === "1" ? (
                  <>
                    <div className="myRow justify-content-between">
                      <button
                        type="button"
                        className="whiteButton deleteButton"
                        data-bs-toggle="modal"
                        data-bs-target="#pack-delete-modal"
                      >
                        Delete
                      </button>
                      <div
                        className="greenButton editButton"
                        onClick={() => {
                          handleEditClick(singlePackDetails);
                        }}
                      >
                        Edit Pack
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="myRow">
                      {creatorsCollaborationData?.some(d => d.brand_id === user?.brand?.id.toString() && d.pack_proposal_id === singlePackDetails?.id.toString()) ? (
                        <LightTooltip placement="right" title="One collaboration is allowed at a time">
                          <button
                            className="greenButton-disabled text-highlight buyButton"
                            onClick={handleBuyPackClick}
                            disabled={true}
                          >
                            Ongoing
                          </button>
                        </LightTooltip>
                      ) : (
                        <button
                          className="greenButton buyButton"
                          onClick={handleBuyPackClick}
                          disabled={false}
                        >
                          Buy This Pack
                        </button>
                      )}
                    </div>

                  </>
                )}

                {/* BUY OR EDIT OPTION  */}

                {/* Delete-Modal */}
                <div
                  className="modal fade"
                  id="pack-delete-modal"
                  tabIndex={-1}
                  aria-labelledby="pack-delete-modal"
                  aria-hidden="true"
                >
                  <div className="modal-dialog pack-delete-modal">
                    <div className="modalContent">
                      <img
                        className="blueBallLeft"
                        src="assets/images/blue_ball.png"
                        alt="empty_circle_2"
                        width={24}
                      />
                      <img
                        className="purpleEmptyCircleRight"
                        src="assets/images/purple_empty_circle.png"
                        alt="empty_circle_2"
                        width={24}
                      />
                      <img
                        className="purplePlusRight"
                        src="assets/images/purple_plus.png"
                        alt="plus"
                        width={16}
                      />
                      <img
                        className="purpleEmptyLeft"
                        src="assets/images/purple_empty_circle.png"
                        alt="empty_circle_2"
                        width={16}
                      />
                      <img
                        className="yellowCircleRight"
                        src="assets/images/yellow_circle.png"
                        alt="yellow_circle"
                        width={16}
                      />
                      <div className="modal-body delete-pack-modal">
                        <div
                          class="closeModalButton"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          id="pack-delete-modal-close"
                        >
                          <CloseIcon className="closeIcon" />
                        </div>
                        <div className="text-center m-4 infoDetails">
                          <div>Are you sure you want to delete the pack?</div>
                          <div>
                            To confirm please enter the pack id:
                            <span className="highlightText">
                              {` ${singlePackDetails?.id} `}
                            </span>{" "}
                            in the text box below...
                          </div>
                        </div>
                        <div className="col-md-12 d-block pb-2">
                          <input
                            type="search"
                            autoComplete="off"
                            className="inputGenericOne"
                            placeholder="Pack ID..."
                            role="combobox"
                            aria-haspopup="listbox"
                            aria-owns="rc_select_8_list"
                            aria-autocomplete="list"
                            aria-controls="rc_select_8_list"
                            aria-activedescendant="rc_select_8_list_-1"
                            id="rc_select_8"
                            aria-expanded="false"
                            value={verifyPackId}
                            onChange={(event) => {
                              setVerifyPackId(event.target.value);
                            }}
                          />
                          {errors ? (
                            <small className="ms-4 text-danger ">
                              {errors}
                            </small>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-md-12 d-block pb-2">
                        <div className="action-btns d-flex w-100 justify-content-between">
                          <div
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            className="whiteButton modalCancelButton"
                          >
                            Cancel
                          </div>
                          <div
                            className="greenButton modalDeleteButton"
                            onClick={() => handlePackDelete()}
                          >
                            Delete
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Delete-Modal-End */}
              </div>
            </div>
          </div>
        </>
      )
      }
    </AppLayout >
  );
};

export default PackDetails;

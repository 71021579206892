import React, { useEffect, useState } from "react";
import AppLayout from "../../Components/AppLayout";
import {
  addCampaign,
  deleteCampaign,
  sendCollaborationCall,
  singleCampaignDetails,
  updateCampaign,
} from "../../Common/Repository";
import { useLocation, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { PuffLoader } from "react-spinners";
import {
  setChangeActiveCollabStatus,
  setLoading,
  updateSingleCampaignData,
} from "../../states/singleCampaign/singleCampaignSlice";
import { InfoIcon, ReviewStar } from "../../Components/Icons";
import LightTooltip from "../../Components/LightToolTip";
import { IconButton } from "@mui/material";

const CampaignDetailsSection = () => {
  const dispatch = useDispatch();

  const campaignId = useLocation()?.state;

  const navigate = useNavigate();

  const { user, token } = useSelector((state) => state.auth);

  const {
    reviewCount,
    averageRating,
    singleCampaignData,
    loading,
    activeCollaborations,
    idealCategoryArray,
    sendRequestLoading,
    sendRequestError,
    pendingCollaborations,
  } = useSelector((state) => state.singleCampaign);

  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setErrors(sendRequestError?.errors);
  }, [sendRequestError]);

  const [description, setDescription] = useState("");

  const [price, setPrice] = useState("");

  const [object, setObject] = useState("");

  const [deliveryTime, setDeliveryTime] = useState("");

  const handleChange = (event, key) => {
    if (key === "description") {
      setDescription(event?.target?.value);
    }
    if (key === "price") {
      setPrice(event?.target?.value);
    }
    if (key === "object") {
      setObject(event?.target?.value);
    }
    if (key === "delivery_time") {
      setDeliveryTime(event?.target?.value);
    }
  };

  const maxWordCount = 500;
  const remainingWords = description.length;

  useEffect(() => {
    if (campaignId && campaignId !== undefined) {
      getSingleCampaignData();
    }
    // eslint-disable-next-line
  }, []);

  const handleNavigateToBrand = () => {
    if (user?.role === 1 && user?.account_verified === 1) {
      navigate("/brandDetails", { state: singleCampaignData?.brand?.id });
    }
  };

  const getSingleCampaignData = async () => {
    if (!campaignId) {
      return;
    }
    try {
      if (singleCampaignData?.id !== campaignId) {
        dispatch(setLoading(true));
      }
      let data = {};
      data.campaign_id = campaignId;
      dispatch(singleCampaignDetails({ token, data }));
    } catch (error) { }
  };

  const handleSendCollaborationRequest = async () => {
    setErrors([]);
    try {
      let formData = {};
      formData.campaign_id = singleCampaignData?.id;
      formData.description = description;
      formData.price = price;
      formData.object = object;
      formData.delivery_time = deliveryTime;
      dispatch(sendCollaborationCall({ token, data: formData }));

      const updatedCollaborationData = [
        {
          ...activeCollaborations[activeCollaborations.length - 1],
          status: "pending",
        },
      ];
      dispatch(setChangeActiveCollabStatus(updatedCollaborationData));
    } catch (error) {
      toast.error(error?.response?.data.error);
    }
  };

  const handleDeleteCampaign = async () => {
    try {
      let formData = {};
      formData.campaign_id = singleCampaignData?.id;

      let response = await deleteCampaign(
        {
          Authorization: `Bearer ${token}`,
        },
        formData
      );
      if (response?.status === 200) {
        navigate("/myCampaigns");
      }
    } catch (error) { }
  };

  const handleUpdateCampaign = async (type) => {
    if (type === "edit") {
      navigate("/edit-campaign", { state: true });
    } else {
      navigate("/edit-campaign");
    }
  };

  const objectData = ["Story", "Reel", "Short Video", "Graphic Post", "Custom"];

  const formatData = (dataString) => {
    try {
      const parsedData = JSON.parse(dataString);
      return parsedData.join(" ");
    } catch (error) {
      return "";
    }
  };

  return (
    <AppLayout>
      {loading ? (
        <>
          <div className="p-5 m-5 d-flex justify-content-center">
            <PuffLoader color="#65acae" size={42} />
          </div>
        </>
      ) : (
        <>
          <div className="main-dashboard position-relative">
            <div className="container container-animation">
              <div
                onClick={() => {
                  if (user?.role == 2) {
                    navigate("/myCampaigns");
                  } else {
                    navigate(-1);
                  }
                }}
                className="btn common-button-style-filled mb-5"
              >
                &#x2190; Back
              </div>
              <img
                className="purple-circle"
                src="/assets/images/circle_top1.png"
                alt="circle_top1"
              />
              <img
                className="yellow-circle"
                src="/assets/images/circle-03.png"
                alt="circle_top1"
              />
              <div className="row campaign-detail-outer-box2">
                <div className="col-md-4 col-12">
                  {singleCampaignData?.campaign_image !== null &&
                    singleCampaignData?.campaign_image !== undefined ? (
                    <img
                      className="img-fluid campaign-detail-image"
                      src={singleCampaignData?.campaign_image.image}
                      alt="avtar-a"
                    />
                  ) : singleCampaignData?.color !== undefined &&
                    singleCampaignData?.color !== null ? (
                    <span
                      className="d-block d-lg-block color-span"
                      style={{
                        backgroundColor: singleCampaignData?.color,
                      }}
                    ></span>
                  ) : (
                    <svg
                      className=" d-none d-lg-block"
                      width="322"
                      height="440"
                      viewBox="0 0 362 401"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_670_150"
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="362"
                        height="386"
                      >
                        <rect width="362" height="386" rx="30.5" fill="white" />
                      </mask>
                      <g mask="url(#mask0_670_150)">
                        <rect width="362" height="400.378" fill="#D9D9D9" />
                      </g>
                      <path
                        d="M163 183V191C163 191.53 163.211 192.039 163.586 192.414C163.961 192.789 164.47 193 165 193H171M171 183V203M191 183V191C191 191.53 191.211 192.039 191.586 192.414C191.961 192.789 192.47 193 193 193H199M199 183V203M177 185V201C177 201.53 177.211 202.039 177.586 202.414C177.961 202.789 178.47 203 179 203H183C183.53 203 184.039 202.789 184.414 202.414C184.789 202.039 185 201.53 185 201V185C185 184.47 184.789 183.961 184.414 183.586C184.039 183.211 183.53 183 183 183H179C178.47 183 177.961 183.211 177.586 183.586C177.211 183.961 177 184.47 177 185Z"
                        stroke="#FE0201"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </div>
                <div className="col-md-8 col-12">
                  <div className="row ">
                    <div className="col-md-12">
                      {user?.role == 2 ? (
                        <>
                          <div className="d-flex justify-content-end">
                            <div
                              className={`d-inline-flex justify-content-center d-flex rounded-pill align-items-center ${singleCampaignData?.is_complete === "0" ||
                                  singleCampaignData?.is_complete === 0 ||
                                  singleCampaignData?.is_complete === null
                                  ? "draft-status-tag"
                                  : singleCampaignData?.is_complete === "1" ||
                                    singleCampaignData?.is_complete === 1
                                    ? singleCampaignData?.campaign_verified ===
                                      "0" ||
                                      singleCampaignData?.campaign_verified === 0
                                      ? "inactive-status-tag"
                                      : singleCampaignData?.campaign_verified ===
                                        "1" ||
                                        singleCampaignData?.campaign_verified ===
                                        1
                                        ? "active-status-tag"
                                        : "draft-status-tag"
                                    : "draft-status-tag"
                                } `}
                            >
                              <span className="me-2 align-center">
                                <div
                                  className={
                                    singleCampaignData?.is_complete === "0" ||
                                      singleCampaignData?.is_complete === null ||
                                      singleCampaignData?.is_complete === 0
                                      ? "dot-draft"
                                      : singleCampaignData?.is_complete ===
                                        "1" ||
                                        singleCampaignData?.is_complete === 1
                                        ? singleCampaignData?.campaign_verified ===
                                          "0" ||
                                          singleCampaignData?.campaign_verified ===
                                          0
                                          ? "dot-in-active"
                                          : singleCampaignData?.campaign_verified ===
                                            "1" ||
                                            singleCampaignData?.campaign_verified ===
                                            1
                                            ? "dot-active"
                                            : "dot-draft"
                                        : "dot-draft"
                                  }
                                ></div>
                              </span>
                              {singleCampaignData?.is_complete === "0" ||
                                singleCampaignData?.is_complete === 0 ||
                                singleCampaignData?.is_complete === null
                                ? "Draft"
                                : singleCampaignData?.is_complete === "1" ||
                                  singleCampaignData?.is_complete === 1
                                  ? singleCampaignData?.campaign_verified ===
                                    "0" ||
                                    singleCampaignData?.campaign_verified === 0
                                    ? "Under review"
                                    : singleCampaignData?.campaign_verified ===
                                      "1" ||
                                      singleCampaignData?.campaign_verified === 1
                                      ? "Active"
                                      : "Draft"
                                  : "Draft"}
                            </div>
                            {(singleCampaignData?.is_complete === "1" ||
                              singleCampaignData?.is_complete === 1) &&
                              (singleCampaignData?.campaign_verified === "0" ||
                                singleCampaignData?.campaign_verified === 0) ? (
                              <div className="d-inline-flex align-items-center ms-1">
                                <LightTooltip
                                  placement="right"
                                  title="Your Campaign will be active once approved by Vitrine."
                                >
                                  <IconButton>
                                    <InfoIcon width="20" height="20" />
                                  </IconButton>
                                </LightTooltip>
                              </div>
                            ) : null}
                          </div>
                        </>
                      ) : null}

                      <div className="text-box">
                        <h5>{singleCampaignData?.title ?? ""}</h5>
                      </div>
                    </div>
                    <div className="col-md-12 py-3">
                      <div className="row">
                        <div className="d-flex col-md-6">
                          <div
                            onClick={() => handleNavigateToBrand()}
                            className="d-flex align-items-center"
                          >
                            {singleCampaignData?.brand?.profile_image !==
                              null &&
                              singleCampaignData?.brand?.profile_image !==
                              undefined ? (
                              <img
                                className="img-fluid profile-image-sm"
                                src={singleCampaignData?.brand?.profile_image}
                                alt="avtar-a"
                              />
                            ) : (
                              // <CircleImageNFound width={100} height={100} />
                              <img
                                className="brand-profile-image"
                                src="/assets/images/user.png"
                                alt="avtar-a"
                              />
                            )}
                            <div className="mt-3 ms-3 text-muted my-auto">
                              <p className="link-style">
                                {singleCampaignData?.brand?.brand_name}
                              </p>
                            </div>
                          </div>
                        </div>
                        {user?.role === "1" ? (
                          <>
                            <div className="reviewStars col-md-8">
                              <ReviewStar fill="#5ba1a3" className="me-1" />
                              {averageRating !== null &&
                                averageRating?.length > 2
                                ? averageRating?.toString().substring(0, 3)
                                : averageRating !== null
                                  ? averageRating.toFixed(1)
                                  : "0"}
                              ({reviewCount ?? "0"})
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-12 ">
                      <div className="text-box2">
                        <div className="d-flex campaign-text-box mobile-col">
                          <p className="mb-2">
                            Updated:&nbsp;
                            <span>
                              {singleCampaignData?.created_at?.split("T")[0]}
                            </span>
                          </p>
                          <p className="ms-md-3 mb-md-0 mb-4">
                            {singleCampaignData?.collaboration_count ?? "0"}
                            &nbsp;collaboration proposals
                          </p>
                        </div>
                        <p>
                          Response time within&nbsp;
                          {`${singleCampaignData?.time_limit ?? "-"} Days`}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-12 ">
                      <div className="text-muted mb-2 align-items-center">
                        <p className="m-0 ms-md-1">
                          {singleCampaignData?.location === "world-wide" ||
                            singleCampaignData?.location === "worldwide"
                            ? "Campaign available worldwide"
                            : singleCampaignData?.location !== null
                              ? singleCampaignData?.location
                              : "Complete the Campaign"}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-12 ">
                      {user?.role === "1" ? (
                        <>
                          {(pendingCollaborations &&
                            pendingCollaborations[
                              pendingCollaborations.length - 1
                            ]?.campaign_id ===
                            singleCampaignData?.id?.toString()) ||
                            (activeCollaborations &&
                              activeCollaborations[
                                activeCollaborations.length - 1
                              ]?.campaign_id ===
                              singleCampaignData?.id?.toString()) ? (
                            <>
                              <button
                                onClick={() => {
                                  setErrors([]);
                                  setDeliveryTime("");
                                  setPrice("");
                                  setDescription("");
                                  setObject("");
                                }}
                                data-bs-toggle="modal"
                                data-bs-target="#collaboration-proposal-modal"
                                className={
                                  (pendingCollaborations &&
                                    pendingCollaborations[
                                      pendingCollaborations.length - 1
                                    ]?.campaign_id ===
                                    singleCampaignData?.id?.toString()) ||
                                    (activeCollaborations &&
                                      activeCollaborations[
                                        activeCollaborations.length - 1
                                      ]?.campaign_id ===
                                      singleCampaignData?.id?.toString())
                                    ? "common-button-style-disabled text-highlight mt-3"
                                    : "common-button-style-filled mt-3"
                                }
                                disabled={
                                  (pendingCollaborations &&
                                    pendingCollaborations.length) ||
                                  (activeCollaborations &&
                                    activeCollaborations.length)
                                }
                              >
                                {pendingCollaborations &&
                                  pendingCollaborations[
                                    pendingCollaborations.length - 1
                                  ]?.campaign_id ===
                                  singleCampaignData?.id?.toString()
                                  ? "Collaboration Proposal Sent"
                                  : activeCollaborations &&
                                    activeCollaborations[
                                      activeCollaborations.length - 1
                                    ]?.campaign_id ===
                                    singleCampaignData?.id?.toString()
                                    ? "Active Collaboration"
                                    : "Make Collaboration Proposal"}
                              </button>
                            </>
                          ) : pendingCollaborations?.length === 0 &&
                            activeCollaborations?.length === 0 ? (
                            <button
                              onClick={() => {
                                setErrors([]);
                                setDeliveryTime("");
                                setPrice("");
                                setDescription("");
                                setObject("");
                              }}
                              data-bs-toggle="modal"
                              data-bs-target="#collaboration-proposal-modal"
                              className="common-button-style-filled mt-3"
                            >
                              Make Collaboration Proposal
                            </button>
                          ) : (
                            <LightTooltip
                              placement="right"
                              title="One collaboration is allowed at a time with each Brand"
                            >
                              <button
                                onClick={() => {
                                  setErrors([]);
                                  setDeliveryTime("");
                                  setPrice("");
                                  setDescription("");
                                  setObject("");
                                }}
                                data-bs-toggle="modal"
                                data-bs-target="#collaboration-proposal-modal"
                                className={
                                  (pendingCollaborations &&
                                    pendingCollaborations[
                                      pendingCollaborations.length - 1
                                    ]?.campaign_id ===
                                    singleCampaignData?.id?.toString()) ||
                                    (activeCollaborations &&
                                      activeCollaborations[
                                        activeCollaborations.length - 1
                                      ]?.campaign_id ===
                                      singleCampaignData?.id?.toString()) ||
                                    (activeCollaborations &&
                                      activeCollaborations[
                                        activeCollaborations.length - 1
                                      ]?.status === "pending")
                                    ? "common-button-style-disabled text-highlight mt-3"
                                    : "common-button-style-filled mt-3"
                                }
                                disabled={
                                  (pendingCollaborations &&
                                    pendingCollaborations.length) ||
                                  (activeCollaborations &&
                                    activeCollaborations.length)
                                }
                              >
                                {(pendingCollaborations &&
                                  pendingCollaborations[
                                    pendingCollaborations.length - 1
                                  ]?.campaign_id ===
                                  singleCampaignData?.id?.toString()) ||
                                  (activeCollaborations &&
                                    activeCollaborations[
                                      activeCollaborations.length - 1
                                    ]?.status === "pending")
                                  ? "Collaboration Proposal Sent"
                                  : (activeCollaborations &&
                                    activeCollaborations[
                                      activeCollaborations.length - 1
                                    ]?.campaign_id ===
                                    singleCampaignData?.id?.toString()) ||
                                    (activeCollaborations &&
                                      activeCollaborations[
                                        activeCollaborations.length - 1
                                      ]?.status === "active")
                                    ? "Active Collaboration"
                                    : "Make Collaboration Proposal"}
                              </button>
                            </LightTooltip>
                          )}
                        </>
                      ) : (
                        <>
                          {singleCampaignData?.is_complete === "1" ||
                            singleCampaignData?.is_complete === 1 ? (
                            <>
                              <button
                                data-bs-toggle="modal"
                                data-bs-target="#edit-campaign-modal"
                                className="common-button-style-filled w-40 mt-3 wm-100"
                              >
                                Edit Campaign
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                onClick={() => {
                                  handleUpdateCampaign("complete");
                                }}
                                className="btn common-button-style-filled me-md-3 wm-100"
                                type="button"
                              >
                                <div className="button-text">
                                  Complete Campaign
                                </div>
                                <img
                                  src="/assets/images/btn-arrow.svg"
                                  alt="->"
                                  className="btn-icon"
                                />
                              </button>
                            </>
                          )}

                          <button
                            data-bs-toggle="modal"
                            data-bs-target="#delete-campaign-modal"
                            className="delete-btn w-40 mt-3 margin-left wm-100"
                          // onClick={handleDeleteCampaign}
                          >
                            Delete Campaign
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="campaign-detail-outer-box2 row mt-5">
                <div className="col-md-4 campaign-offered-box1 border-end">
                  <img
                    className="campaign-detail-img"
                    src="/assets/images/campaign-detail-main-img.png"
                    alt="avtar-a"
                  />
                  <h6>What's offered in this campaign</h6>
                  <div className="row">
                    <div className="col-md-12 ">
                      <div className="d-flex icon-box mb-3 align-items-center">
                        <p className="m-0 p-text ms-1">
                          Campaign incentive:{" "}
                          <span className="fw-bold">
                            {singleCampaignData?.campaign_incentive !== null
                              ? singleCampaignData?.campaign_incentive
                                ?.charAt(0)
                                ?.toUpperCase() +
                              singleCampaignData?.campaign_incentive?.slice(1)
                              : "N/A"}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-md-12 ">
                      <div className="hidden-div d-flex icon-box mb-3 align-items-center">
                        <svg
                          className="me-2"
                          width={18}
                          height={18}
                          x={0}
                          y={0}
                          viewBox="0 0 682.667 682.667"
                          style={{ enableBackground: "new 0 0 512 512" }}
                          xmlSpace="preserve"
                        >
                          <g>
                            <defs>
                              <clipPath id="a" clipPathUnits="userSpaceOnUse">
                                <path
                                  d="M0 512h512V0H0Z"
                                  fill="#000000"
                                  data-original="#000000"
                                />
                              </clipPath>
                            </defs>
                            <g
                              clipPath="url(#a)"
                              transform="matrix(1.33333 0 0 -1.33333 0 682.667)"
                            >
                              <path
                                d="M0 0v73.918m0-190.117v80.665M71.618 73.918v-190.117"
                                style={{
                                  strokeWidth: 15,
                                  strokeLinecap: "round",
                                  strokeLinejoin: "round",
                                  strokeMiterlimit: 10,
                                  strokeDasharray: "none",
                                  strokeOpacity: 1,
                                }}
                                transform="translate(221.54 128.199)"
                                fill="none"
                                stroke="#000000"
                                strokeWidth={15}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit={10}
                                strokeDasharray="none"
                                data-original="#000000"
                              />
                              <path
                                d="m0 0 252.176 140.538c7.069 3.954 9.629 12.94 5.701 20.005l-15.213 27.277c-3.956 7.066-12.945 9.625-20.014 5.67L-29.555 52.952c-7.04-3.926-9.6-12.911-5.673-19.977L-20.014 5.67C-16.086-1.367-7.069-3.926 0 0Z"
                                style={{
                                  strokeWidth: 15,
                                  strokeLinecap: "round",
                                  strokeLinejoin: "round",
                                  strokeMiterlimit: 10,
                                  strokeDasharray: "none",
                                  strokeOpacity: 1,
                                }}
                                transform="translate(52.647 132.532)"
                                fill="none"
                                stroke="#000000"
                                strokeWidth={15}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit={10}
                                strokeDasharray="none"
                                data-original="#000000"
                              />
                              <path
                                d="m0 0 29.526-52.953m-80.781-45.013-29.526 52.952"
                                style={{
                                  strokeWidth: 15,
                                  strokeLinecap: "round",
                                  strokeLinejoin: "round",
                                  strokeMiterlimit: 10,
                                  strokeDasharray: "none",
                                  strokeOpacity: 1,
                                }}
                                transform="translate(189.6 278.274)"
                                fill="none"
                                stroke="#000000"
                                strokeWidth={15}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit={10}
                                strokeDasharray="none"
                                data-original="#000000"
                              />
                              <path
                                d="M0 0c-139.687-16.575-91.486 156.706 27.606 63.421"
                                style={{
                                  strokeWidth: 15,
                                  strokeLinecap: "round",
                                  strokeLinejoin: "round",
                                  strokeMiterlimit: 10,
                                  strokeDasharray: "none",
                                  strokeOpacity: 1,
                                }}
                                transform="translate(92.79 224.304)"
                                fill="none"
                                stroke="#000000"
                                strokeWidth={15}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit={10}
                                strokeDasharray="none"
                                data-original="#000000"
                              />
                              <path
                                d="M0 0h214.156c5.876 0 10.705-4.827 10.705-10.701v-160.282c0-10.527-8.61-19.134-19.141-19.134H-45.089c-10.53 0-19.14 8.607-19.14 19.134v134.925"
                                style={{
                                  strokeWidth: 15,
                                  strokeLinecap: "round",
                                  strokeLinejoin: "round",
                                  strokeMiterlimit: 10,
                                  strokeDasharray: "none",
                                  strokeOpacity: 1,
                                }}
                                transform="translate(177.033 202.117)"
                                fill="none"
                                stroke="#000000"
                                strokeWidth={15}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit={10}
                                strokeDasharray="none"
                                data-original="#000000"
                              />
                              <path
                                d="m0 0 80.781 45.014-10.21 18.291c-5.614 10.09-18.472 13.725-28.537 8.112l-44.128-24.6c-10.094-5.612-13.731-18.436-8.116-28.497Z"
                                style={{
                                  strokeWidth: 15,
                                  strokeLinecap: "round",
                                  strokeLinejoin: "round",
                                  strokeMiterlimit: 10,
                                  strokeDasharray: "none",
                                  strokeOpacity: 1,
                                }}
                                transform="translate(108.818 233.26)"
                                fill="none"
                                stroke="#000000"
                                strokeWidth={15}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit={10}
                                strokeDasharray="none"
                                data-original="#000000"
                              />
                              <path
                                d="M0 0c-12.072 4.391-21.352 6.688-37.555 8.462"
                                style={{
                                  strokeWidth: 15,
                                  strokeLinecap: "round",
                                  strokeLinejoin: "round",
                                  strokeMiterlimit: 10,
                                  strokeDasharray: "none",
                                  strokeOpacity: 1,
                                }}
                                transform="translate(96.048 263.444)"
                                fill="none"
                                stroke="#000000"
                                strokeWidth={15}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit={10}
                                strokeDasharray="none"
                                data-original="#000000"
                              />
                              <path
                                d="M0 0c-8.785-15.179-13.41-37.715-9.949-68.8m68.477-9.858C115.456-7.095 62.28 39.14 22.748 21.373"
                                style={{
                                  strokeWidth: 15,
                                  strokeLinecap: "round",
                                  strokeLinejoin: "round",
                                  strokeMiterlimit: 10,
                                  strokeDasharray: "none",
                                  strokeOpacity: 1,
                                }}
                                transform="translate(148.002 366.354)"
                                fill="none"
                                stroke="#000000"
                                strokeWidth={15}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit={10}
                                strokeDasharray="none"
                                data-original="#000000"
                              />
                              <path
                                d="M0 0c.843 10.643 6.021 26.666 12.538 37.367"
                                style={{
                                  strokeWidth: 15,
                                  strokeLinecap: "round",
                                  strokeLinejoin: "round",
                                  strokeMiterlimit: 10,
                                  strokeDasharray: "none",
                                  strokeOpacity: 1,
                                }}
                                transform="translate(171.506 304.532)"
                                fill="none"
                                stroke="#000000"
                                strokeWidth={15}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit={10}
                                strokeDasharray="none"
                                data-original="#000000"
                              />
                              <path
                                d="M0 0s10.356-95.611-24.435-133.326"
                                style={{
                                  strokeWidth: 15,
                                  strokeLinecap: "round",
                                  strokeLinejoin: "round",
                                  strokeMiterlimit: 10,
                                  strokeDasharray: "none",
                                  strokeOpacity: 1,
                                }}
                                transform="translate(371.147 378.45)"
                                fill="none"
                                stroke="#000000"
                                strokeWidth={15}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit={10}
                                strokeDasharray="none"
                                data-original="#000000"
                              />
                              <path
                                d="M0 0s15.01 66.125 81.77 82.06"
                                style={{
                                  strokeWidth: 15,
                                  strokeLinecap: "round",
                                  strokeLinejoin: "round",
                                  strokeMiterlimit: 10,
                                  strokeDasharray: "none",
                                  strokeOpacity: 1,
                                }}
                                transform="translate(422.723 349.634)"
                                fill="none"
                                stroke="#000000"
                                strokeWidth={15}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit={10}
                                strokeDasharray="none"
                                data-original="#000000"
                              />
                              <path
                                d="m0 0-11.548-19.279c-1.251-2.094-1.426-2.792-4.015-3.431l-22.864-5.671c-4.422-1.105-4.654-2.122-1.629-5.554l14.748-17.738c1.571-1.89 1.804-4.1 1.6-6.775l-1.774-21.926c-.495-4.536.465-5.088 4.712-3.373l20.101 8.375c2.095.872 5.382.872 7.447 0l20.13-8.375c4.247-1.715 5.178-1.163 4.683 3.373l-1.745 21.926c-.203 2.675 0 4.885 1.6 6.775l14.719 17.738c3.025 3.432 2.822 4.449-1.629 5.554L21.672-22.71c-2.589.639-2.735 1.337-4.014 3.431L6.109 0C3.782 3.926 2.356 3.926 0 0Z"
                                style={{
                                  strokeWidth: 15,
                                  strokeLinecap: "round",
                                  strokeLinejoin: "round",
                                  strokeMiterlimit: 10,
                                  strokeDasharray: "none",
                                  strokeOpacity: 1,
                                }}
                                transform="translate(309.623 486.333)"
                                fill="none"
                                stroke="#000000"
                                strokeWidth={15}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit={10}
                                strokeDasharray="none"
                                data-original="#000000"
                              />
                              <path
                                d="m0 0-11.578-19.279c-1.251-2.094-1.425-2.792-3.985-3.431l-22.864-5.671c-4.451-1.105-4.683-2.122-1.629-5.554l14.719-17.738c1.6-1.89 1.804-4.1 1.6-6.775l-1.774-21.897c-.466-4.565.465-5.117 4.712-3.402l20.101 8.375c2.094.872 5.381.872 7.476 0l20.1-8.375c4.247-1.715 5.179-1.163 4.713 3.402l-1.775 21.897c-.203 2.675 0 4.885 1.601 6.775l14.719 17.738c3.054 3.432 2.822 4.449-1.629 5.554L21.643-22.71c-2.56.639-2.735 1.337-3.986 3.431L6.109 0C3.752 3.926 2.327 3.926 0 0Z"
                                style={{
                                  strokeWidth: 15,
                                  strokeLinecap: "round",
                                  strokeLinejoin: "round",
                                  strokeMiterlimit: 10,
                                  strokeDasharray: "none",
                                  strokeOpacity: 1,
                                }}
                                transform="translate(456.32 313.925)"
                                fill="none"
                                stroke="#000000"
                                strokeWidth={15}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit={10}
                                strokeDasharray="none"
                                data-original="#000000"
                              />
                              <path
                                d="M0 0c16.552 0 30.021 13.464 30.021 29.98 0 16.546-13.469 30.01-30.021 30.01-16.523 0-30.02-13.464-30.02-30.01C-30.02 13.464-16.523 0 0 0Z"
                                style={{
                                  strokeWidth: 15,
                                  strokeLinecap: "round",
                                  strokeLinejoin: "round",
                                  strokeMiterlimit: 10,
                                  strokeDasharray: "none",
                                  strokeOpacity: 1,
                                }}
                                transform="translate(432.002 440.01)"
                                fill="none"
                                stroke="#000000"
                                strokeWidth={15}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeMiterlimit={10}
                                strokeDasharray="none"
                                data-original="#000000"
                              />
                            </g>
                          </g>
                        </svg>
                        <p className="m-0 p-text ms-1">$1678 Gift value</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 campaign-offered-box1 ">
                  <h6>Creator requirements</h6>
                  <div className="row">
                    <div className="col-md-6 ">
                      <div className="d-flex icon-box mb-3 align-items-center">
                        <p className="m-0 p-text ms-1">
                          Location:{" "}
                          <span className="fw-bold">
                            {singleCampaignData?.location === "world-wide" ||
                              singleCampaignData?.location === "worldwide"
                              ? "Any location"
                              : singleCampaignData?.location !== null
                                ? singleCampaignData?.location
                                : "N/A "}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6 ">
                      <div className="d-flex icon-box mb-3 align-items-center">
                        <p className="m-0 p-text ms-1">
                          Minimum engagement:{" "}
                          <span className="fw-bold">
                            {singleCampaignData?.engagement_rate
                              ? `${singleCampaignData.engagement_rate}%`
                              : "N/A"}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6 ">
                      <div className="d-flex icon-box mb-3 align-items-center">
                        <p className="m-0 p-text ms-1">
                          Minimum followers:{" "}
                          <span className="fw-bold">
                            {singleCampaignData?.minimum_follower !== null &&
                              singleCampaignData?.minimum_follower !== undefined
                              ? `${singleCampaignData.minimum_follower}k`
                              : "N/A"}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 campaign-offered-box1"></div>

                <div className="col-md-12 campaign-offered-box1 mt-5">
                  <div className="row campaign-desc-box">
                    <div className="col-md-6">
                      <h6 className="my-auto">Campaign description</h6>
                    </div>
                    <div className="col-md-6">
                      <button className="my-auto category-btn">
                        <div className="d-flex">
                          <b>Ideal category:&nbsp;</b>
                          {formatData(idealCategoryArray)}
                        </div>
                      </button>
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-12 mt-md-5 mt-2">
                      <div className="desc-text text-muted">
                        {singleCampaignData?.description ??
                          "No information provided!"}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 border-top campaign-offered-box1 mt-5 pt-5">
                  <div>
                    <h6>Collaboration details</h6>
                    <p>
                      Target URL:{" "}
                      <span className="fw-bold">
                        {singleCampaignData?.collabrations_url ??
                          "No Link Available"}
                      </span>
                    </p>
                    <p>
                      Hashtags to include:{" "}
                      <span className="fw-bold">
                        {" "}
                        {singleCampaignData?.hashtag
                          ? formatData(singleCampaignData?.hashtag)
                          : "N/A"}{" "}
                      </span>
                    </p>
                    <p>
                      User names or profiles to include:{" "}
                      <span className="fw-bold">
                        {singleCampaignData?.mention
                          ? formatData(singleCampaignData?.mention)
                          : "N/A"}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {/* COLLABORATION PROPOSAL MODAL START  */}
      <div
        className="modal fade"
        id="collaboration-proposal-modal"
        tabIndex={-1}
        aria-labelledby="collaboration-proposal-modal"
        aria-hidden="true"
      >
        <div className="modal-dialog collaboration-proposal-modal">
          <div className="modal-content">
            <div className="modal-body p-5">
              {/* <div
                className=" close-btn-box new-lg-close-position"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setDeliveryTime("");
                  setDescription("");
                  setPrice("");
                  setObject("");
                }}
              >
                <img src="/assets/images/close-Icon2.svg" alt="X" />
              </div> */}
              <div className="position-relative">
                <textarea
                  maxLength={maxWordCount}
                  placeholder="Description"
                  className="custom-offer-textarea"
                  name="description"
                  value={description}
                  onChange={(event) => {
                    handleChange(event, "description");
                  }}
                />
                <div className="view-offer-count-position">
                  <p> {remainingWords}/500 Characters</p>
                </div>
                {errors && errors?.description ? (
                  <>
                    <div className="errorText">{errors?.description}</div>
                  </>
                ) : null}
              </div>
              <div className="d-flex justify-content-between">
                <div className="col-5 mt-4">
                  <div className=" position-relative">
                    <input
                      maxLength={6}
                      type="tel"
                      placeholder="Price"
                      className="custom-input"
                      value={price}
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        const numericValue = inputValue.replace(/[^0-9]/g, "");
                        setPrice(numericValue);
                      }}
                    />
                    {errors && errors?.price ? (
                      <>
                        <div className="errorText my-1">{errors?.price}</div>
                      </>
                    ) : null}
                    <div className="collaboration-proposal-dollar-icon">
                      <img src="/assets/images/$.png" alt="$" />
                    </div>
                  </div>
                </div>
                <div className="pt-3 justify-content-center d-flex-col">
                  Per
                </div>
                <div className="col-5 mt-4">
                  <select
                    className="custom-input px-3"
                    value={object}
                    onChange={(event) => {
                      setObject(event.target.value);
                    }}
                  >
                    <option value="" disabled>
                      Select Object
                    </option>
                    {objectData.map((object, index) => (
                      <option key={index} value={object}>
                        {object}
                      </option>
                    ))}
                  </select>
                  {errors && errors?.object ? (
                    <>
                      <div className="errorText my-1">{errors?.object}</div>
                    </>
                  ) : null}
                </div>
              </div>
              <div className="col-12 position-relative mt-3">
                <p className="mb-1 smallText">
                  Enter the delivery date, by which you need this work done.
                </p>
                <input
                  type="date"
                  placeholder="Delivery Time"
                  className="custom-input pe-3"
                  value={deliveryTime}
                  min={new Date().toISOString().split("T")[0]}
                  onChange={(event) => {
                    handleChange(event, "delivery_time");
                  }}
                  onKeyUp={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      handleSendCollaborationRequest();
                    }
                  }}
                />
                {errors && errors?.delivery_time ? (
                  <>
                    <div className="errorText my-1">
                      {errors?.delivery_time}
                    </div>
                  </>
                ) : null}
                <img
                  className="popup-yellow-brand-circle position-absolute"
                  src="assets/images/yellow_circle.png"
                  alt="circle_3"
                  width={16}
                />
              </div>
              <div className="d-flex justify-content-between mt-4">
                <div className="action-btns d-flex w-100 justify-content-between">
                  <div
                    id="collaboration-proposal-modal-close"
                    className="common-button-style-outline w-25 align-center"
                    onClick={() => {
                      setDeliveryTime("");
                      setDescription("");
                      setPrice("");
                      setObject("");
                      setErrors([]);
                    }}
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Discard
                  </div>

                  <div
                    className="common-button-style-filled w-25 align-center"
                    onClick={() => {
                      handleSendCollaborationRequest();
                    }}
                  >
                    {sendRequestLoading ? (
                      <>
                        <PuffLoader color="white" size={18} />
                      </>
                    ) : (
                      "Send"
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* COLLABORATION PROPOSAL MODAL END  */}

      {/* EDIT CAMPAIGN CONFIRMATION MODAL */}
      <div
        className="modal fade mt-5 pt-5"
        id="edit-campaign-modal"
        tabIndex={-1}
        aria-labelledby="edit-campaign-modal"
        aria-hidden="true"
      >
        <div className="modal-dialog user-settings-modal edit-campaign">
          <div className="modal-content">
            <div className="modal-body p-5">
              <img
                className="blue-ball-popup"
                src="/assets/images/blue_ball.png"
                alt="blue-ball"
                width={25}
              />
              <img
                className="popup-yellow-circle"
                src="assets/images/yellow_circle.png"
                alt="circle_3"
                width={16}
              />
              <img
                className="purple-empty-circle-popup"
                src="assets/images/purple_empty_circle.png"
                alt="empty_circle"
                width={20}
              />
              <img
                className="purple-plus-popup"
                src="assets/images/purple_plus.png"
                alt="plus"
                width={16}
              />
              <img
                className="purple-empty-circle-2-popup position-absolute"
                src="assets/images/purple_empty_circle.png"
                alt="empty_circle_2"
                width={16}
              />
              <div
                className=" close-btn-box new-lg-close-position"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <img src="/assets/images/close-Icon2.svg" alt="X" />
              </div>
              <p className="m-5 title">
                Are you sure you want to edit the campaign? If you edit, your
                campaign will be reviewed again before going live
              </p>
            </div>

            <div className="modal-footer mb-5">
              <div className="row  justify-content-between mx-5">
                <div className="col-5">
                  <div
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    className="common-button-style-outline align-center"
                  >
                    Cancel
                  </div>
                </div>
                <div className="col-5">
                  <div
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    className="common-button-style-filled align-center"
                    onClick={() => {
                      handleUpdateCampaign("edit");
                    }}
                  >
                    Proceed
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* EDIT CAMPAIGN CONFIRMATION MODAL */}

      {/* DELETE CAMPAIGN CONFIRMATION MODAL */}
      <div
        className="modal fade mt-5 pt-5"
        id="delete-campaign-modal"
        tabIndex={-1}
        aria-labelledby="delete-campaign-modal"
        aria-hidden="true"
      >
        <div className="modal-dialog user-settings-modal edit-campaign">
          <div className="modal-content">
            <div className="modal-body p-5">
              <img
                className="blue-ball-popup"
                src="/assets/images/blue_ball.png"
                alt="blue-ball"
                width={25}
              />
              <img
                className="popup-yellow-circle"
                src="assets/images/yellow_circle.png"
                alt="circle_3"
                width={16}
              />
              <img
                className="purple-empty-circle-popup"
                src="assets/images/purple_empty_circle.png"
                alt="empty_circle"
                width={20}
              />
              <img
                className="purple-plus-popup"
                src="assets/images/purple_plus.png"
                alt="plus"
                width={16}
              />
              <img
                className="purple-empty-circle-2-popup position-absolute"
                src="assets/images/purple_empty_circle.png"
                alt="empty_circle_2"
                width={16}
              />
              <div
                className=" close-btn-box new-lg-close-position"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <img src="/assets/images/close-Icon2.svg" alt="X" />
              </div>
              <p className="m-3 title">
                Are you sure you want to delete the campaign? Once deleted,this
                can't be undone.
              </p>
            </div>
            <div className="d-flex mb-5">
              <div className="m-auto link-style">
                <div
                  className="ms-5"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </div>
              </div>
              <div className="mx-auto">
                <button
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleDeleteCampaign}
                  className="delete-btn common-button-style-filled "
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* DELETE CAMPAIGN CONFIRMATION MODAL */}
    </AppLayout>
  );
};

export default CampaignDetailsSection;

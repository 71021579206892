import React, { useEffect, useState } from "react";
import {
  acceptCollaborationCall,
  getMyPendingCollaborationsCall,
  rejectCollaborationCall,
} from "../Common/Repository";
import { useDispatch, useSelector } from "react-redux";
import { PuffLoader } from "react-spinners";
import { setRequestsList } from "../states/pendingRequests/pendingRequestsSlice";
import { useNavigate } from "react-router-dom";
import { setTotalUnreadMessagesCount } from "../states/chat/chatSlice";

const ChatRequest = ({ page }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { user, token } = useSelector((state) => state.auth);

  const [listToShow, setListToShow] = useState([]);

  const { requestsList, loading } = useSelector(
    (state) => state.pendingRequests
  );

  const filteredReceivedRequests = requestsList?.filter((request) => {
    const userRole = user?.role;
    if (
      (userRole === 1 || userRole === "1") &&
      request?.collaboration_owner === "brand"
    ) {
      return true;
    } else if (
      (userRole === 2 || userRole === "2") &&
      request?.collaboration_owner === "creator"
    ) {
      return true;
    }
    return false;
  });

  const filteredSentRequests = requestsList?.filter((request) => {
    const userRole = user?.role;
    if (
      (userRole === 1 || userRole === "1") &&
      request?.collaboration_owner === "creator"
    ) {
      return true;
    } else if (
      (userRole === 2 || userRole === "2") &&
      request?.collaboration_owner === "brand"
    ) {
      return true;
    }
    return false;
  });

  useEffect(() => {
    if (page === "Requests-Sent") {
      setListToShow(filteredSentRequests);
    } else {
      setListToShow(filteredReceivedRequests);
    }
    // eslint-disable-next-line
  }, [page, requestsList]);

  const [offerDetails, setOfferDetails] = useState([]);

  const actions = ["Accept", "Deny", "View"];

  const requestListData = {
    listTitle: [
      user?.role === "2" ? "Creator" : "Brand",
      user?.role === "2" ? "Followers" : null,
      "Price",
      "Location",
      user?.role === "2" ? "Gender" : null,
      "Action",
    ],
  };

  const actionHandler = (action, requestData) => {
    if (action === "Accept") {
      acceptCollaboration(requestData?.id);
    }
    if (action === "Deny") {
      rejectCollaboration(requestData?.id);
    }
    if (action === "View") {
      setOfferDetails(requestData?.offers);
    }
  };

  useEffect(() => {
    getPendingRequests();
    // eslint-disable-next-line
  }, []);

  const getPendingRequests = async () => {
    try {
      let data = {};
      data.status = "pending";
      dispatch(getMyPendingCollaborationsCall({ token, data, user }));
    } catch (error) {}
  };

  const rejectCollaboration = async (collaborationId) => {
    try {
      let data = {};
      data.status = "rejected";
      data.collaboration_id = collaborationId;
      let response = await rejectCollaborationCall(
        {
          Authorization: `Bearer ${token}`,
        },
        data
      );
      if (response) {
        dispatch(setTotalUnreadMessagesCount());
        dispatch(
          setRequestsList(
            requestsList.filter(
              (pr) => pr.id.toString() !== collaborationId.toString()
            )
          )
        );
        navigate("/creatorChat", {
          state: {
            page: "Inbox",
          },
        });
      }
    } catch (error) {}
  };

  const acceptCollaboration = async (collaborationId) => {
    try {
      let data = {};
      data.status = "approved";
      data.collaboration_id = collaborationId;
      let response = await acceptCollaborationCall(
        {
          Authorization: `Bearer ${token}`,
        },
        data
      );
      if (response) {
        dispatch(setTotalUnreadMessagesCount());
        dispatch(
          setRequestsList(
            requestsList.filter(
              (pr) => pr.id.toString() !== collaborationId.toString()
            )
          )
        );
        navigate("/creatorChat", {
          state: {
            page: "Inbox",
          },
        });
      }
    } catch (error) {}
  };

  // The function below calculates the Delivery Time in Days

  const calculateDeliveryDays = (deliveryTime, createdAt) => {
    const deliveryDate = new Date(deliveryTime);
    const createdDate = new Date(createdAt);

    // Calculate the time difference in milliseconds
    const timeDifference = deliveryDate - createdDate;

    if (timeDifference < 24 * 60 * 60 * 1000) {
      // If less than 24 hours
      // Calculate the number of hours
      const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
      return hoursDifference + " hours";
    } else {
      // Calculate the number of days
      const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      return daysDifference + " days";
    }
  };

  return (
    <>
      {loading ? (
        <>
          <div className="p-5 m-5 d-flex justify-content-center">
            <PuffLoader color="#65acae" size={42} />
          </div>
        </>
      ) : (
        <>
          <div className="brand-listing-table" id="listing-view-catalogues">
            <div className="dataTables_wrapper mt-5 dt-bootstrap5 no-footer">
              {listToShow?.length ? (
                <>
                  <div className="row dt-row">
                    <div className="col-sm-12 ms-3">
                      <table
                        id="brand-detail-listing-table"
                        className="table rounded-top-3 table-striped dataTable no-footer"
                        style={{ width: "100%" }}
                      >
                        <thead>
                          <tr>
                            {requestListData.listTitle.map((cTitle, index) => {
                              return (
                                <th
                                  key={`Chat-Request-Title-${cTitle}-${index}`}
                                  className={
                                    cTitle === "Creator" || cTitle === "Action"
                                      ? "w-auto"
                                      : "text-center w-auto"
                                  }
                                  tabIndex={0}
                                  aria-controls="brand-detail-listing-table"
                                  rowSpan={1}
                                  colSpan={1}
                                  // aria-label="Account: activate to sort column ascending"
                                >
                                  <span className="mx-3">{cTitle}</span>
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {listToShow?.map((requestData, index) => {
                            const isEven = (index + 1) % 2 === 0;
                            const rowClass = isEven
                              ? "bg-table-stripe"
                              : "bg-white";
                            return (
                              <tr key={index} className={rowClass}>
                                <td>
                                  <div className="profile-outer-box w-100 d-flex">
                                    <div className="profile-inner1">
                                      <span className="person-image">
                                        <img
                                          src={
                                            user?.role === "1"
                                              ? requestData?.brand
                                                  ?.profile_image ??
                                                "/assets/images/user.png"
                                              : user?.role === "2"
                                              ? requestData?.creator
                                                  ?.profile_image ??
                                                "/assets/images/user.png"
                                              : ""
                                          }
                                          className="img-fluid person-image"
                                          alt="img"
                                        />
                                      </span>
                                    </div>
                                    <div className="profile-inner2">
                                      <span className="person-name-dark">
                                        {user?.role === "1"
                                          ? requestData?.brand?.brand_name ??
                                            "-"
                                          : user?.role === "2"
                                          ? requestData?.creator
                                              ?.profile_name ?? "-"
                                          : "-"}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                                {user?.role === "2" ? (
                                  <td className="text-center">
                                    {requestData?.creator?.followers_count ??
                                      "-"}
                                  </td>
                                ) : (
                                  <td></td>
                                )}
                                <td className="text-center">
                                  {requestData?.offers[0]?.price
                                    ? `$ ${requestData.offers[0].price
                                        ?.toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                                    : "-"}
                                </td>
                                <td className="text-center">
                                  {requestData?.creator?.location ?? "-"}
                                </td>
                                {user?.role === "2" ? (
                                  <td className="text-center">
                                    <img
                                      src={
                                        requestData?.creator?.gender === "male"
                                          ? "/assets/images/men.png"
                                          : "/assets/images/women.png"
                                      }
                                      alt={requestData?.creator?.gender}
                                    />
                                  </td>
                                ) : (
                                  <td></td>
                                )}

                                <td className="">
                                  <div className="d-flex">
                                    {page === "Requests-Sent" ? (
                                      <>
                                        <button
                                          data-bs-toggle="modal"
                                          data-bs-target="#view-offer-modal"
                                          onClick={() =>
                                            actionHandler("View", requestData)
                                          }
                                          className="table-request-btn btn bg-info btn-view"
                                        >
                                          View
                                        </button>
                                      </>
                                    ) : (
                                      <>
                                        {actions.map((action) => {
                                          return (
                                            <button
                                              key={`Action-${action}`}
                                              data-bs-toggle={`${
                                                action === "View" ? "modal" : ""
                                              }`}
                                              data-bs-target="#view-offer-modal"
                                              onClick={() =>
                                                actionHandler(
                                                  action,
                                                  requestData
                                                )
                                              }
                                              className={`table-request-btn btn ${
                                                action === "Accept"
                                                  ? "btn-accept"
                                                  : action === "Deny"
                                                  ? "bg-danger btn-deny"
                                                  : action === "View"
                                                  ? "bg-info btn-view"
                                                  : ""
                                              } "" `}
                                            >
                                              {action}
                                            </button>
                                          );
                                        })}
                                      </>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="review-box h-50 text-center">
                    <div className="review-heading">
                      <div>Pending Requests</div>
                    </div>
                    <div className="my-5">No Pending Requests</div>
                  </div>
                </>
              )}
            </div>

            {/* VIEW OFFER MODAL START  */}
            <div
              className="modal fade"
              id="view-offer-modal"
              tabIndex={-1}
              aria-labelledby="view-offer-modal"
              aria-hidden="true"
            >
              <div className="modal-dialog view-offer-modal">
                <div className="modal-content">
                  <div className="modal-body p-5">
                    <img
                      className="blue-ball-popup"
                      src="/assets/images/blue_ball.png"
                      alt="blue-ball"
                      width={25}
                    />
                    <img
                      className="popup-yellow-circle"
                      src="assets/images/yellow_circle.png"
                      alt="circle_3"
                      width={16}
                    />
                    <img
                      className="purple-empty-circle-popup"
                      src="assets/images/purple_empty_circle.png"
                      alt="empty_circle"
                      width={20}
                    />
                    <img
                      className="purple-plus-popup"
                      src="assets/images/purple_plus.png"
                      alt="plus"
                      width={16}
                    />

                    <div
                      className=" close-btn-box new-lg-close-position"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <img src="/assets/images/close-Icon2.svg" alt="X" />
                    </div>
                    <h5 className="text-center">Offer - Details</h5>
                    <div className="my-5 px-2 text-center">
                      {offerDetails[0]?.description}
                    </div>
                    <div className="px-2 view-offer-modal-bottom">
                      {offerDetails[0]?.price ? (
                        <div>
                          $
                          {offerDetails[0]?.price
                            ?.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                          per {offerDetails[0]?.object}
                        </div>
                      ) : (
                        <div></div>
                      )}
                      <div>
                        Delivery:{" "}
                        {calculateDeliveryDays(
                          offerDetails[0]?.delivery_time,
                          offerDetails[0]?.created_at
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* VIEW OFFER MODAL END  */}
          </div>
        </>
      )}
    </>
  );
};

export default ChatRequest;

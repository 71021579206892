// store.js
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Defaults to localStorage for web

import authReducer, { logout } from "../states/auth/authSlice";
import creatorsReducer from "../states/creators/creatorSlice";
import brandsReducer from "../states/brands/brandSlice";
import campaignsReducer from "../states/campaigns/campaignSlice";
import collaborationsReducer from "../states/collaborations/collaborationSlice";
import myCampaignsReducer from "../states/myCampaigns/myCampaignSlice";
import packsReducer from "../states/packs/packSlice";
import creatorDetailsReducer from "../states/creatorDetails/creatorDetailsSlice";
import brandDetailsReducer from "../states/brandDetails/brandDetailsSlice";
import pendingRequestsReducer from "../states/pendingRequests/pendingRequestsSlice";
import singleCollaborationReducer from "../states/singleCollaboration/singleCollaborationSlice";
import singleCampaignReducer from "../states/singleCampaign/singleCampaignSlice";
import packDetailsReducer from "../states/packDetails/packDetailsSlice";
import chatReducer from "../states/chat/chatSlice";
import contactUsReducer from "../states/contactUs/contactUsSlice";

// Redux persist configuration
const persistConfig = {
  key: "root",
  storage,
};

// Combine all the reducers
const appReducer = combineReducers({
  auth: authReducer,
  creators: creatorsReducer,
  brands: brandsReducer,
  campaigns: campaignsReducer,
  collaborations: collaborationsReducer,
  myCampaigns: myCampaignsReducer,
  packs: packsReducer,
  creatorDetails: creatorDetailsReducer,
  brandDetails: brandDetailsReducer,
  pendingRequests: pendingRequestsReducer,
  singleCollaboration: singleCollaborationReducer,
  singleCampaign: singleCampaignReducer,
  packDetails: packDetailsReducer,
  chat: chatReducer,
  contactUs: contactUsReducer,
});

// Root reducer to handle logout by resetting all state
const rootReducer = (state, action) => {
  if (action.type === logout.type) {
    state = undefined; // Reset all the state on logout
  }
  return appReducer(state, action);
};

// Create persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Persist the store
export const persistor = persistStore(store);

// singleCampaignSlice.js

import { createSlice } from "@reduxjs/toolkit";
import { logout } from "../auth/authSlice";
import {
  sendCollaborationCall,
  singleCampaignDetails,
} from "../../Common/Repository";
import { toast } from "react-toastify";

const initialState = {
  reviewCount: 0,
  averageRating: null,
  singleCampaignData: {},
  activeCollaborations: [],
  idealCategoryArray: [],
  loading: false,
  error: null,
  sendRequestLoading: false,
  sendRequestSuccess: false,
  sendRequestError: null,
};

const singleCampaignSlice = createSlice({
  name: "singleCampaign",
  initialState,
  reducers: {
    setChangeActiveCollabStatus: (state, action) => {
      state.activeCollaborations = action?.payload;
    },
    updateSingleCampaignData: (state, action) => {
      state.singleCampaignData = action?.payload;
    },
    setSingleCampaignData: (state, action) => {
      state.singleCampaignData = action.payload?.data?.data;
      state.activeCollaborations = action.payload?.data?.active_collaborations;
      state.pendingCollaborations = action.payload?.data?.pending_collaborations;

      state.loading = false;
      state.error = null;
      const idealCategoryString = state.singleCampaignData?.ideal_category;
      if (idealCategoryString !== undefined && idealCategoryString !== null) {
        if (typeof idealCategoryString === "string") {
          state.idealCategoryArray = idealCategoryString;
        } else {
          state.idealCategoryArray = JSON.parse(idealCategoryString);
        }
      }

      // try {
      //   state.idealCategoryArray = JSON.parse(idealCategoryString);
      // } catch (error) {
      //   
      //   state.idealCategoryArray = [];
      // }
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(singleCampaignDetails.pending, (state) => {
        state.error = null;
        state.activeCollaborations = [];
        state.pendingCollaborations = [];

        state.idealCategoryArray = [];
        state.loading = true;
      })
      .addCase(singleCampaignDetails.fulfilled, (state, action) => {
        state.singleCampaignData = action.payload?.data?.data;
        state.activeCollaborations =
          action.payload?.data?.active_collaborations;
        state.pendingCollaborations =
          action.payload?.data?.pending_collaborations;
        state.error = null;
        state.reviewCount = action?.payload?.data?.reviews?.total_reviews;
        state.averageRating = action?.payload?.data?.reviews?.average_rating;

        const idealCategoryString = state.singleCampaignData?.ideal_category;

        if (idealCategoryString !== undefined && idealCategoryString !== null) {
          if (typeof idealCategoryString === "string") {
            state.idealCategoryArray = idealCategoryString;
          } else {
            state.idealCategoryArray = JSON.parse(idealCategoryString);
          }
        }

        // try {
        //   state.idealCategoryArray = JSON.parse(idealCategoryString);
        // } catch (error) {
        //   
        //   state.idealCategoryArray = [];
        // }

        state.loading = false;
      })
      .addCase(singleCampaignDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })


      .addCase(sendCollaborationCall.pending, (state) => {
        state.sendRequestError = null;
        state.sendRequestSuccess = false;
        state.sendRequestLoading = true;
      })
      .addCase(sendCollaborationCall.fulfilled, (state, action) => {
        state.sendRequestLoading = false;
        state.sendRequestSuccess = true;
        state.sendRequestError = null;
        document.getElementById("collaboration-proposal-modal-close")?.click();
        toast.success("Collaboration Request Sent!");
      })
      .addCase(sendCollaborationCall.rejected, (state, action) => {
        state.sendRequestLoading = false;
        state.sendRequestSuccess = false;
        state.sendRequestError = action.payload;
      })
      .addCase(logout, (state) => {
        state.sendRequestLoading = false;
        state.sendRequestSuccess = false;
        state.sendRequestError = null;
        state.loading = false;
        state.error = null;
        state.singleCampaignData = {};
        state.activeCollaborations = [];
        state.pendingCollaborations = [];
        state.averageRating = null;
        state.reviewCount = 0;

      });
  },
});

export const { updateSingleCampaignData, setSingleCampaignData, setChangeActiveCollabStatus, setLoading } =
  singleCampaignSlice.actions;

export default singleCampaignSlice.reducer;

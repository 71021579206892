import React from "react";

const CircleImageNFound = ({ width, height }) => {
  return (
    <>
      <svg
        width={width ? width : "70"}
        height={height ? height : "70"}
        viewBox="0 0 70 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="34.939" cy="34.939" r="34.939" fill="#D9D9D9" />
        <path
          d="M23 28.333V33.6663C23 34.02 23.1405 34.3591 23.3905 34.6091C23.6406 34.8592 23.9797 34.9997 24.3333 34.9997H28.3333M28.3333 28.333V41.6663M41.6667 28.333V33.6663C41.6667 34.02 41.8071 34.3591 42.0572 34.6091C42.3072 34.8592 42.6464 34.9997 43 34.9997H47M47 28.333V41.6663M32.3333 29.6663V40.333C32.3333 40.6866 32.4738 41.0258 32.7239 41.2758C32.9739 41.5259 33.313 41.6663 33.6667 41.6663H36.3333C36.687 41.6663 37.0261 41.5259 37.2761 41.2758C37.5262 41.0258 37.6667 40.6866 37.6667 40.333V29.6663C37.6667 29.3127 37.5262 28.9736 37.2761 28.7235C37.0261 28.4735 36.687 28.333 36.3333 28.333H33.6667C33.313 28.333 32.9739 28.4735 32.7239 28.7235C32.4738 28.9736 32.3333 29.3127 32.3333 29.6663Z"
          stroke="#FE0201"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default CircleImageNFound;

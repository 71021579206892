import React, { useEffect, useState } from 'react';
import { Elements, useStripe, useElements, CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AppLayout from '../Components/AppLayout';
import { useLocation, useNavigate } from 'react-router-dom';
import { AcceptOfferApi, MakePaymentBrand, buyPackPaymentSuccessCall } from '../Common/Repository';
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { PuffLoader } from 'react-spinners';

const stripePromise = loadStripe('pk_test_51NkO0pD98IPRVgsD6bzV8jmone2PK0NhCoMy89U98BqE2E2Oba9FxW9IHO6rQsUgW5iqAZsr3Q5R47UwWDfapEB300WbQX4Q5t');
const PaymentForm = () => {

  const { loadingBrandPayment } = useSelector((state) => state.chat);


  const dispatch = useDispatch();

  const navigate = useNavigate();

  const data = useLocation();

  const [packId, setPackId] = useState(null);

  const [collabOfferId, setCollabOfferId] = useState(null);

  const userToken = useState(localStorage.getItem("token"));
  const {
    currentChatId
  } = useSelector((state) => state.chat)
  useEffect(() => {
    setPackId(data?.state?.pack_id)
    setCollabOfferId(data?.state?.offer_id)
  }, [data])

  const stripe = useStripe();
  const elements = useElements();
  const [paymentError, setPaymentError] = useState('');
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  useEffect(() => {
    console.log('data: ', data?.state?.collaboration_offer);

  }, [data])

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    // CAMPAIGN COLLABORATION
    if (data?.state?.payment_type === "campaign_collaboration_offer") {
      try {

        setPaymentError('');
        const { token, error } = await stripe.createToken(
          elements.getElement(CardNumberElement)
        );
        if (error) {
          setPaymentError(error);
        } else {
          try {
            let formData = {};
            formData.offer_id = collabOfferId;
            formData.token = token.id;
            let header = {
              Authorization: `Bearer ${userToken[0]}`,
            };
            let response = await dispatch(AcceptOfferApi({ header, formData, dispatch, currentChatId }));
            if (response.error) {
              toast.error(response?.payload?.error);
            } else {
              setPaymentSuccess(true);
              toast.success('Payment made successfully');
              navigate('/creatorChat');
            }
          } catch (error) {
            toast.error(error?.response?.data?.error);
          }
        }
      } catch (error) {
        setPaymentError("An error occurred during payment processing.");
      }
    } else if (data?.state?.payment_type === "buy_pack") {
      try {

        setPaymentError('');
        const { token, error } = await stripe.createToken(
          elements.getElement(CardNumberElement)
        );
        if (error) {
          setPaymentError(error);
        } else {
          try {
            let data = {};
            data.pack_proposal_id = packId;
            data.token = token.id;
            let header = {
              Authorization: `Bearer ${userToken[0]}`,
            };
            let response = await dispatch(buyPackPaymentSuccessCall({ header, data, dispatch, currentChatId }));
            if (response.error) {
              toast.error(response?.payload?.error);
            } else {
              setPaymentSuccess(true);
              toast.success('Pack bought successfully');
              navigate('/creatorChat');
            }
          } catch (error) {
            toast.error(error?.response?.payload?.error);
          }
        }
      } catch (error) {
        setPaymentError("An error occurred during payment processing.");
      }
    } else {
      // INVITE COLLABORATION 
      try {

        setPaymentError('');
        const { token, error } = await stripe.createToken(
          elements.getElement(CardNumberElement)
        );
        if (error) {
          setPaymentError(error);
        } else {
          try {
            let formData = {};
            formData.offer_id = collabOfferId;
            formData.token = token.id;
            let header = {
              Authorization: `Bearer ${userToken[0]}`,
            };
            let response = await dispatch(MakePaymentBrand({ header, formData, dispatch, currentChatId }));
            if (response.error) {
              toast.error(response?.payload?.error);
            } else {
              setPaymentSuccess(true);
              toast.success('Payment made successfully');
              // navigate('/creatorChat');
              navigate('/creatorChat', { state: { openFirstChat: true } });
            }
          } catch (error) {
            toast.error(error?.response?.payload?.error);
          }
        }
      } catch (error) {
        setPaymentError("An error occurred during payment processing.");
      }
    }
  };


  return (
    <AppLayout>
      <div className='navigaton-back-container'>
        <div
          onClick={() => {
            navigate(-1);
          }}
          className='btn common-button-style-filled'>
          &#x2190;  Back
        </div>
      </div>
      <div className='payment-container-box p-5'>
        <div className="d-flex justify-content-between align-items-center">
          <h2>Make a Payment</h2>
          <div className='text-muted w-50 d-flex align-items-center justify-content-end fs-4 fw-bold pe-5'>
            <span className='text-highlight mx-2'> ${data?.state?.collaboration_offer?.price?.toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "} </span> for {data?.state?.collaboration_offer?.object}
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <label>
            Card Details
          </label>
          <div className='row payment-section'>
            <div className='col-md-12'>
              <div className='p-3 stripe-input-design'>
                <CardNumberElement />
              </div>
              <div className='ms-3'>
                {paymentError.code === "incomplete_number" ? <p style={{ color: 'red' }}>{paymentError.message}</p> : <p></p>}
              </div>
            </div>
            <div className='col-md-5'>
              <div className='p-3  stripe-input-design'>
                <CardCvcElement />
              </div>
              <div className='ms-3'>
                {paymentError.code === "incomplete_cvc" ? <p style={{ color: 'red' }}>{paymentError.message}</p> : <p></p>}
              </div>
            </div>

            <div className='col-md-5'>
              <div className='p-3 stripe-input-design '>
                <CardExpiryElement />

              </div>
              <div className='ms-3'>
                {paymentError.code === "incomplete_expiry" ? <p style={{ color: 'red' }}>{paymentError.message}</p> : <p></p>}
              </div>

            </div>
          </div>

          <div className='d-flex justify-content-end'>
            <button onClick={() => {
              if (paymentSuccess) {
                setPaymentSuccess(false);
                setPaymentError("");
              }
              setPaymentLoader(true);
            }} className='btn common-button-style-filled m-4' type="submit" disabled={!stripe}>
              {loadingBrandPayment || paymentLoader ? (
                <PuffLoader
                  className="justify-content-center"
                  color="white"
                  size={22}
                />
              ) : (
                "Pay"
              )}
            </button>
          </div>
          {paymentSuccess && <p style={{ color: 'green' }}>Payment successful!</p>}
        </form>
      </div>
    </AppLayout>
  );
};

const WrappedPaymentForm = () => (
  <Elements stripe={stripePromise}>
    <PaymentForm />
  </Elements>
);

export default WrappedPaymentForm;

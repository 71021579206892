import React, { useEffect, useState } from "react";
import AppLayout from "../../Components/AppLayout";
import Reviews from "../../Components/Reviews";
import {
  addToFavouriteCall,
  getAllPacksForBrandCall,
  getSingleCreatorCall,
  getUserPublicProfile,
  removeFavouriteCall,
  sendCollaborationCall,
} from "../../Common/Repository";
import { useLocation, useNavigate } from "react-router-dom";
import CircleImageNFound from "../../Components/404_CircleImage";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { PuffLoader } from "react-spinners";
import {
  setCreatorsCollaborationData,
  setFavourite,
  setLoading,
} from "../../states/creatorDetails/creatorDetailsSlice";
import { setErrors } from "../../states/brandDetails/brandDetailsSlice";

const CreatorDetails = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const location = useLocation();

  const {
    singleCreator,
    creatorsCollaborationData,
    reviewsReceived,
    averageRating,
    reviewCount,
    creatorPacksData,
    loading,
    instagramLoading,
    isFavourite,
    inviteLoading,
    instagramData
  } = useSelector((state) => state.creatorDetails);

  const { collaborationsData } = useSelector((state) => state.collaborations);


  const completedCollaborations = collaborationsData.filter(
    (collab) => collab.status === "completed"
  ).length;

  const creator_Id = location?.state?.creatorId;

  const { user, token } = useSelector((state) => state.auth);

  const [description, setDescription] = useState("");

  const [price, setPrice] = useState("");

  const [object, setObject] = useState("");

  const [deliveryTime, setDeliveryTime] = useState("");

  const [descriptionError, setDescriptionError] = useState(false);

  const [priceError, setPriceError] = useState(false);

  const [objectError, setObjectError] = useState(false);

  const [dateError, setDateError] = useState(false);

  const today = new Date();

  const nextDay = new Date(today.getTime() + 24 * 60 * 60 * 1000); // Adding 24 hours in milliseconds

  const minDate = nextDay.toISOString().slice(0, 10);

  const [fieldErrors, setFieldErrors] = useState();

  const handleChange = (event, key) => {
    if (key === "description") {
      setDescription(event?.target?.value);
    }
    if (key === "price") {
      setPrice(event?.target?.value);
    }
    if (key === "object") {
      setObject(event?.target?.value);
    }
    if (key === "delivery_time") {
      setDeliveryTime(event?.target?.value);
    }
  };

  const objectData = ["Story", "Reel", "Short Video", "Graphic Post", "Custom"];

  const maxWordCount = 250;

  const remainingWords = description.length;

  const validateSendCollab = () => {
    resetErrors();
    let errors = new Map();
    if (description.length < 21) {
      setDescriptionError(true);
      errors.set(
        "description",
        "The description needs to be more than 20 characters!"
      );
    }
    if (price.length < 1) {
      setPriceError(true);
      errors.set("price", "Please enter a valid offering price");
    }
    if (object.length < 1) {
      setObjectError(true);
      errors.set("object", "Please select an object");
    }

    if (deliveryTime.length < 10) {
      setDateError(true);
      errors.set("delivery_time", "Please enter a delivery time");
    }

    setFieldErrors(errors);

    if (errors.size > 0) {
      return false;
    } else {
      return true;
    }
  };

  const resetErrors = () => {
    setFieldErrors([]);
    setDateError(false);
    setDescriptionError(false);
    setPriceError(false);
    setObjectError(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getSingleCreator();
      getAllPacksForBrand();
    };

    fetchData();
    // eslint-disable-next-line
  }, [creator_Id]);

  const getPublicView = async () => {
    try {
      dispatch(getUserPublicProfile({ token, user }));
    } catch (error) {
      setErrors(error?.response?.data?.error);
    }
  };

  useEffect(() => {
    getPublicView();
    // eslint-disable-next-line
  }, []);

  const getSingleCreator = async () => {
    if (!creator_Id) {
      return;
    }
    try {
      if (singleCreator?.id !== creator_Id) {
        dispatch(setLoading(true));
      }
      let data = {};
      data.creator_id = creator_Id || singleCreator?.id;
      dispatch(getSingleCreatorCall({ token, data }));
    } catch (error) { }
  };

  const getAllPacksForBrand = async () => {
    try {
      let data = {};
      data.creator_id = creator_Id || singleCreator?.id;
      dispatch(getAllPacksForBrandCall({ token, data }));
    } catch (error) { }
  };

  const handleAddToFavourite = async () => {
    try {
      dispatch(setFavourite(true));
      let data = {};
      data.creator_id = singleCreator?.id;
      data.is_favourite = 1;
      let response = await addToFavouriteCall(
        {
          Authorization: `Bearer ${token}`,
        },
        data
      );
      if (response) {
        getSingleCreator();
        toast.success("Creator added to favourites!");
      }
    } catch (error) {
      toast.error("creator is already added to favourites");
    }
  };

  const handleRemoveFavourite = async () => {
    try {
      dispatch(setFavourite(false));
      let data = {};
      data.creator_id = singleCreator?.id;
      data.is_favourite = 0;
      let response = await removeFavouriteCall(
        {
          Authorization: `Bearer ${token}`,
        },
        data
      );
      if (response) {
        getSingleCreator();
        toast.success("Creator removed from favourites!");
      }
    } catch (error) {
      toast.error("Failed to remove Creator from favourites!");
    }
  };

  const handleSendCollaborationRequest = async () => {
    try {
      if (validateSendCollab()) {
        let data = {};
        data.creator_id = singleCreator?.id;
        data.description = description;
        data.price = price;
        data.object = object;
        data.delivery_time = deliveryTime;
        dispatch(sendCollaborationCall({ token, data }));
        setDescription("");
        setPrice("");
        setObject("");
        setDeliveryTime("");
        document.getElementById("invite-modal-close").click();
        toast.success("Collaboration Request Sent Success!");

        // Update creatorsCollaborationData in the Redux store
        const updatedCollaborationData = [
          {
            ...creatorsCollaborationData[0],
            status: "pending",
          },
        ];
        dispatch(setCreatorsCollaborationData(updatedCollaborationData));
      }
    } catch (error) {
      toast.error(error?.response?.data?.messsage);
    }
  };

  const handlePackClick = (packId) => {
    if (user?.role === "2") {
      navigate("/pack/details", {
        state: {
          packId: packId,
          creatorId: singleCreator?.id,
          creatorsCollaborationData: creatorsCollaborationData,
        },
      });
    }
  };

  function getCityAndCountry(fullAddress) {
    const addressParts = fullAddress.split(",");
    const city = addressParts[addressParts.length - 2]?.trim();
    const country = addressParts[addressParts.length - 1]?.trim();

    return `${city}, ${country}`;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <AppLayout>
      {loading ? (
        <>
          <div className="p-5 m-5 d-flex justify-content-center">
            <PuffLoader color="#65acae" size={42} />
          </div>
        </>
      ) : (
        <>
          <div className="categories-dashboard mt-5 creators-detail creator-details-width mx-4 mx-xl-auto pb-5 mb-5">
            <div
              onClick={() => {
                if (user?.role == 1) {
                  navigate("/creatorsProfile")
                } else {
                  navigate("/creatorsCatalogue");
                }
              }}
              className="btn common-button-style-filled mb-5"
            >
              &#x2190; Back
            </div>
            <img
              className="purple-circle"
              src="/assets/images/popup-purple-circle.png"
              alt="purple-circle"
            />
            <div className="container-animation">
              <div className="w-100 col-lg-12">
                <div className="user-profile-box-outer w-100 d-flex">
                  <div className="row mobile-col">
                    <div className="col-md-4">
                      <div className="row">
                        <div className="d-flex justify-content-center col-xl-5 col-12 mobile-col">
                          {singleCreator?.profile_image !== undefined &&
                            singleCreator?.profile_image !== null ? (
                            <img
                              className="brand-profile-image sm-align-to-center"
                              src={singleCreator?.profile_image}
                              alt="avatar"
                            />
                          ) : (
                            <img
                              className="brand-profile-image sm-align-to-center"
                              src={
                                singleCreator?.gender === "female"
                                  ? "/assets/images/women-opt.png"
                                  : "/assets/images/user.png"
                              }
                              alt="avatar"
                            />
                          )}
                        </div>
                        <div className="col-xl-7 col-12">
                          <h6 className="text-center text-truncate my-3">
                            {singleCreator?.profile_name ?? "*"}
                          </h6>
                          <div className="text-center creatorProfileDetail p-2 mx-5 mx-md-0">
                            <div
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                              }}
                              className="d-flex justify-content-center align-items-center"
                            >
                              <img
                                src="/assets/images/locationIcon.svg"
                                className="mx-2"
                                alt="location"
                                style={{
                                  maxWidth: "20px",
                                  maxHeight: "20px",
                                }}
                              />
                              <p
                                style={{
                                  // maxWidth: "100%",
                                  // overflow: "hidden",
                                  // textOverflow: "ellipsis",
                                  // whiteSpace: "nowrap",
                                  margin: 0,
                                }}
                                className="my-auto"
                              >
                                {getCityAndCountry(singleCreator?.address ?? "Location")}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 border-start border-end no-border-phone my-4 mt-md-0">
                      <div className="row pt-2">
                        <div className="col-md-12 d-flex justify-content-center">
                          <div className="ps-4 text-muted text-center">
                            {singleCreator.profile_name} has completed <span className="text-dark fw-bold"> {completedCollaborations} </span> collaborations
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="row">
                        <div className="col-6 align-center">
                          {user?.role === "2" ? (
                            <>
                              <div className="d-flex ">
                                <div
                                  onClick={() => {
                                    if (
                                      singleCreator?.favourite_creator
                                        ?.is_favourite === "1" ||
                                      singleCreator?.favourite_creator
                                        ?.is_favourite === 1
                                    ) {
                                      handleRemoveFavourite();
                                    } else if (
                                      singleCreator?.favourite_creator
                                        ?.is_favourite === "0" ||
                                      singleCreator?.favourite_creator === null
                                    ) {
                                      handleAddToFavourite();
                                    }
                                  }}
                                  className="me-md-3 icon-bg"
                                >
                                  <svg
                                    width="100px"
                                    height="100px"
                                    viewBox="1 -4 40 40"
                                    fill={
                                      isFavourite
                                        ? "#549A9C"
                                        : // : singleCreator?.favourite_creator
                                        //     ?.is_favourite === "1" ||
                                        //   singleCreator?.favourite_creator
                                        //     ?.is_favourite === 1
                                        // ? "#549A9C"
                                        "none"
                                    }
                                    xmlns="http://www.w3.org/2000/svg"
                                    transform="translate(5,-5)"
                                  >
                                    <g
                                      id="SVGRepo_bgCarrier"
                                      strokeWidth="0"
                                    ></g>
                                    <g
                                      id="SVGRepo_tracerCarrier"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></g>
                                    <g id="SVGRepo_iconCarrier">
                                      <path
                                        d="M21 23.5C19.8 24.8235 16.5 28 16.5 28C16.5 28 13.2 24.8235 12 23.5C9.2 20.4118 8.5 19.2059 8.5 17C8.5 14.7941 10.1 13 12.5 13C14.5 13 15.7 14.3235 16.5 15.6471C17.3 14.3235 18.5 13 20.5 13C22.9 13 24.5 14.7941 24.5 17C24.5 19.2059 23.8 20.4118 21 23.5Z"
                                        stroke={
                                          // singleCreator?.favourite_creator
                                          //   ?.is_favourite === "1" ||
                                          // singleCreator?.favourite_creator
                                          //   ?.is_favourite === 1
                                          //   ?
                                          isFavourite ? "" : "#549A9C"
                                        }
                                        strokeWidth="1.2"
                                      ></path>
                                    </g>
                                  </svg>
                                </div>
                                {/* <div
                                  style={{
                                    zIndex: "100",
                                  }}
                                  className="icon-bg"
                                >
                                  <img
                                    className=""
                                    src="/assets/images/share-new.svg"
                                    alt="avatar"
                                  />
                                </div> */}
                              </div>
                            </>
                          ) : null}
                        </div>
                        <div className="col-6">
                          <div className="user-profile-box-inner border-0">
                            <div className="public-profile-box text-center">
                              {user?.role === "2" ? (
                                <>
                                  <button
                                    disabled={
                                      creatorsCollaborationData[
                                        creatorsCollaborationData?.length - 1
                                      ]?.status === "pending" ||
                                        creatorsCollaborationData[
                                          creatorsCollaborationData?.length - 1
                                        ]?.status === "approved" ||
                                        user?.role === "1"
                                        ? true
                                        : false
                                    }
                                    data-bs-toggle="modal"
                                    data-bs-target="#collaboration-proposal-modal"
                                    className={` ${creatorsCollaborationData[
                                      creatorsCollaborationData?.length - 1
                                    ]?.status === "pending" ||
                                      creatorsCollaborationData[
                                        creatorsCollaborationData?.length - 1
                                      ]?.status === "approved"
                                      ? "common-button-style-disabled text-highlight"
                                      : "common-button-style-filled"
                                      }`}
                                  // className="common-button-style-filled border-0 btn disable"
                                  >
                                    {inviteLoading ? (
                                      <PuffLoader
                                        className="justify-content-center"
                                        color="#65acae"
                                        size={20}
                                      />
                                    ) : (
                                      <>
                                        {creatorsCollaborationData[
                                          creatorsCollaborationData?.length - 1
                                        ]?.status === "pending"
                                          ? "Invited"
                                          : creatorsCollaborationData[
                                            creatorsCollaborationData?.length -
                                            1
                                          ]?.status === "approved"
                                            ? "Ongoing"
                                            : "Invite"}
                                      </>
                                    )}
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button className="common-button-style-filled">
                                    Invite
                                  </button>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="userOutsideProfileBox mt-5">
                {instagramLoading ?
                  <div className="d-flex justify-content-center">
                    <PuffLoader
                      className="justify-content-center"
                      color="#65acae"
                      size={20}
                    />
                  </div>
                  :
                  <>
                    <div className="d-flex justify-content-between">
                      <h5 className="d-flex flex-column justify-content-center">Instagram</h5>
                      <div className="d-flex justify-content-between">
                        {instagramData?.instagram_verified_at ?
                          <div className="d-xl-none justify-content-center d-flex border border-success rounded-pill px-4 align-items-center text-success ">
                            Verified
                          </div> : ""}
                      </div>
                    </div>
                    {instagramData?.instagram_verified_at ? (
                      <>
                        <div className="row mt-5">
                          <div className="col-xl-9">
                            <div className="row align-items-stretch">
                              <div className="col-md-3 d-flex flex-column align-items-center">
                                {instagramData
                                  ?.instagram_profile_picture_url !== undefined &&
                                  instagramData
                                    ?.instagram_profile_picture_url !== null ? (
                                  <img
                                    className="brand-profile-image m-2"
                                    src={
                                      instagramData
                                        ?.instagram_profile_picture_url
                                    }
                                    alt="Instagram dp"
                                  />
                                ) : (
                                  <div className="m-2">
                                    <CircleImageNFound width={100} height={100} />
                                  </div>
                                )}
                                <strong>
                                  <div className="text-truncate cursor-pointer mt-3 text-center text-underline">
                                    <small className="d-flex">

                                      <span className="pb-1">
                                        {/* Create a dynamic link to the Instagram profile */}
                                        <a
                                          href={`https://www.instagram.com/${instagramData?.instagram_username ?? "username"}`}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="text-decoration-none"
                                        >
                                          <span className="px-1">
                                            <img src="/assets/images/InstaIcon.svg" alt="Instagram Icon" />
                                          </span>
                                          {instagramData?.instagram_username ?? "username"}
                                        </a>
                                      </span>
                                    </small>
                                  </div>
                                </strong>

                              </div>
                              <div className="col-md-9 border-start no-border-phone">
                                <div className="row justify-space-between text-center">
                                  <div className="row my-3">
                                    <div style={{
                                      height: '80px'
                                    }} className="col-md-4 col-6">
                                      <strong>
                                        {instagramData
                                          ?.instagram_followers_count ?? "N/A"}
                                      </strong>
                                      <div>followers</div>
                                    </div>
                                    <div style={{
                                      height: '80px'
                                    }} className="col-md-4 col-6">
                                      <strong>
                                        {instagramData
                                          ?.instagram_follows_count ?? "N/A"}
                                      </strong>
                                      <div>following</div>
                                    </div>
                                    <div style={{
                                      height: '80px'
                                    }} className="col-md-4 col-6">
                                      <strong>
                                        {instagramData
                                          ?.instagram_media_count ?? "N/A"}
                                      </strong>
                                      <div>posts</div>
                                    </div>
                                  </div>
                                  <div style={{
                                    height: '80px'
                                  }} className="col-md-4 col-6">
                                    <strong>
                                      {instagramData?.impressions
                                        ? instagramData?.impressions +
                                        "%"
                                        : "N/A"}
                                    </strong>
                                    <div>impressions</div>
                                  </div>
                                  <div style={{
                                    height: '80px'
                                  }} className="col-md-4 col-6">
                                    <strong>
                                      {" "}
                                      {instagramData
                                        ?.page_connected_to_instagram_category ??
                                        "N/A"}{" "}
                                    </strong>
                                    <div>niche</div>
                                  </div>
                                  <div style={{
                                    height: '80px'
                                  }} className="col-md-4 col-6">
                                    <strong>
                                      {instagramData?.reach
                                        ? instagramData?.reach + "%"
                                        : "N/A"}
                                    </strong>
                                    <div>reach</div>
                                  </div>
                                </div>
                                {instagramData?.instagram_biography ? (
                                  <>
                                    <div className="px-5">
                                      <div className="row my-4 ps-2 border-bottom">
                                        {instagramData?.instagram_biography}
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                                {[instagramData?.instagram_website]?.map((site, index) => {
                                  return (
                                    <div key={index} className="px-5">
                                      <strong
                                      // className={`${index > 0 ? "invisible" : ""}`}
                                      >
                                        Website: </strong>
                                      <a
                                        className="link-style"
                                        style={{
                                          fontSize: "16px",
                                          cursor: "pointer",
                                        }}
                                        href={site}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {site}
                                      </a>
                                      <div className="row ps-2 border-bottom"></div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                          <div className="text-center d-none d-xl-block col-xl-3 px-5 w-content">
                            <div className="justify-content-center d-flex mt-2 border border-success rounded-5 py-2  text-success ">
                              Instagram Verified
                            </div>
                          </div>

                        </div>
                      </>
                    ) : (
                      <>
                        <div className="d-flex justify-content-between me-5">
                          <div className="align-center">
                            {singleCreator?.profile_name} hasn't connected their
                            Instagram yet.
                          </div>
                        </div>
                      </>
                    )}
                  </>
                }
              </div>
              <div className="user-profile-box-outer mt-5">
                <div className="w-100 d-flex creator-detail-profile creator-detail-profile-box">
                  <div className="d-flex campaign-profile creator-deatil-section align-items-center">
                    <div className="ms-3">
                      <div className="d-flex align-items-center">
                        <h4 className="mt-3">Packs Offered</h4>
                      </div>
                    </div>
                  </div>
                  <div className="w-50 followers-box text-end mt-3">
                  </div>
                </div>
                <div className="campaigns-img-sec pt-3">
                  {creatorPacksData.length > 0 ? (
                    <div className="row justify-content-start">
                      {creatorPacksData?.map((cData, index) => (
                        <div className="col-md-3 mb-4" key={index}>
                          <div
                            onClick={() => {
                              handlePackClick(cData?.id);
                            }}
                            className="position-relative"
                          >
                            <div className="d-flex">
                              {cData?.pack_images !== undefined &&
                                cData?.pack_images !== null ? (
                                <>
                                  <img
                                    className="creator-pack-image"
                                    src={cData?.pack_images[0]?.image}
                                    alt="Pack Image"
                                    onLoad={(e) => {
                                      e.target.style.filter = "none";
                                      e.target.style.opacity = 1;
                                    }}
                                  />
                                  <div className="creator-pack-overlay"></div>
                                </>
                              ) : null}
                            </div>
                            <div className="pack-delivery">
                              <div className="text-light px-2">{cData?.time_duration} Days</div>
                            </div>
                            <div className="creator-pack-tag">
                              <div>{cData?.title}</div>
                              <div className="d-flex">
                                <div className="brand-name">
                                  {cData?.price
                                    ?.toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                                  per {cData?.object}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="ms-3">No packs are offered currently</div>
                  )}
                </div>

              </div>
            </div>
            {reviewsReceived?.length ? (
              <Reviews
                totalReviews={reviewCount}
                averageStars={averageRating}
                reviewsReceived={reviewsReceived}
              />
            ) : (
              <>
                <div className="my-5">
                  <div className="reviewHeading">
                    <div>Reviews</div>
                    <div className="reviewStars"></div>
                  </div>
                  <div className="bg-white text-center p-5 rounded-3">
                    No Reviews Yet
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}

      {/* COLLABORATION PROPOSAL MODAL START  */}
      <div
        className="modal fade"
        id="collaboration-proposal-modal"
        tabIndex={-1}
        aria-labelledby="collaboration-proposal-modal"
        aria-hidden="true"
      >
        <div className="modal-dialog collaboration-proposal-modal">
          <div className="modal-content">
            <div className="modal-body pt-4 py-4 px-5">
              {/* <div
                onClick={() => {
                  setDescription("");
                  setPrice("");
                  setObject("");
                  setDeliveryTime("");
                }}
                className=" close-btn-box lg-close-position"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="invite-modal-close"
              >
                <img src="/assets/images/close-Icon.png" alt="X" />
              </div> */}
              <h3 className="mb-4 text-center">Invitation Form</h3>
              <div className="position-relative">
                <textarea
                  maxLength={maxWordCount}
                  placeholder="Description*"
                  className={`custom-offer-textarea${descriptionError ? " redOutline" : ""
                    }`}
                  name="description"
                  value={description}
                  onChange={(event) => {
                    handleChange(event, "description");
                  }}
                />
                <div
                  className={`view-offer-count-position${descriptionError ? " bottom-20" : ""
                    }`}
                >
                  <p> {remainingWords}/500 Characters</p>
                </div>
                {descriptionError ? (
                  <span className="errorText">
                    {fieldErrors.get("description")}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex justify-content-between mt-4 mb-4">
                <div className="col-5">
                  <div className=" position-relative">
                    <input
                      maxLength={6}
                      type="tel"
                      placeholder="Offering Price*"
                      className={`custom-input${priceError ? " redOutline" : ""
                        }`}
                      value={price}
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        const numericValue = inputValue.replace(/[^0-9]/g, "");
                        setPrice(numericValue);
                      }}
                    />
                    <div className="collaboration-proposal-dollar-icon">
                      <img src="/assets/images/$.png" alt="$" />
                    </div>
                    {priceError ? (
                      <span className="errorText">
                        {fieldErrors.get("price")}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="d-flex align-items-center">Per</div>
                <div className="col-5">
                  <select
                    className={`custom-input px-3${objectError ? " redOutline" : ""
                      }`}
                    value={object}
                    onChange={(event) => {
                      setObject(event.target.value);
                    }}
                  >
                    <option className="select" value="">
                      Select Object*
                    </option>
                    {objectData.map((object, index) => (
                      <option key={index} value={object}>
                        {object}
                      </option>
                    ))}
                  </select>
                  {objectError ? (
                    <span className="errorText">
                      {fieldErrors.get("object")}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-12 mb-4">
                <p className="mb-1 smallText">
                  Enter the delivery date, by which you need this work done.
                </p>
                <input
                  // maxLength={8}
                  type="date"
                  placeholder="Delivery Time*"
                  className={`custom-input pe-3${dateError ? " redOutline m-0" : ""
                    }`}
                  value={deliveryTime}
                  // min={new Date().toJSON().slice(0, 10)}
                  min={minDate}
                  max={new Date(today.getFullYear() + 2, today.getMonth(), today.getDate()).toISOString().split("T")[0]}
                  onChange={(event) => {
                    handleChange(event, "delivery_time");
                  }}
                  onKeyUp={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      handleSendCollaborationRequest();
                    }
                  }}
                />
                {dateError ? (
                  <span className="errorText">
                    {fieldErrors.get("delivery_time")}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex justify-content-between">
                <div
                  onClick={() => {
                    setDescription("");
                    setPrice("");
                    setObject("");
                    setDeliveryTime("");
                    resetErrors();
                  }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="common-button-style-outline px-5"
                  id="invite-modal-close"
                >
                  Discard
                </div>
                <button
                  onClick={() => {
                    handleSendCollaborationRequest();
                  }}
                  className="border-0 common-button-style-filled"
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* COLLABORATION PROPOSAL MODAL END  */}
    </AppLayout>
  );
};

export default CreatorDetails;

import React from "react";

const DropdownForList = ({
  dropdownType,
  data,
  selectedValue,
  handleClick,
}) => {
  return (
    <>
      <div className="dropdown-list-data">
        {data?.map((item, index) => {
          return (
            <div
              className={`text-truncate d-flex ps-1 pe-2 rounded-3 justify-content-between  ${
                selectedValue !== undefined && selectedValue?.includes(item)
                  ? "selected"
                  : ""
              }`}
              key={`DropDown-${index}`}
              onClick={() => handleClick(item)}
            >
              <div
                className={`${item === "Add Custom" ? "custom-design" : ""}`}
              >
                {item.charAt(0).toUpperCase() + item.slice(1)}
                {selectedValue?.includes(item) && (
                  <span className="tick-mark ps-2">&#10004;</span>
                )}
              </div>
            </div>
          );
        })}
        {dropdownType && dropdownType === "industry" ? (
          <div
            data-bs-toggle="modal"
            data-bs-target="#add-custom-industry-modal"
            id="add-custom-industry-modal-btn"
            className="border d-flex justify-content-center fw-bold"
          >
            Add Custom
          </div>
        ) : null}
      </div>
    </>
  );
};

export default DropdownForList;
